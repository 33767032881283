// hooks/useAiGenCommentary.ts
// Handles generation of AI Host's commentary

import { useState, useCallback } from 'react';
import { generateHostCommentary } from '../services/aiGenServices';
import { useRoomStore } from '../stores/useRoomStore';
import {
  useLastCommentaryKey,
  useAiGenActions,
  useAiGenStore,
} from '../stores/useAiGenStore';

export function useAiGenCommentary() {
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const [isCommentaryGenerating, setIsCommentaryGenerating] = useState(false);
  const [errorGenerating, setErrorGenerating] = useState<string | null>(null);
  const lastCommentaryKey = useLastCommentaryKey();
  const { setLastCommentary } = useAiGenActions();

  const fetchCommentary = useCallback(
    async (gameNotes: string) => {
      if (!roomDetails?.roomCode) {
        setErrorGenerating('Room code not available');
        return null;
      }

      if (!roomDetails.roomUser.userSettings) {
        setErrorGenerating('Room settings not available');
        return null;
      }

      const key = `${roomDetails.roomCode}-${gameNotes}`;

      // Skip if the last request is the same as this one
      if (lastCommentaryKey === key) {
        console.log(`Skipping duplicate request for key: ${key}`);
        // Return the current commentary from the store
        return useAiGenStore.getState().lastCommentaryValue;
      }

      setIsCommentaryGenerating(true);
      setErrorGenerating(null);

      try {
        const commentaryText = await generateHostCommentary({
          roomCode: roomDetails.roomCode,
          language: roomDetails.roomUser.userSettings.language ?? 'en',
          hostAccent: roomDetails.roomUser.userSettings.hostAccent ?? 'en-US',
          hostStyle: roomDetails.roomUser.userSettings.hostStyle ?? 'standard',
          contentStyle:
            roomDetails.roomUser.userSettings.contentStyle ?? 'standard',
          gameNotes,
        });

        // Update the store
        setLastCommentary(key, commentaryText);

        // Return the commentary directly
        return commentaryText;
      } catch (error: unknown) {
        console.error('Failed to fetch host commentary:', error);
        if (error instanceof Error) {
          setErrorGenerating(error.message);
        } else {
          setErrorGenerating('Failed to fetch commentary');
        }
        setLastCommentary('', '');
        return null;
      } finally {
        setIsCommentaryGenerating(false);
      }
    },
    [roomDetails, lastCommentaryKey, setLastCommentary]
  );

  return {
    isCommentaryGenerating,
    errorGenerating,
    fetchCommentary,
  };
}

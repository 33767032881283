// pages/EmailVerify.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../stores/useUserStore';
import { useUserOtpValidate } from '../hooks/useUserOtpValidate';
import Form from '../components/Form';
import Wordmark from '../components/Wordmark';
import HamburgerMenu from '../components/HamburgerMenu';
import './EmailVerify.css';

const EmailVerify: React.FC = () => {
  const navigate = useNavigate();
  const user = useUser();
  const { validateOtp, errorValidatingOtp } = useUserOtpValidate();

  const handleOTPSubmit = async (
    otp: string,
    setError: (msg: string | null) => void
  ) => {
    setError(null);

    if (!otp) {
      setError('Please enter code.');
      return;
    }

    if (!user?.email) {
      setError('No email provided. Please go back and enter your email.');
      return;
    }

    try {
      await validateOtp(user?.email, otp);
      navigate(user?.subscriber ? '/game-library' : '/subscribe');
    } catch (err) {
      // Use the error from the hook if available
      const errorMessage =
        errorValidatingOtp || 'Failed to validate code. Please try again.';

      if (errorMessage.includes('expired')) {
        setError('Code has expired. Go back to try again.');
      } else if (errorMessage.includes('invalid')) {
        setError('Invalid code. Please try again.');
      } else {
        setError(errorMessage);
      }
    }
  };

  return (
    <div className="otp-page-container">
      <Wordmark />
      <HamburgerMenu />
      <main>
        <Form
          className="form-container--emailverify"
          placeholder="Enter code"
          buttonLabel="Submit"
          onSubmit={handleOTPSubmit}
          validationType="number"
          headerText="Enter Passcode"
          explainerText="Check your email for the code"
          customContent={
            user && user?.email ? (
              <div>{user?.email}</div>
            ) : (
              <div>No email provided</div>
            )
          }
        />
      </main>
    </div>
  );
};

export default EmailVerify;

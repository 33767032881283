// components/Button.tsx

import React from 'react';
import './Button.css';

interface ButtonProps {
  onClick: () => void;
  label: string | React.ReactNode;
  trackingLabel?: string;
  disabledButton?: boolean;
  variant?: 'default' | 'success' | 'transparent';
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  trackingLabel = label as string, // Default trackingLabel to label
  disabledButton = false,
  variant = 'default',
}) => {
  const buttonClass = `button ${
    variant === 'success'
      ? 'button-success'
      : variant === 'transparent'
        ? 'button-transparent'
        : ''
  }`;

  // Wrap the onClick handler to include analytics tracking
  const handleClick = () => {
    if (trackingLabel) {
      // Log to Google Analytics
      window.gtag?.('event', 'button_click', {
        button_name: trackingLabel, // Pass tracking label as parameter
      });
    }

    // Call the original onClick handler
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={buttonClass}
      onClick={handleClick}
      disabled={disabledButton}
    >
      {label}
    </button>
  );
};

export default Button;

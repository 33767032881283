// app/GameplayInitializer.tsx
// Handles initialization tasks for room and game routes

import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useRoomStore } from '../stores/useRoomStore';
import { useGameStore } from '../stores/useGameStore';
import { usePlayerStore } from '../stores/usePlayerStore';
import { useRoomFetch } from '../hooks/useRoomFetch';
import { usePlayerMeFetch } from '../hooks/usePlayerMeFetch';
import { useWebSocketEmitters } from '../hooks/useWebSocketEmitters';
import { getCurrentRouteType, getRouteParams } from '../utilities/isRoute';

export const GameplayInitializer = () => {
  const location = useLocation();
  const { fetchRoomDetails } = useRoomFetch();
  const { fetchPlayerMe } = usePlayerMeFetch();
  const { joinGameRoom } = useWebSocketEmitters();
  const roomDetails = useRoomStore((state) => state.roomDetails);

  const getRoomIdentifier = useCallback(() => {
    // Get roomId from route params
    const { roomId } = getRouteParams();
    if (roomId != null && roomId !== '') {
      return roomId;
    }

    // If not in route params, try to get from room store
    if (roomDetails?.roomCode != null && roomDetails.roomCode !== '') {
      return roomDetails.roomCode;
    }

    console.log('No valid room identifier found in route params or store');
    return null;
  }, [roomDetails?.roomCode]);

  const checkInitializationNeeded = useCallback(() => {
    // Get current state
    const roomDetails = useRoomStore.getState().roomDetails;
    const gameSelected = useGameStore.getState().gameSelected;
    const playerMe = usePlayerStore.getState().playerMe;

    // Return true if any required data is missing
    return !roomDetails || !gameSelected || !playerMe;
  }, []);

  useEffect(() => {
    const initializeGameplayData = async () => {
      const roomCode = getRoomIdentifier();
      if (!roomCode) {
        console.log('Cannot initialize gameplay data without room identifier');
        return;
      }

      const routeType = getCurrentRouteType();
      if (routeType === 'unknown') {
        console.log('Cannot initialize gameplay data for unknown route type');
        return;
      }

      // Check if we need to initialize data
      const needsInitialization = checkInitializationNeeded();

      if (needsInitialization) {
        try {
          console.log('Initializing gameplay data for room:', roomCode);
          await Promise.all([
            fetchRoomDetails(roomCode),
            fetchPlayerMe(),
            joinGameRoom(roomCode),
          ]);
        } catch (error) {
          console.error('Failed to initialize gameplay data:', error);
        }
      } else {
        console.log('Gameplay data already initialized');
      }
    };

    initializeGameplayData();
  }, [
    location.pathname,
    fetchRoomDetails,
    fetchPlayerMe,
    getRoomIdentifier,
    checkInitializationNeeded,
    joinGameRoom,
  ]);

  return null;
};

// app/monitoring/AnalyticsTracker.tsx
// Watches for route changes

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../../utilities/analyticsPageLogging';
import { routes } from '../../configs/routes';

const AnalyticsTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const pathPattern = (path: string) =>
      new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`);

    const matchingRoute = routes.find((route) =>
      pathPattern(route.path).test(location.pathname)
    );

    if (!matchingRoute) {
      console.warn(`No matching route found for path: ${location.pathname}`);
      logPageView('/unknown', 'Unknown Page');
      return;
    }

    const { normalizedPath, pageTitle, extractRoomCode } = matchingRoute;
    const roomCode = extractRoomCode?.(location.pathname);

    // console.log('gtag Normalized Path:', normalizedPath);
    // console.log('gtag Room Code:', roomCode);

    document.title = pageTitle;
    logPageView(normalizedPath, pageTitle, roomCode);
  }, [location]);

  return null;
};

export default AnalyticsTracker;

// components/Wordmark.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import './Wordmark.css';

const Wordmark: React.FC = () => {
  // Handle click event for analytics
  const handleClick = () => {
    window.gtag?.('event', 'button_click', {
      button_name: 'Wordmark',
    });
  };

  return (
    <div className="wordmark">
      <Link to="/" title="Go to Homepage" onClick={handleClick}>
        <img src="/assets/wordmark.png" alt="Airhead Games Logo" />
      </Link>
    </div>
  );
};

export default Wordmark;

// frontend/services/audioServices.ts
// Responsible for making one-shot (fully synthesized) TTS requests via axios.

import axiosInstance from '../../utilities/axiosConfig';
import { SpeechQueueItem } from '../../types';

/**
 * Requests a fully synthesized audio file (Blob) for the given text payload.
 * @param payload - The text payload to be synthesized into speech.
 * @returns A Blob representing the complete audio data.
 * @throws An error if the request fails or the browser does not support audio playback.
 */
export async function getSynthesizedSpeech(
  payload: SpeechQueueItem
): Promise<Blob> {
  try {
    // We request 'blob' so we get the entire audio file in one response
    const response = await axiosInstance.post<Blob>('/api/speech', payload, {
      responseType: 'blob',
    });

    // If the server returns success but there's no Blob data, throw an error
    if (!response.data || response.data.size === 0) {
      throw new Error('No audio data received from the server.');
    }

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`AudioService Error: ${error.message}`);
    }
    throw new Error('An unknown error occurred while fetching the audio.');
  }
}

// utilities/isRoute.ts
// Determines if the client is on a particular path

import { matchPath } from 'react-router-dom';

// Define route patterns with explicit params where needed
const ROUTE_PATTERNS = {
  ROOM: '/room/:roomId',
  ROOM_PLAYER: '/room/:roomId/player',
  GAME: '/game/:roomId',
  GAME_PLAYING: '/game/:roomId/playing',
} as const;

// Determines if the current route exactly matches a pattern
const matchesPattern = (pattern: string): boolean => {
  const match = matchPath(
    {
      path: pattern,
      end: true, // This ensures we match the entire path, not just a prefix
    },
    window.location.pathname
  );
  return !!match;
};

// Get all matching patterns for the current route
const getMatchingPatterns = (): string[] => {
  return Object.values(ROUTE_PATTERNS).filter((pattern) =>
    matchesPattern(pattern)
  );
};

// Determines if the current route is a playing route (player or playing game)
export const isPlayingRoute = (): boolean => {
  const matches = getMatchingPatterns();
  return matches.some(
    (pattern) =>
      pattern === ROUTE_PATTERNS.ROOM_PLAYER ||
      pattern === ROUTE_PATTERNS.GAME_PLAYING
  );
};

// Determines if the current route is a base room route (without player suffix)
export const isRoomRoute = (): boolean => {
  const matches = getMatchingPatterns();
  // Only match ROOM if it's the only match (avoid matching ROOM_PLAYER)
  return matches.length === 1 && matches[0] === ROUTE_PATTERNS.ROOM;
};

// Determines if the current route is a base game route (without playing suffix)
export const isGameRoute = (): boolean => {
  const matches = getMatchingPatterns();
  // Only match GAME if it's the only match (avoid matching GAME_PLAYING)
  return matches.length === 1 && matches[0] === ROUTE_PATTERNS.GAME;
};

// Helper to get current route type with exact matching
export const getCurrentRouteType = ():
  | 'room'
  | 'game'
  | 'playing'
  | 'unknown' => {
  const matches = getMatchingPatterns();

  // If we have multiple matches, something is wrong
  if (matches.length > 1) {
    console.warn('Multiple route matches found:', matches);
    return 'unknown';
  }

  // Single exact match
  const [match] = matches;

  switch (match) {
    case ROUTE_PATTERNS.ROOM:
      return 'room';
    case ROUTE_PATTERNS.GAME:
      return 'game';
    case ROUTE_PATTERNS.ROOM_PLAYER:
    case ROUTE_PATTERNS.GAME_PLAYING:
      return 'playing';
    default:
      return 'unknown';
  }
};

// Helper to get route params if needed
export const getRouteParams = (): { roomId?: string } => {
  const matches = getMatchingPatterns();
  const [pattern] = matches;

  if (!pattern) return {};

  const match = matchPath(
    {
      path: pattern,
      end: true,
    },
    window.location.pathname
  );

  return match?.params || {};
};

import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './Accordion.css';

// Define types for the component props
export interface AccordionOption {
  id: string;
  label: string;
  description?: string;
}

export interface AccordionSection {
  id: string;
  title: string;
  options: AccordionOption[];
}

export interface AccordionProps {
  sections: AccordionSection[];
  selections: Record<string, string>;
  onSelect: (sectionId: string, optionId: string) => void;
  defaultOpenSection?: string | null;
}

/**
 * Reusable Accordion component
 *
 * Manages expandable/collapsible sections with selectable options
 * Automatically closes sections when a selection is made
 * Displays the currently selected option next to each section title
 */
const Accordion: React.FC<AccordionProps> = ({
  sections,
  selections,
  onSelect,
  defaultOpenSection = null,
}) => {
  // Track which section is currently open
  const [openSection, setOpenSection] = useState<string | null>(
    defaultOpenSection === null
      ? null
      : defaultOpenSection || sections[0]?.id || null
  );

  // Toggle section open/close
  const toggleSection = (sectionId: string) => {
    if (openSection === sectionId) {
      setOpenSection(null);
    } else {
      setOpenSection(sectionId);
    }
  };

  // Handle option selection
  const handleSelectOption = (sectionId: string, optionId: string) => {
    onSelect(sectionId, optionId);
    setOpenSection(null); // Close section after selection
  };

  // Get selected option label for display
  const getSelectedLabel = (section: AccordionSection): string => {
    const selectedId = selections[section.id];
    const selectedOption = section.options.find(
      (option) => option.id === selectedId
    );
    return selectedOption ? selectedOption.label : '';
  };

  return (
    <div
      className={`accordion ${openSection !== null ? 'has-open-section' : ''}`}
    >
      {sections.map((section) => (
        <div key={section.id} className="accordion-section">
          {/* Accordion Header */}
          <div
            className={`accordion-header ${openSection === section.id ? 'open' : ''}`}
            onClick={() => toggleSection(section.id)}
          >
            <div className="accordion-title">{section.title}</div>
            <div className="accordion-header-right">
              {/* Only show selected option when section is closed */}
              {selections[section.id] && openSection !== section.id && (
                <div className="selected-option">
                  {getSelectedLabel(section)}
                </div>
              )}
              {openSection === section.id ? (
                <ChevronUp size={24} />
              ) : (
                <ChevronDown size={24} />
              )}
            </div>
          </div>

          {/* Accordion Content */}
          {openSection === section.id && (
            <div className="accordion-content">
              {section.options.map((option) => (
                <div
                  key={option.id}
                  onClick={() => handleSelectOption(section.id, option.id)}
                  className={`accordion-option ${selections[section.id] === option.id ? 'selected' : ''}`}
                >
                  <div className="option-content">
                    <div className="option-label">{option.label}</div>
                    {option.description && (
                      <div className="option-description">
                        {option.description}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;

// stores/useRoomStore.ts
// Global room state manager with performance-optimized access hooks

import { create } from 'zustand';
import { useMemo } from 'react';
import { Room } from '@shared/types';

interface RoomState {
  roomDetails: Room | null;
  readyForSelection: boolean;
  setRoomDetails: (details: Room | null) => void;
  updateRoomDetails: (details: Partial<Room>) => void;
  setReadyForSelection: (ready: boolean) => void;
  clearRoom: () => void;
}

// Initial state definition to use in both store creation and reset
const initialState = {
  roomDetails: null,
  readyForSelection: false,
};

const useRoomStore = create<RoomState>((set) => ({
  ...initialState,
  setRoomDetails: (details) => set(() => ({ roomDetails: details })),
  updateRoomDetails: (details) =>
    set((state) => ({
      roomDetails: state.roomDetails
        ? { ...state.roomDetails, ...details }
        : (details as Room),
    })),
  setReadyForSelection: (ready) => set(() => ({ readyForSelection: ready })),
  clearRoom: () => set(() => ({ ...initialState })), // Reset to initial state
}));

// Memoized actions to prevent unnecessary object recreation
export const useRoomActions = () => {
  const store = useRoomStore();

  return useMemo(
    () => ({
      setRoomDetails: store.setRoomDetails,
      updateRoomDetails: store.updateRoomDetails,
      setReadyForSelection: store.setReadyForSelection,
      clearRoom: store.clearRoom,
    }),
    [
      store.setRoomDetails,
      store.updateRoomDetails,
      store.setReadyForSelection,
      store.clearRoom,
    ]
  );
};

export { useRoomStore };

// components/GameCover.tsx

import React from 'react';
import './GameCover.css';

interface GameCoverProps {
  imagePath: string;
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const GameCover: React.FC<GameCoverProps> = ({
  imagePath,
  name,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`game-cover ${isActive ? 'active' : 'inactive'}`}
      onClick={onClick}
    >
      <img src={imagePath} alt={name} />
      <p>{name}</p>
    </div>
  );
};

export default GameCover;

// hooks/useUserOtpValidate.ts
// Handles OTP validation after user enters the passcode

import { useCallback, useState } from 'react';
import { validateOTP } from '../services/userServices';
import { useUserFetch } from './useUserFetch';

// Custom hook to validate an OTP entered by the user.
// If successful, sets user in context (the server sets the JWT cookie).
export function useUserOtpValidate() {
  const { fetchUserDetails } = useUserFetch();
  const [isValidating, setIsValidating] = useState(false);
  const [errorValidatingOtp, setErrorValidatingOtp] = useState<string | null>(
    null
  );

  const validateOtp = useCallback(
    async (email: string, otp: string): Promise<void> => {
      setIsValidating(true);
      setErrorValidatingOtp(null);

      try {
        // The server sets the HTTP-only cookie on success
        await validateOTP(email, otp);

        // After success, useUserDetails to fetch the user and set in context
        await fetchUserDetails();
      } catch (err) {
        console.error('Failed to validate OTP:', err);
        setErrorValidatingOtp('Failed to validate OTP.');
        throw err;
      } finally {
        setIsValidating(false);
      }
    },
    [fetchUserDetails]
  );

  return {
    validateOtp,
    isValidating,
    errorValidatingOtp,
  };
}

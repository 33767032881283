// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './app/App';
import reportWebVitals from './app/monitoring/reportWebVitals';
import GlobalStyle from './styles/globalStyles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const RootComponent = () => (
  // <React.StrictMode>
  <HelmetProvider>
    <GlobalStyle />
    <App />
  </HelmetProvider>
  // </React.StrictMode>
);

root.render(<RootComponent />);

reportWebVitals(console.log);

// styles/globalStyles.ts

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
    height: 100%;
    overflow: hidden; /* Prevent scrolling */
  }

  /* Global styles for hyperlinks */
  a {
    color: #00D0FF; /* Change the color of hyperlinks */
    text-decoration: none; /* Remove underline */
    transition: color 0.2s ease; /* Smooth transition for color change */
  }

  a:hover {
    color: #d0ff00; /* Change color on hover */
    text-decoration: underline; /* Underline on hover */
  }
    
  }
`;

export default GlobalStyle;

// pages/Subscribe.tsx

import React, { useEffect, useState } from 'react';
import { useUser } from '../stores/useUserStore';
import ProgressBar from '../components/ProgressBar';
import LanguageSelection from '../components/onboarding/Step01LanguageSelection';
import HostCustomization from '../components/onboarding/Step02HostCustomization';
import ContentStyleSelection from '../components/onboarding/Step03ContentStyleSelection';
import StripePricingTable from '../components/onboarding/Step04StripePricingTable';
import StripeCompleted from '../components/onboarding/Step05StripeCompleted';
import Wordmark from '../components/Wordmark';
import './Subscribe.css';

const Subscribe: React.FC = () => {
  // Access the user settings from context using the custom hook
  const user = useUser();

  const [isSpeaking, setIsSpeaking] = useState(false);

  // Handle refresh logic if user navigates back from Stripe Checkout
  useEffect(() => {
    if (document.referrer.includes('checkout.stripe.com')) {
      // Reload the page to reset state
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const handleSpeakingChange = (event: CustomEvent) => {
      setIsSpeaking(event.detail.isSpeaking);
    };

    window.addEventListener(
      'audio-speaking-change',
      handleSpeakingChange as EventListener
    );

    return () => {
      window.removeEventListener(
        'audio-speaking-change',
        handleSpeakingChange as EventListener
      );
    };
  }, []);

  // Extract `onboardingSet` and subscriber status from user settings
  const onboardingSet = user?.onboardingSet ?? false;
  const subscriberSet = user?.subscriber ?? false;

  // Set initial onboarding step based on `onboardingSet`
  const [onboardingStep, setOnboardingStep] = useState(() => {
    if (subscriberSet) return 6; // Skip to step 6 if subscriberSet is true
    return onboardingSet ? 5 : 1; // Default logic for onboardingSet
  });

  // Total number of steps in the onboarding flow
  const totalSteps = 6;

  // Define the component mapping for each onboarding step
  const componentMap = {
    1: LanguageSelection,
    2: HostCustomization,
    3: ContentStyleSelection,
    4: StripePricingTable,
    5: StripeCompleted,
  } as const;

  // Get the current component based on onboarding step
  const CurrentComponent =
    componentMap[onboardingStep as keyof typeof componentMap];

  // Function to advance to the next step
  const nextStep = () => {
    if (onboardingStep < totalSteps) {
      setOnboardingStep(onboardingStep + 1); // Move to the next step
    }
  };

  return (
    <div className="subscribe">
      <Wordmark />
      <div
        className={`onboarding-progress-container ${isSpeaking ? 'is-speaking' : ''}`}
      >
        <ProgressBar
          progress={(onboardingStep / totalSteps) * 100}
          className="onboarding"
          strokeWidth={8}
          trailWidth={2}
          strokeColor="#00ff2f"
          trailColor="#414141"
        />
      </div>
      <div>
        {/* Render the current onboarding component and pass down nextStep */}
        <CurrentComponent nextStep={nextStep} />
      </div>
    </div>
  );
};

export default Subscribe;

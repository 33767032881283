// components/ProgressBar.tsx

import React from 'react';
import { Circle } from 'rc-progress';
import './ProgressBar.css';

interface ProgressBarProps {
  progress: number; // Progress percentage (0 to 100)
  gapDegree?: number; // Gap in the circle
  gapPosition?: string; // Where is the gap (top, bottom, left, right)
  className?: string; // Optional for styling
  strokeWidth?: number; // Stroke width of the progress bar
  trailWidth?: number;
  strokeColor?: string; // Stroke color of the progress bar
  trailColor?: string; // Background color of the progress bar
  width?: number; // Width of the progress bar
  height?: number; // Height of the progress bar

  isTimer?: boolean; // If true, display the timer text
  timerPulse?: boolean; // If true, pulse the timer text
  timeRemaining?: number; // Time remaining to display in the center
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  gapDegree = 0, // Default no gap
  className,
  strokeWidth = 8, // Default stroke width
  trailWidth = 2, // Default stroke width
  strokeColor = '#00ff2f', // Default stroke color
  trailColor = '#828282', // Default trail color
  width = 64, // Default width
  height = 64, // Default height
  isTimer = false,
  timerPulse = false,
  timeRemaining = 0, // Default to zero if not provided
}) => (
  <div
    className={`progress-bar-container ${className || ''}`}
    style={{
      width, // Dynamically set width
      height, // Dynamically set height
    }}
  >
    <Circle
      percent={progress}
      gapDegree={gapDegree}
      strokeWidth={strokeWidth}
      trailWidth={trailWidth}
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
    {progress === 100 && <div className="checkmark">✓</div>}
    {isTimer && !timerPulse && progress !== 100 && (
      <div className="timer">{timeRemaining.toFixed(1)}</div>
    )}
    {isTimer && timerPulse && progress !== 100 && (
      <div className="timer-pulse">{timeRemaining.toFixed(1)}</div>
    )}
  </div>
);

export default ProgressBar;

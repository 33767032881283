// hooks/useRoomFetch.ts
// Handles retrieval from backend and setting room store

import { useCallback, useState } from 'react';
import { useRoomActions } from '../stores/useRoomStore';
import { getRoomDetails } from '../services/roomServices';

export interface UseRoomFetchReturn {
  isFetchingRoom: boolean;
  errorFetchingRoom: string | null;
  fetchRoomDetails: (roomCode: string) => Promise<void>;
}

export function useRoomFetch(): UseRoomFetchReturn {
  const { updateRoomDetails } = useRoomActions();

  // Internal states for loading, error, and lastUpdated
  const [isFetchingRoom, setIsFetchingRoom] = useState<boolean>(false);
  const [errorFetchingRoom, setErrorFetchingRoom] = useState<string | null>(
    null
  );

  // Fetches latest room details by room code and stores in context
  const fetchRoomDetails = useCallback(
    async (roomCode: string): Promise<void> => {
      if (!roomCode) {
        console.error('No roomCode provided; cannot fetch room details.');
        return;
      }

      console.log('Fetching room details for:', roomCode);
      setIsFetchingRoom(true);
      setErrorFetchingRoom(null);

      try {
        const data = await getRoomDetails(roomCode);
        console.log('Got room details:', data);
        updateRoomDetails(data);
      } catch (err: unknown) {
        const errorMessage =
          err instanceof Error ? err.message : 'Failed to fetch room details.';
        console.error('Failed to fetch room details:', err);
        setErrorFetchingRoom(errorMessage);
      } finally {
        setIsFetchingRoom(false);
      }
    },
    [updateRoomDetails]
  );

  return {
    isFetchingRoom,
    errorFetchingRoom,
    fetchRoomDetails,
  };
}

// hooks/useWebSocketEmitters.ts
// Custom hook for emitting WebSocket events with type safety and memoization

import { useMemo } from 'react';
import WebSocketService from '../services/webSocketService';
import { VotingStatus, VotingBallotUpdate } from '@shared/types';

export const useWebSocketEmitters = () => {
  const webSocketService = WebSocketService.getInstance();

  return useMemo(
    () => ({
      // WebSocket Room Management
      joinGameRoom: (roomCode: string) => {
        webSocketService.emitEvent('joinGameRoom', { roomCode });
      },

      leaveGameRoom: (roomCode?: string) => {
        webSocketService.emitEvent('leaveGameRoom', {
          roomCode: roomCode || '',
        });
      },

      // Voting System
      sendVotingOpenUpdate: (roomCode: string, votingData: VotingStatus) => {
        webSocketService.emitEvent('votingOpenUpdate', {
          roomCode,
          votingOpenData: votingData,
        });
      },

      sendVotingBallotUpdate: (
        roomCode: string,
        actionAndBallot: VotingBallotUpdate
      ) => {
        webSocketService.emitEvent('votingBallotUpdate', {
          roomCode,
          actionAndBallot,
        });
      },

      sendResetBallotBox: (roomCode: string) => {
        webSocketService.emitEvent('resetBallotBox', { roomCode });
      },

      sendRequestRoomVotingState: (roomCode: string) => {
        webSocketService.emitEvent('requestRoomVotingState', { roomCode });
      },

      // Room State Management
      sendRoomUpdate: (roomCode: string) => {
        webSocketService.emitEvent('roomUpdate', { roomCode });
      },

      sendRequestRoomSelectionState: (roomCode: string) => {
        webSocketService.emitEvent('requestRoomSelectionState', { roomCode });
      },

      sendReadyForSelectionChange: (roomCode: string, isReady: boolean) => {
        webSocketService.emitEvent('readyForSelectionChange', {
          roomCode,
          isReady,
        });
      },

      // Game Flow Control
      sendEndGameReplay: (roomCode: string) => {
        webSocketService.emitEvent('endGameReplay', { roomCode });
      },

      sendEndGameLibrary: (roomCode: string) => {
        webSocketService.emitEvent('endGameLibrary', { roomCode });
      },

      // Player Updates
      sendPlayerUpdate: (roomCode: string, playerId: string) => {
        webSocketService.emitEvent('playerUpdate', { roomCode, playerId });
      },
    }),
    [webSocketService]
  );
};

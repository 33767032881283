// shared/utilities/mapHostStyle.ts
// Unified utility for mapping hostStyle to style properties

import { HostStyle } from '../types';

export const hostStyleMapping: HostStyle[] = [
  {
    value: 'cheerleader',
    label: 'Cheerleader',
    labelDescription: 'Sweet & supportive',
    description: 'personality of a cheerleader, sweetly supporting the players',
    voiceActorMale: 'Charon',
    voiceActorFemale: 'Leda',
    disabled: false,
  },
  {
    value: 'comedian',
    label: 'Comedian',
    labelDescription: 'Snarky & witty',
    description:
      'personality of a comedian, using snark and wit to make players laugh',
    voiceActorMale: 'Orus',
    voiceActorFemale: 'Kore',
    disabled: false,
  },
  {
    value: 'diva',
    label: 'Diva',
    labelDescription: 'Dramatic & intense',
    description:
      'personality of a diva, dramatically and intensely guiding players through the game',
    voiceActorMale: 'Puck',
    voiceActorFemale: 'Zephyr',
    disabled: false,
  },
  {
    value: 'entertainer',
    label: 'Entertainer',
    labelDescription: 'Quirky & whimsical',
    description:
      'personality of an entertainer, with quirky and whimsical behavior to entertain players',
    voiceActorMale: 'Fenrir',
    voiceActorFemale: 'Aoede',
    disabled: false,
  },
];

// Generic helper to find a style by value
export function findHostStyle(
  style: HostStyle['value']
): HostStyle | undefined {
  return hostStyleMapping.find((s) => s.value === style);
}

// Helper functions to get specific properties using a style
export function getHostLabel(style: HostStyle['value']): string | undefined {
  return findHostStyle(style)?.label;
}

export function getHostDescription(
  style: HostStyle['value']
): string | undefined {
  return findHostStyle(style)?.description;
}

export function isHostStyleDisabled(
  style: HostStyle['value']
): boolean | undefined {
  return findHostStyle(style)?.disabled;
}

// Helper to get all enabled host styles
export function getEnabledHostStyles(): HostStyle[] {
  return hostStyleMapping.filter((s) => !s.disabled);
}

// Helper to get all host style values
export function getAllHostStyles(): HostStyle['value'][] {
  return hostStyleMapping.map((s) => s.value);
}

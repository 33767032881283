// stores/usePlayerStore.ts
// Global player state manager with performance-optimized access hooks

import { create } from 'zustand';
import { useMemo } from 'react';
import { useRoomStore } from './useRoomStore';
import { Player } from '@shared/types';

interface PlayerState {
  playerMe: Player | null;
  setPlayerMe: (player: Player | null) => void;
}

const usePlayerStore = create<PlayerState>((set) => ({
  playerMe: null,
  setPlayerMe: (player) => set(() => ({ playerMe: player })),
}));

export const usePlayerMe = () => usePlayerStore((state) => state.playerMe);

export const usePlayersNotMe = () => {
  const playerMe = usePlayerStore((state) => state.playerMe);
  const roomDetails = useRoomStore((state) => state.roomDetails);

  return useMemo(() => {
    if (!playerMe || !roomDetails?.roomPlayers)
      return roomDetails?.roomPlayers || [];
    return roomDetails.roomPlayers.filter(
      (player) => player._id !== playerMe._id
    );
  }, [playerMe, roomDetails]);
};

export const usePlayerCurrentTurn = () => {
  const roomDetails = useRoomStore((state) => state.roomDetails);

  return useMemo(() => {
    const currentTurnIndex = roomDetails?.gameStructure?.playerCurrentTurnIndex;

    if (currentTurnIndex === undefined || !roomDetails?.roomPlayers) {
      return null;
    }

    return roomDetails.roomPlayers[currentTurnIndex] || null;
  }, [roomDetails]);
};

export const useIsMyPlayerTurn = () => {
  const playerMe = usePlayerStore((state) => state.playerMe);
  const playerCurrentTurn = usePlayerCurrentTurn(); // Now using the derived hook instead

  return useMemo(() => {
    if (!playerMe || !playerCurrentTurn) {
      return false;
    }

    return playerMe._id === playerCurrentTurn._id;
  }, [playerMe, playerCurrentTurn]);
};

// Memoized actions
export const usePlayerActions = () => {
  const store = usePlayerStore();

  return useMemo(
    () => ({
      setPlayerMe: store.setPlayerMe,
    }),
    [store.setPlayerMe]
  );
};

export { usePlayerStore };

// services/roomServices.ts
// Handles all API interactions related to rooms

import axiosInstance from '../utilities/axiosConfig';
import { handleApiResponse } from './axiosWrapper';
import {
  Room,
  CreateRoomData,
  UpdateRoomData,
  ApiResponse,
} from '@shared/types';

// Creates a new room in the backend.
export async function createRoom(data: CreateRoomData): Promise<Room> {
  return handleApiResponse<Room>(
    axiosInstance.post<ApiResponse<Room>>('/api/room-create', data)
  );
}

// Checks if a room exists
export async function verifyRoomExists(roomCode: string): Promise<boolean> {
  const response = await axiosInstance.head<ApiResponse<null>>(
    `/api/room-exists/${roomCode}`
  );

  if (response.status === 200) {
    return true;
  } else {
    throw new Error('Failed to verify room');
  }
}

// Updates an existing room using array-of-operations.
export async function updateRoom(
  roomCode: string,
  payload: UpdateRoomData
): Promise<Room> {
  return handleApiResponse<Room>(
    axiosInstance.patch<ApiResponse<Room>>(
      `/api/room-update/${roomCode}`,
      payload
    )
  );
}

// Fetch room details by room code.
export async function getRoomDetails(roomCode: string): Promise<Room> {
  return handleApiResponse<Room>(
    axiosInstance.get<ApiResponse<Room>>(`/api/room-details/${roomCode}`)
  );
}

// components/onboarding/Step01LanguageSelection.tsx

import React, { useState } from 'react';
import { useUserSave } from '../../hooks/useUserSave';
import { languageMapping, getEnabledAccents } from '@shared/utilities';
import ChoiceList from '../ChoiceList';
import './Step01LanguageSelection.css';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const LanguageSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const { saveUser } = useUserSave();

  // Get all languages and their labels
  const choices = languageMapping.map((lang) => lang.label);
  const disabledChoices = languageMapping
    .filter((lang) => lang.disabled)
    .map((lang) => lang.label);

  const handleLanguageChoice = async (choice: string) => {
    // Find the language object by matching the label
    const selectedLanguage = languageMapping.find(
      (lang) => lang.label === choice
    );

    if (!selectedLanguage) return;

    // Get the first accent for the selected language
    const firstAccent =
      getEnabledAccents(selectedLanguage.value)[0]?.value || '';

    try {
      setSelectedChoice(choice);
      // First save language
      await saveUser({
        operations: [
          {
            operator: '$set',
            path: 'userSettings.language',
            value: selectedLanguage.value,
          },
        ],
      });
      // Then save accent
      await saveUser({
        operations: [
          {
            operator: '$set',
            path: 'userSettings.hostAccent',
            value: firstAccent,
          },
        ],
      });

      console.log(`Updated language to: ${selectedLanguage.value}`);
      console.log(`Updated accent to: ${firstAccent}`);
      nextStep();
    } catch (error) {
      console.error('Failed to update language and accent settings:', error);
    }
  };

  return (
    <div className="onboard-pick-language">
      <div className="form-header-explainer">
        <div className="form-header">Language</div>
        <div className="form-explainer">Choose your preferred language</div>
      </div>
      <ChoiceList
        choices={choices}
        selectedChoice={selectedChoice}
        onSelectChoice={handleLanguageChoice}
        disabledChoices={disabledChoices}
      />
    </div>
  );
};

export default LanguageSelection;

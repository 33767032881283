// services/userServices.ts
// Handles all API interactions related to users

import axiosInstance from '../utilities/axiosConfig';
import { handleApiResponse } from './axiosWrapper';
import { User, UpdateUserData, ApiResponse } from '@shared/types';

// Request an OTP for the given email.
export async function requestOTP(email: string): Promise<User> {
  return handleApiResponse<User>(
    axiosInstance.post<ApiResponse<User>>('/api/otp-request', { email })
  );
}

// Validate an OTP for the given email; server sets an HTTP-only cookie on success.
export async function validateOTP(email: string, otp: string): Promise<void> {
  await axiosInstance.post('/api/otp-verify', { email, otp });
}

// Fetch user details
export async function getUserDetails(): Promise<User> {
  return handleApiResponse<User>(
    axiosInstance.get<ApiResponse<User>>('/api/user-details')
  );
}

// Update an existing user with array-of-operations.
export async function updateUser(data: UpdateUserData): Promise<User> {
  return handleApiResponse<User>(
    axiosInstance.patch<ApiResponse<User>>('/api/user-update', data)
  );
}

// Logout the user
export async function logoutUser(): Promise<void> {
  await axiosInstance.post('/api/user-logout');
}

// pages/Email.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../stores/useUserStore';
import { useUserOtpRequest } from '../hooks/useUserOtpRequest';
import Form from '../components/Form';
import Wordmark from '../components/Wordmark';
import HamburgerMenu from '../components/HamburgerMenu';
import './Email.css';

const Email: React.FC = () => {
  const navigate = useNavigate();
  const user = useUser();
  const { requestOtp } = useUserOtpRequest();

  const handleEmailSubmit = async (
    email: string,
    setError: (msg: string | null) => void
  ) => {
    try {
      if (!email) {
        setError('No email provided');
        return;
      }
      await requestOtp(email);
      console.log('Submitted request for passcode');
      navigate('/email-verification');
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Error sending OTP. Please try again.');
    }
  };

  return (
    <div className="email-page-container">
      <Wordmark />
      <HamburgerMenu />
      <main>
        {user?.subscriber ? (
          <Form
            className="form-container--email"
            buttonLabel="Go To Game Library"
            onSubmit={() => navigate('/game-library')} // Navigate directly to game library
            headerText="Sign Up / Sign In"
            explainerText="You are already signed in and ready to start playing."
          />
        ) : (
          <>
            <Form
              className="form-container--email"
              placeholder="Enter your email"
              buttonLabel="Submit"
              onSubmit={handleEmailSubmit}
              validationType="email"
              headerText="Sign Up / Sign In"
              explainerText="One-time passcode will be emailed to you."
            />
            <div className="terms-container">
              <p>
                You agree to our{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  Terms
                </a>{' '}
                by using our service.
              </p>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Email;

// pages/Game.tsx

import React, { useEffect } from 'react';
import { useGameSelection } from '../hooks/useGameSelection';
import { useGameFetch } from '../hooks/useGameLibraryFetch';
import { useGames } from '../stores/useGameStore';
import { useRoomStore } from '../stores/useRoomStore';
import { Game as GameType } from '@shared/types';
import './Game.css';

const Game: React.FC = () => {
  const { gameSelected, gameSelectById } = useGameSelection();
  const { fetchGames, isFetchingGames, errorFetchingGames } = useGameFetch();
  const games = useGames();
  const roomDetails = useRoomStore((state) => state.roomDetails);

  // If we have room details but no game selected, try to select the game from room
  useEffect(() => {
    if (roomDetails?.game._id) {
      // Check if we need to update the selected game (either no game is selected or a different game is in roomDetails)
      if (!gameSelected || gameSelected._id !== roomDetails.game._id) {
        // If we don't have any games in the library, fetch them first
        if (games.length === 0) {
          fetchGames();
        }
        gameSelectById(roomDetails.game._id);
      }
    }
  }, [roomDetails, gameSelected, games.length, gameSelectById, fetchGames]);

  // Monitor gameActive changes
  useEffect(() => {
    console.log('Game active state changed:', roomDetails?.gameActive);
  }, [roomDetails?.gameActive]);

  // Show loading state while fetching games
  if (isFetchingGames) {
    return <div className="game-waiting-messages">Loading game library...</div>;
  }

  // Show error if game library fetch failed
  if (errorFetchingGames) {
    return (
      <div className="game-waiting-messages">
        Error loading game library: {errorFetchingGames}
      </div>
    );
  }

  // Show message if no roomDetails
  if (!roomDetails) {
    return (
      <div className="game-waiting-messages">Waiting for room details...</div>
    );
  }

  // Show message if no game could be selected
  if (!gameSelected) {
    return <div className="game-waiting-messages">Unable to load game...</div>;
  }

  // Show message when game is selected but not yet active
  if (!roomDetails.gameActive) {
    return (
      <div className="game-waiting-messages">Waiting for game to start...</div>
    );
  }

  // Dynamic import of the selected game component
  const GameComponent = React.lazy(
    () =>
      import(
        `../games/${(gameSelected as GameType).pathCodeFolder}/${
          (gameSelected as GameType).pathCodeFolder
        }Game`
      )
  );

  return (
    <div className="game-layout">
      <React.Suspense fallback={<div>Loading game...</div>}>
        <GameComponent />
      </React.Suspense>
    </div>
  );
};

export default Game;

// services/audio/WebAudioService.ts
// Centralized Web Audio API service

export class WebAudioService {
  private _context: AudioContext | null = null;
  private _analyzerNode: AnalyserNode | null = null;
  private _gainNode: GainNode | null = null;
  private _connectedElements: Map<
    HTMLAudioElement,
    MediaElementAudioSourceNode
  > = new Map();

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    try {
      // Initialize Web Audio API
      const AudioContextClass =
        window.AudioContext || (window as any).webkitAudioContext;

      if (!AudioContextClass) {
        console.warn('Web Audio API is not supported in this browser');
        return;
      }

      this._context = new AudioContextClass();

      // Create analyzer node for visualization
      this._analyzerNode = this._context.createAnalyser();
      this._analyzerNode.fftSize = 2048;

      // Create master gain node for muting
      this._gainNode = this._context.createGain();

      // Connect analyzer → gain → destination
      // This setup ensures visualization works even when muted
      this._analyzerNode.connect(this._gainNode);
      this._gainNode.connect(this._context.destination);

      console.log('WebAudioService initialized successfully');
    } catch (error) {
      console.error('Failed to initialize WebAudioService:', error);
    }
  }

  /**
   * Connect an audio element to the Web Audio graph
   * @param element The HTMLAudioElement to connect
   * @returns The created MediaElementAudioSourceNode or null if connection failed
   */
  connectSource(
    element: HTMLAudioElement | null
  ): MediaElementAudioSourceNode | null {
    if (!element || !this._context || !this._analyzerNode) {
      return null;
    }

    // Skip if already connected
    if (this._connectedElements.has(element)) {
      return this._connectedElements.get(element) || null;
    }

    try {
      const source = this._context.createMediaElementSource(element);
      // Connect source → analyzer (which routes to gain → destination)
      source.connect(this._analyzerNode);
      this._connectedElements.set(element, source);
      console.log('Connected audio element to Web Audio graph');
      return source;
    } catch (error) {
      console.error('Failed to connect audio source:', error);
      return null;
    }
  }

  /**
   * Disconnect a previously connected audio element
   * @param element The HTMLAudioElement to disconnect
   */
  disconnectSource(element: HTMLAudioElement | null): void {
    if (!element || !this._connectedElements.has(element)) {
      return;
    }

    try {
      const source = this._connectedElements.get(element);
      if (source) {
        source.disconnect();
        this._connectedElements.delete(element);
        console.log('Disconnected audio element from Web Audio graph');
      }
    } catch (error) {
      console.error('Failed to disconnect audio source:', error);
    }
  }

  /**
   * Set the mute state by adjusting the master gain node
   * @param isMuted True to mute, false to unmute
   */
  setMute(isMuted: boolean): void {
    if (!this._gainNode || !this._context) return;
    const volume = isMuted ? 0 : 1;
    const now = this._context.currentTime;
    this._gainNode.gain.cancelScheduledValues(now);
    this._gainNode.gain.setValueAtTime(this._gainNode.gain.value, now);
    this._gainNode.gain.linearRampToValueAtTime(volume, now + 0.05);
    console.log(`WebAudioService mute state set to ${isMuted}`);
  }

  /**
   * Resume the audio context (required after user interaction in some browsers)
   */
  async resume(): Promise<void> {
    if (this._context && this._context.state !== 'running') {
      try {
        await this._context.resume();
        console.log('Audio context resumed successfully');
      } catch (error) {
        console.error('Failed to resume audio context:', error);
        throw error;
      }
    }
  }

  /**
   * Get the analyzer node for visualization
   */
  getAnalyzer(): AnalyserNode | null {
    return this._analyzerNode;
  }

  /**
   * Get the audio context
   */
  getContext(): AudioContext | null {
    return this._context;
  }

  /**
   * Clean up resources
   */
  dispose(): void {
    this._connectedElements.forEach((source) => source.disconnect());
    this._connectedElements.clear();
    if (this._context && this._context.state !== 'closed') {
      this._context
        .close()
        .catch((err) => console.error('Error closing audio context:', err));
    }
    this._context = null;
    this._gainNode = null;
    this._analyzerNode = null;
  }
}

// components/HamburgerMenu.tsx

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../stores/useUserStore';
import HamburgerIcon from './icons/Hamburger';
import XMarkIcon from './icons/XMark';
import './HamburgerMenu.css';

const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false); // Internal state to manage open/close
  const location = useLocation(); // Detect route changes
  const user = useUser();

  // Close the menu on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const [iconSize, setIconSize] = useState(window.innerWidth <= 720 ? 32 : 64);

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth <= 720 ? 32 : 64);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, []);

  // Toggle the menu open/close state
  const toggleMenu = () => {
    // Track menu toggle action
    window.gtag?.('event', 'menu_toggle', {
      menu_state: isOpen ? 'closed' : 'open', // Track whether the menu is opening or closing
    });
    setIsOpen((prev) => !prev);
  };

  // Define menu items
  const menuItems = [
    { path: '/', label: 'Home' },
    {
      path: user?.subscriber ? '/game-library' : '/email',
      label: user?.subscriber ? 'Game Library' : 'Sign Up / Sign In',
    },
    { path: '/about', label: 'About' },
    { path: '/terms', label: 'Terms' },
  ];

  // Filter out the current page
  const filteredMenuItems = menuItems.filter(
    (item) => item.path !== location.pathname
  );

  // Track individual menu item clicks
  const handleMenuItemClick = (label: string) => {
    window.gtag?.('event', 'menu_item_click', {
      menu_item: label, // Track the label of the menu item clicked
    });
  };

  return (
    <>
      <div className="main-menu-hamburger-xmark">
        {isOpen ? (
          <XMarkIcon size={iconSize} onClick={() => setIsOpen(false)} />
        ) : (
          <HamburgerIcon size={iconSize} onClick={() => setIsOpen(true)} />
        )}
      </div>

      {/* Menu content */}
      {isOpen && (
        <div className={`hamburger-menu ${isOpen ? 'open' : 'closed'}`}>
          <div className="menu-backdrop" onClick={toggleMenu}></div>
          <div className="menu-content">
            <ul>
              {filteredMenuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    onClick={() => {
                      toggleMenu(); // Close the menu
                      handleMenuItemClick(item.label); // Track the menu item click
                    }}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;

// hooks/websocket/useWebSocketListenersVoting.ts

import { useEffect } from 'react';
import WebSocketService from '../services/webSocketService';
import { useVotingActions } from '../stores/useVotingStore';
import { WebSocketEventHandler } from '../types';

export const useWebSocketListenersVoting = () => {
  const { updateVotingStatus, updateBallotBox } = useVotingActions();
  const webSocketService = WebSocketService.getInstance();

  useEffect(() => {
    const handleVotingOpenUpdate: WebSocketEventHandler<'votingOpenUpdate'> = (
      data
    ) => {
      console.log('[ws] votingOpen update received:', data);

      if (data.votingOpenData) {
        updateVotingStatus({
          votingOpen: data.votingOpenData.votingOpen,
          timestamp: data.votingOpenData.timestamp,
        });
      } else {
        console.warn('Received votingOpen with invalid data structure:', data);
      }
    };

    const handleBallotBoxUpdate: WebSocketEventHandler<'ballotBoxUpdate'> = (
      data
    ) => {
      console.log('[ws] Ballot Box update received:', data);

      if (data.ballotBox) {
        updateBallotBox(data.ballotBox);
      } else {
        console.warn('Received ballotBox with invalid data structure:', data);
      }
    };

    const handleLatestRoomVotingState: WebSocketEventHandler<
      'latestRoomVotingState'
    > = (data) => {
      console.log('[ws] Latest room voting state received:', data);

      if (data.votingOpenData !== undefined) {
        updateVotingStatus({
          votingOpen: data.votingOpenData.votingOpen,
          timestamp: data.votingOpenData.timestamp,
        });
      }

      if (data.ballotBox) {
        updateBallotBox(data.ballotBox);
      }
    };

    // Register voting event listeners
    webSocketService.onEvent('votingOpenUpdate', handleVotingOpenUpdate);
    webSocketService.onEvent('ballotBoxUpdate', handleBallotBoxUpdate);
    webSocketService.onEvent(
      'latestRoomVotingState',
      handleLatestRoomVotingState
    );

    // Cleanup function
    return () => {
      webSocketService.offEvent('votingOpenUpdate', handleVotingOpenUpdate);
      webSocketService.offEvent('ballotBoxUpdate', handleBallotBoxUpdate);
      webSocketService.offEvent(
        'latestRoomVotingState',
        handleLatestRoomVotingState
      );
    };
  }, [webSocketService, updateVotingStatus, updateBallotBox]);
};

// hooks/useRoomSave.ts
// Handles room saves, including creation and updates

import { useState, useCallback } from 'react';
import { useRoomStore, useRoomActions } from '../stores/useRoomStore';
import { useWebSocketEmitters } from '../hooks/useWebSocketEmitters';
import { createRoom, updateRoom } from '../services/roomServices';
import { Room, CreateRoomData, UpdateRoomData } from '@shared/types';

interface UseRoomSaveReturn {
  isSavingRoom: boolean;
  errorSavingRoom: string | null;
  saveRoom: (
    createData: CreateRoomData | null,
    updateData: UpdateRoomData | null,
    identifier?: string
  ) => Promise<Room | null>;
}

export function useRoomSave(): UseRoomSaveReturn {
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const roomCode = roomDetails?.roomCode;
  const { setRoomDetails } = useRoomActions();
  const { sendRoomUpdate } = useWebSocketEmitters();
  const [isSavingRoom, setIsSavingRoom] = useState<boolean>(false);
  const [errorSavingRoom, setErrorSavingRoom] = useState<string | null>(null);

  const saveRoom = useCallback(
    async (
      createData: CreateRoomData | null,
      updateData: UpdateRoomData | null,
      identifier?: string
    ): Promise<Room | null> => {
      setIsSavingRoom(true);
      setErrorSavingRoom(null);

      try {
        let roomDetails: Room;
        const targetRoomCode = identifier || roomCode;

        if (updateData && targetRoomCode) {
          const updatedRoom = await updateRoom(targetRoomCode, updateData);
          roomDetails = updatedRoom;
          setRoomDetails(updatedRoom);
          sendRoomUpdate(targetRoomCode);
        } else {
          if (!createData) {
            throw new Error('Cannot create room without createData.');
          }
          const newRoom = await createRoom(createData);
          if (!newRoom.roomCode) {
            throw new Error('Room created without room code');
          }
          roomDetails = newRoom;
          setRoomDetails(newRoom);
        }

        return roomDetails;
      } catch (err) {
        console.error('Failed to save room:', err);
        setErrorSavingRoom(
          err instanceof Error ? err.message : 'An unknown error occurred.'
        );
        return null;
      } finally {
        setIsSavingRoom(false);
      }
    },
    [roomCode, sendRoomUpdate, setRoomDetails]
  );

  return { isSavingRoom, errorSavingRoom, saveRoom };
}

// components/GameGrid.tsx

import React from 'react';
import './GameGrid.css';
import GameCover from './GameCover';
import { Game } from '@shared/types';

interface GameGridProps {
  games: Game[];
  onGameClick: (gameId: string) => void;
}

const GameGrid: React.FC<GameGridProps> = ({ games, onGameClick }) => {
  // Track game clicks
  const handleGameClick = (gameId: string, gameName: string) => {
    // Log to Google Analytics
    window.gtag?.('event', 'game_click', {
      game_id: gameId, // Track the game ID
      game_name: gameName, // Track the game name
    });

    // Trigger the original click handler
    onGameClick(gameId);
  };

  return (
    <div className="game-grid">
      {games.map((game) => (
        <GameCover
          key={game._id}
          imagePath={game.pathCoverImage}
          name={game.name}
          isActive={game.isActive}
          onClick={() => handleGameClick(game._id, game.name)} // Wrap with tracking
        />
      ))}
    </div>
  );
};

export default GameGrid;

// app/ProtectedRoutes.tsx
// Responsible for route protection and rerouting as necessary

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser, useIsAuthInitialized } from '../stores/useUserStore';

// Route protection for authenticated users
export const AuthenticatedRoute: React.FC = () => {
  const user = useUser();
  const isAuthInitialized = useIsAuthInitialized();
  // First show loader while we initialize auth
  if (!isAuthInitialized) {
    return null;
  }
  // Render the child route components; otherwise, redirect to login page
  return user ? <Outlet /> : <Navigate to="/email" />;
};

// Route protection for subscribed users (requires authentication + subscription)
export const SubscriptionRoute: React.FC = () => {
  const user = useUser();
  const isAuthInitialized = useIsAuthInitialized();
  // Show component while we initialize auth
  if (!isAuthInitialized) {
    return <Outlet />;
  }
  // If auth+sub, render child route components; otherwise, redirect to sub page
  return user && user.subscriber ? <Outlet /> : <Navigate to="/subscribe" />;
};

// components/onboarding/Step04ContentStyleSelection.tsx

import React, { useState } from 'react';
import { useUserSave } from '../../hooks/useUserSave';
import { contentStyleMapping } from '@shared/utilities';
import ChoiceList from '../ChoiceList';
import './Step03ContentStyleSelection.css';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const ContentStyleSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const { saveUser } = useUserSave();

  // Get all content styles and their labels
  const choices = contentStyleMapping.map((style) => style.label);
  const disabledChoices = contentStyleMapping
    .filter((style) => style.disabled)
    .map((style) => style.label);

  const handleContentStyleChoice = async (choice: string) => {
    // Find the content style object by matching the label
    const selectedStyle = contentStyleMapping.find(
      (style) => style.label === choice
    );

    if (!selectedStyle) return;

    try {
      setSelectedChoice(choice);
      await saveUser({
        operations: [
          {
            operator: '$set',
            path: 'userSettings.contentStyle',
            value: selectedStyle.value,
          },
          {
            operator: '$set',
            path: 'onboardingSet',
            value: true,
          },
        ],
      });

      console.log(`Updated content style to: ${selectedStyle.value}`);
      nextStep();
    } catch (error) {
      console.error('Failed to update content style:', error);
    }
  };

  return (
    <div className="onboard-pick-content-style">
      <div className="form-header-explainer">
        <div className="form-header">Content Style</div>
        <div className="form-explainer">
          All of our game content is generated by AI. While we strive to tailor
          the content to align with your selected preferences, we cannot fully
          control the AI output.
        </div>
      </div>
      <ChoiceList
        choices={choices}
        selectedChoice={selectedChoice}
        onSelectChoice={handleContentStyleChoice}
        disabledChoices={disabledChoices}
      />
    </div>
  );
};

export default ContentStyleSelection;

// components/PlayerCard.tsx

import React from 'react';
import Avatar from './Avatar';
import { Player } from '@shared/types';
import './PlayerCard.css';

interface PlayerCardProps {
  player: Player; // Use Player type for consistent structure
  avatarSize: number; // Allow avatar size to be customized
  showName?: boolean; // Optional flag to show/hide the name
  avatarPreset?:
    | 'standard-static'
    | 'standard-floating'
    | 'pop-head'
    | 'scorebar-reveal';
  avatarVisibleLayers?: {
    border?: boolean;
    balloon?: boolean;
    string?: boolean;
    body?: boolean;
    explosion?: boolean;
    scorebar?: boolean;
    score?: boolean;
  }; // Optional manual control of avatar layers

  // New prop for awaiting response animation
  isAwaitingResponse?: boolean;

  // Optional scaling props for each element
  avatarBorderScale?: number;
  avatarBalloonScale?: number;
  avatarStringScale?: number;
  avatarBodyScale?: number;
  avatarExplosionScale?: number;
  avatarScorebarScale?: number;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  player,
  avatarSize,
  showName = true, // Default to true
  avatarPreset = 'standard-static', // Default to 'standard-static'
  avatarVisibleLayers,
  isAwaitingResponse = false, // Default to false

  // Default all scaling to 1 so it won't affect existing usage
  avatarBorderScale = 1,
  avatarBalloonScale = 1,
  avatarStringScale = 1,
  avatarBodyScale = 1,
  avatarExplosionScale = 1,
  avatarScorebarScale = 1,
}) => {
  // Apply the awaiting-response class if needed
  const cardClassName = `player-card ${isAwaitingResponse ? 'awaiting-response' : ''}`;

  return (
    <div className={cardClassName}>
      {/* Pass props to Avatar */}
      <Avatar
        color={player.avatarColor}
        size={avatarSize}
        preset={avatarPreset}
        visibleLayers={avatarVisibleLayers}
        progress={player.progress}
        borderScale={avatarBorderScale}
        balloonScale={avatarBalloonScale}
        stringScale={avatarStringScale}
        bodyScale={avatarBodyScale}
        explosionScale={avatarExplosionScale}
        scorebarScale={avatarScorebarScale}
        isAwaitingResponse={isAwaitingResponse}
      />

      {/* Conditionally display the player's name */}
      {showName && <h3 className="player-name">{player.playerName}</h3>}
    </div>
  );
};

export default PlayerCard;

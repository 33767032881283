// stores/useUserStore.ts
// Global user state manager with performance-optimized access hooks using Zustand

import { create } from 'zustand';
import { useMemo } from 'react';
import { User } from '@shared/types';

interface UserState {
  user: User | null;
  isAuthInitialized: boolean;
  setUser: (user: User | null) => void;
  setIsAuthInitialized: (initialized: boolean) => void;
}

const useUserStore = create<UserState>((set) => ({
  user: null,
  isAuthInitialized: false,
  setUser: (user: User | null) => set(() => ({ user })),
  // Set the auth initialization flag directly
  setIsAuthInitialized: (initialized: boolean) =>
    set(() => ({ isAuthInitialized: initialized })),
}));

export const useUser = () => useUserStore((state) => state.user);
export const useIsAuthInitialized = () =>
  useUserStore((state) => state.isAuthInitialized);

// Memoized actions to prevent unnecessary re-renders when invoking setters
export const useUserActions = () => {
  const store = useUserStore();
  return useMemo(
    () => ({
      setUser: store.setUser,
      setIsAuthInitialized: store.setIsAuthInitialized,
    }),
    [store.setUser, store.setIsAuthInitialized]
  );
};

export { useUserStore };

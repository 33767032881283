// app/AppInitializer.tsx
// Handles initialization tasks at app level

import { useEffect } from 'react';
import { useUserFetch } from '../hooks/useUserFetch';
import { useUserActions } from '../stores/useUserStore';
import {
  useWebSocketStore,
  useWebSocketActions,
} from '../stores/useWebSocketStore';
import { useWebSocketListenersRoom } from '../hooks/useWebSocketListenersRoom';
import { useWebSocketListenersPlayer } from '../hooks/useWebSocketListenersPlayer';
import { useWebSocketListenersVoting } from '../hooks/useWebSocketListenersVoting';
import WebSocketService from '../services/webSocketService';

export const AppInitializer = (): null => {
  // Hooks for user data
  const { fetchUserDetails } = useUserFetch();
  const { setIsAuthInitialized } = useUserActions();

  // Hooks for WebSocket
  const connectionStatus = useWebSocketStore((state) => state.connectionStatus);
  const { setConnectionStatus, setSocketId, setLastError } =
    useWebSocketActions();

  // Initialize user data
  useEffect(() => {
    const initializeApp = async () => {
      try {
        console.info('Fetching user details...');
        await fetchUserDetails();
        console.info('User details fetched successfully.');
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch user details:', error.message);
        } else {
          console.error('Unknown error fetching user details:', error);
        }
      } finally {
        setIsAuthInitialized(true);
        console.info('Auth initialization complete.');
      }
    };

    console.info('Initializing app...');
    initializeApp();
  }, [fetchUserDetails, setIsAuthInitialized]);

  // Initialize WebSocket connection monitoring
  useEffect(() => {
    const webSocketService = WebSocketService.getInstance();

    // Enable debug logging to see connection events
    webSocketService.setDebug(true);

    // Set up status callback
    webSocketService.setStatusCallback((status) => {
      console.info('Setting WebSocket status:', status);
      setConnectionStatus(status);
      if (status === 'connected') {
        setSocketId(webSocketService.getSocketId());
      } else if (status === 'disconnected' || status === 'failed') {
        setSocketId(null);
      }
    });

    // Clean up
    return () => {
      webSocketService.setStatusCallback(() => {}); // Remove callback
    };
  }, [setConnectionStatus, setSocketId]);

  // Monitor WebSocket connection status
  useEffect(() => {
    if (connectionStatus === 'failed') {
      console.warn('WebSocket connection failed.');
      setLastError(
        'WebSocket connection failed. Please check your internet connection.'
      );
    } else {
      console.info(`WebSocket connection status: ${connectionStatus}`);
      setLastError(null);
    }
  }, [connectionStatus, setLastError]);

  // Initialize all WebSocket listeners
  useWebSocketListenersRoom();
  useWebSocketListenersPlayer();
  useWebSocketListenersVoting();

  useEffect(() => {
    console.info('WebSocket listeners attached.');
  }, []);

  return null;
};

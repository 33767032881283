// stores/useWebSocketStore.ts
// Global WebSocket state manager with performance-optimized access hooks

import { useMemo } from 'react';
import { create } from 'zustand';
import WebSocketService from '../services/webSocketService';
import { WebSocketConnectionStatus } from '../types';

interface WebSocketState {
  connectionStatus: WebSocketConnectionStatus;
  socketId: string | null;
  lastError: string | null;
  setConnectionStatus: (status: WebSocketConnectionStatus) => void;
  setSocketId: (id: string | null) => void;
  setLastError: (error: string | null) => void;
  resetConnection: () => void;
}

const useWebSocketStore = create<WebSocketState>((set) => {
  const webSocketService = WebSocketService.getInstance();

  return {
    // Initial state
    connectionStatus: 'connecting',
    socketId: null,
    lastError: null,

    // Actions
    setConnectionStatus: (status) => {
      console.info('WebSocket store: Setting connection status to', status);
      set(() => ({ connectionStatus: status }));
    },
    setSocketId: (id) => {
      console.info('WebSocket store: Setting socket ID to', id);
      set(() => ({ socketId: id }));
    },
    setLastError: (error) => set(() => ({ lastError: error })),
    resetConnection: () => {
      console.info('WebSocket store: Resetting connection');
      webSocketService.reconnect();
      set(() => ({ connectionStatus: 'connecting', lastError: null }));
    },
  };
});

// Individual state selectors
export const useConnectionStatus = () =>
  useWebSocketStore((state) => state.connectionStatus);

export const useSocketId = () => useWebSocketStore((state) => state.socketId);

export const useLastError = () => useWebSocketStore((state) => state.lastError);

export const useIsConnected = () => {
  const connectionStatus = useWebSocketStore((state) => state.connectionStatus);
  return useMemo(() => connectionStatus === 'connected', [connectionStatus]);
};

// Memoized actions to prevent unnecessary object recreation
export const useWebSocketActions = () => {
  const store = useWebSocketStore();

  return useMemo(
    () => ({
      setConnectionStatus: store.setConnectionStatus,
      setSocketId: store.setSocketId,
      setLastError: store.setLastError,
      resetConnection: store.resetConnection,
    }),
    [
      store.setConnectionStatus,
      store.setSocketId,
      store.setLastError,
      store.resetConnection,
    ]
  );
};

export { useWebSocketStore };

// hooks/usePlayerSave.ts
// Creates or updates a player

import { useCallback, useState } from 'react';
import { createPlayer, updatePlayer } from '../services/playerServices';
import { usePlayerMe, usePlayerActions } from '../stores/usePlayerStore';
import { useRoomStore } from '../stores/useRoomStore';
import { useWebSocketEmitters } from '../hooks/useWebSocketEmitters';
import { Player, CreatePlayerData, UpdatePlayerData } from '@shared/types';

interface UsePlayerSaveReturn {
  isSavingPlayer: boolean;
  errorSavingPlayer: string | null;
  savePlayer: (
    createData: CreatePlayerData | null,
    updateData: UpdatePlayerData | null,
    identifier?: string
  ) => Promise<void>;
}

export function usePlayerSave(): UsePlayerSaveReturn {
  const playerMe = usePlayerMe();
  const { setPlayerMe } = usePlayerActions();
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const { sendPlayerUpdate } = useWebSocketEmitters();
  const [isSavingPlayer, setIsSavingPlayer] = useState(false);
  const [errorSavingPlayer, setErrorSavingPlayer] = useState<string | null>(
    null
  );

  const savePlayer = useCallback(
    async (
      createData: CreatePlayerData | null,
      updateData: UpdatePlayerData | null,
      identifier?: string
    ) => {
      if (!roomDetails?.roomCode) return;

      setIsSavingPlayer(true);
      setErrorSavingPlayer(null);

      try {
        let savedPlayer: Player;
        const targetPlayerId = identifier || playerMe?._id;

        if (targetPlayerId && updateData) {
          savedPlayer = await updatePlayer(
            roomDetails.roomCode,
            targetPlayerId,
            updateData
          );
        } else {
          if (!createData) {
            throw new Error('Cannot create player without createData.');
          }
          savedPlayer = await createPlayer(roomDetails.roomCode, createData);
        }

        // If playerMe is target then then set state
        if (targetPlayerId === playerMe?._id) {
          setPlayerMe(savedPlayer);
        }

        // In any case, send event so others can refresh players in room
        sendPlayerUpdate(roomDetails.roomCode, savedPlayer._id);
        console.log('ws: Sending player update');
      } catch (error) {
        console.error('Error saving player:', error);
        setErrorSavingPlayer(
          error instanceof Error ? error.message : 'An unknown error occurred.'
        );
      } finally {
        setIsSavingPlayer(false);
      }
    },
    [playerMe, setPlayerMe, sendPlayerUpdate, roomDetails?.roomCode]
  );

  return { isSavingPlayer, errorSavingPlayer, savePlayer };
}

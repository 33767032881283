// hooks/useRoomVerification.ts
// Checks if room exists without using Room Context so Home doesn't need to be wrapped in Gameplay Providers

import { useState } from 'react';
import { verifyRoomExists } from '../services/roomServices';

export function useRoomVerification() {
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorVerifying, setErrorVerifying] = useState<string | null>(null);

  const verifyRoom = async (roomCode: string): Promise<boolean> => {
    setIsVerifying(true);
    setErrorVerifying(null);

    try {
      return await verifyRoomExists(roomCode);
    } catch (err) {
      if (err instanceof Error) {
        setErrorVerifying(err.message);
      }
      return false;
    } finally {
      setIsVerifying(false);
    }
  };

  return {
    verifyRoom,
    isVerifying,
    errorVerifying,
  };
}

// utilities/analyticsPageLogging.ts
// Tracks when users navigate to different pages and sends to GA

export const logPageView = (
  path: string,
  title: string,
  roomCode?: string
): void => {
  if (window.gtag) {
    window.gtag('config', 'G-5KDD1FQECM', {
      page_path: path, // Page URL path (e.g., "/home")
      page_title: title, // Page title (e.g., "Home")
      ...(roomCode && { room_code: roomCode }), // Include room_code only if defined
    });
  } else {
    console.warn('Google Analytics is not initialized.');
  }
};

// shared/utilities/mapLanguages.ts
// Unified utility for mapping language to language properties

import { Language, Accent } from '../types';

export const languageMapping: Language[] = [
  {
    value: 'en',
    label: 'English',
    disabled: false,
    accents: [
      {
        value: 'en-US',
        label: 'American',
        disabled: false,
      },
      {
        value: 'en-AU',
        label: 'Australian',
        disabled: false,
      },
      {
        value: 'en-GB',
        label: 'British',
        disabled: false,
      },
      {
        value: 'en-IN',
        label: 'Indian',
        disabled: false,
      },
    ],
  },
  {
    value: 'de',
    label: 'Deutsch (bald verfügbar)',
    disabled: true,
    accents: [
      {
        value: 'de-DE',
        label: 'Deutsch',
        disabled: true,
      },
    ],
  },
  {
    value: 'es',
    label: 'Español (próximamente)',
    disabled: true,
    accents: [
      {
        value: 'es-ES',
        label: 'Español de España',
        disabled: true,
      },
      {
        value: 'es-US',
        label: 'Español de América',
        disabled: true,
      },
    ],
  },
  {
    value: 'fr',
    label: 'Français (à venir)',
    disabled: true,
    accents: [
      {
        value: 'fr-FR',
        label: 'Français de France',
        disabled: true,
      },
      {
        value: 'fr-CA',
        label: 'Français Canadien',
        disabled: true,
      },
    ],
  },
  {
    value: 'it',
    label: 'Italiano (prossimamente)',
    disabled: true,
    accents: [
      {
        value: 'it-IT',

        label: 'Italiano',
        disabled: true,
      },
    ],
  },
  {
    value: 'nl',
    label: 'Nederlands (binnenkort beschikbaar)',
    disabled: true,
    accents: [
      {
        value: 'nl-NL',

        label: 'Nederlands',
        disabled: true,
      },
    ],
  },
];

// Generic helper to find a language by value
export function findLanguage(language: string): Language | undefined {
  return languageMapping.find((l) => l.value === language);
}

// Generic helper to find an accent by value
export function findAccent(accent: string): Accent | undefined {
  for (const language of languageMapping) {
    const foundAccent = language.accents.find((a) => a.value === accent);
    if (foundAccent) return foundAccent;
  }
  return undefined;
}

// Helper functions to get specific properties using a language
export function getLanguageLabel(language: string): string | undefined {
  return findLanguage(language)?.label;
}

export function isLanguageDisabled(language: string): boolean | undefined {
  return findLanguage(language)?.disabled;
}

// Helper functions to get specific properties using an accent
export function getAccentLabel(accent: string): string | undefined {
  return findAccent(accent)?.label;
}

export function isAccentDisabled(accent: string): boolean | undefined {
  return findAccent(accent)?.disabled;
}

// Helper to get all enabled languages
export function getEnabledLanguages(): Language[] {
  return languageMapping.filter((l) => !l.disabled);
}

// Helper to get all enabled accents for a language
export function getEnabledAccents(language: string): Accent[] {
  const lang = findLanguage(language);
  return lang?.accents.filter((a) => !a.disabled) || [];
}

// Helper to get all language values
export function getAllLanguages(): string[] {
  return languageMapping.map((l) => l.value);
}

// Helper to get all accents for a language
export function getLanguageAccents(language: string): Accent[] {
  return findLanguage(language)?.accents || [];
}

// Helper to get enabled language and accent counts
export function getEnabledLanguageAndAccentCounts(): {
  languagesCount: number;
  accentsCount: number;
} {
  const enabledLanguages = getEnabledLanguages();
  return {
    languagesCount: enabledLanguages.length,
    accentsCount: enabledLanguages.reduce(
      (count, language) => count + getEnabledAccents(language.value).length,
      0
    ),
  };
}

// frontend/utilities/mapSharedAssets.ts
// Single source of truth for sound effect mapping

export const SOUND_EFFECTS = {
  TIMER_END: {
    id: 'timer_end',
    path: 'sound-effects/timer_end.wav',
  },
  TIMER_BEEP: {
    id: 'timer_beep',
    path: 'sound-effects/timer_beep.wav',
  },
  BUBBLE_POP: {
    id: 'bubble_pop',
    path: 'sound-effects/bubble_pop.wav',
  },
  POWER_UP: {
    id: 'power_up',
    path: 'sound-effects/power_up.wav',
  },
  GRAND_ENTRANCE: {
    id: 'grand_entrance',
    path: 'sound-effects/grand_entrance.wav',
  },
} as const;

// Helper function to get the path for a sound effect
export function getSoundEffectPath(key: string): string {
  const entry = Object.values(SOUND_EFFECTS).find(
    (effect) => effect.id === key
  );
  if (!entry) {
    throw new Error(`Invalid sound effect key: ${key}`);
  }
  return entry.path;
}

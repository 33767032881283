// app/App.tsx

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ensureSessionCookie } from '../utilities/cookieUtils';
import '../utilities/axiosConfig';

// Pages
import Email from '../pages/Email';
import EmailVerify from '../pages/EmailVerify';
import Game from '../pages/Game';
import GameLibrary from '../pages/GameLibrary';
import Home from '../pages/Home';
import Player from '../pages/Player';
import Playing from '../pages/Playing';
import Room from '../pages/Room';
import Subscribe from '../pages/Subscribe';
import Terms from '../pages/Terms';
import About from '../pages/About';

// Components & Providers
import { GameplayLayout } from './GameplayLayout';
import { AuthenticatedRoute, SubscriptionRoute } from './ProtectedRoutes';
import { AppProviders } from './AppProviders';

// Main App component that sets up the router and initializes the session ID cookie
const App: React.FC = () => {
  // Checks if a session ID is already set and, if not, creates one
  useEffect(() => {
    ensureSessionCookie('sessionId');
  }, []);

  return (
    <Router>
      <AppProviders>
        <Routes>
          {/* Protected Routes */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/subscribe" element={<Subscribe />} />
          </Route>

          <Route element={<SubscriptionRoute />}>
            <Route path="/game-library" element={<GameLibrary />} />
          </Route>

          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/email" element={<Email />} />
          <Route path="/email-verification" element={<EmailVerify />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />

          {/* Gameplay Routes */}
          <Route
            path="/room/:roomId"
            element={
              <GameplayLayout>
                <Room />
              </GameplayLayout>
            }
          />
          <Route
            path="/room/:roomId/player"
            element={
              <GameplayLayout>
                <Player />
              </GameplayLayout>
            }
          />
          <Route
            path="/game/:roomId"
            element={
              <GameplayLayout>
                <Game />
              </GameplayLayout>
            }
          />
          <Route
            path="/game/:roomId/playing"
            element={
              <GameplayLayout>
                <Playing />
              </GameplayLayout>
            }
          />
        </Routes>
      </AppProviders>
    </Router>
  );
};

export default App;

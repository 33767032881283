// hooks/useUserFetch.ts
// Handles retrieval and setting state for user

import { useCallback, useState } from 'react';
import { useUserActions } from '../stores/useUserStore';
import { getUserDetails } from '../services/userServices';
import { User } from '@shared/types';

// Hook to fetch user details and update the global user store
export function useUserFetch(): {
  fetchUserDetails: () => Promise<void>;
  isFetchingUser: boolean;
  fetchingUserError: string | null;
} {
  // Get the setUser action from the global user store
  const { setUser } = useUserActions();

  // Local state for tracking loading status and any fetching error
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);
  const [fetchingUserError, setFetchingUserError] = useState<string | null>(
    null
  );

  // Function to fetch user details from the backend
  const fetchUserDetails = useCallback(async (): Promise<void> => {
    setIsFetchingUser(true);
    setFetchingUserError(null);

    try {
      // Retrieve user details via the service call
      const fetchedUser: User = await getUserDetails();
      // Update global state with fetched user details
      setUser(fetchedUser);
    } catch (err: unknown) {
      console.error('Failed to fetch user details:', err);
      // Reset global user state on error
      setUser(null);

      // Explicit type check for error object to handle specific status codes
      if (err && typeof err === 'object' && 'response' in err) {
        const error = err as { response?: { status: number } };
        setFetchingUserError(
          error.response?.status === 401 ? 'no_session' : 'server_error'
        );
      } else {
        setFetchingUserError('server_error');
      }
    } finally {
      setIsFetchingUser(false);
    }
  }, [setUser]);

  return {
    fetchUserDetails,
    isFetchingUser,
    fetchingUserError,
  };
}

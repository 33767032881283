// frontend/services/axiosWrapper.ts

import { AxiosResponse } from 'axios';
import { ApiResponse } from '@shared/types';
import { processApiResponse } from '@shared/utilities';

// Wraps Axios promise returning an ApiResponse<T> and processes using shared helper.
export async function handleApiResponse<T>(
  promise: Promise<AxiosResponse<ApiResponse<T>>>
): Promise<T> {
  const response = await promise;
  return processApiResponse(response.data);
}

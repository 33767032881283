// hooks/useUserOtpRequest.ts
// Handles requesting a one time password from backend for a specified email address

import { useCallback, useState } from 'react';
import { requestOTP } from '../services/userServices';
import { useUserActions } from '../stores/useUserStore';

// Custom hook to request an OTP using the user's email.
// Returns loading state, error state, and function to call requestOTP.
export function useUserOtpRequest() {
  const { setUser } = useUserActions();
  const [isRequesting, setIsRequesting] = useState(false);
  const [errorRequestingOtp, setErrorRequestingOtp] = useState<string | null>(
    null
  );

  const requestOtp = useCallback(
    async (email: string): Promise<void> => {
      setIsRequesting(true);
      setErrorRequestingOtp(null);
      try {
        const userData = await requestOTP(email);
        setUser(userData);
      } catch (err) {
        console.error('Failed to request OTP:', err);
        setErrorRequestingOtp('Failed to request OTP.');
      } finally {
        setIsRequesting(false);
      }
    },
    [setUser]
  );

  return {
    requestOtp,
    isRequesting,
    errorRequestingOtp,
  };
}

// hooks/usePlayerMeFetch.ts
// Handles retrieval and storing of playerMe

import { useCallback, useState } from 'react';
import { getPlayerBySession } from '../services/playerServices';
import { usePlayerActions } from '../stores/usePlayerStore';
import { isPlayingRoute } from '../utilities/isRoute';
import usePlayerCookie from './usePlayerCookie';

export interface UsePlayerMeFetchReturn {
  isFetchingPlayer: boolean;
  errorFetchingPlayer: string | null;
  fetchPlayerMe: () => Promise<void>;
}

// Fetches player data by session ID and updates the store state.
export function usePlayerMeFetch(): UsePlayerMeFetchReturn {
  const { setPlayerMe } = usePlayerActions();
  const sessionId = usePlayerCookie('sessionId');
  const [isFetchingPlayer, setIsFetchingPlayer] = useState(false);
  const [errorFetchingPlayer, setErrorFetchingPlayer] = useState<string | null>(
    null
  );

  const fetchPlayerMe = useCallback(async () => {
    if (!isPlayingRoute() || !sessionId) {
      console.log(
        'Not a playing route or no sessionId. Skipping fetchPlayerMe.'
      );
      setPlayerMe(null);
      return;
    }

    setIsFetchingPlayer(true);
    setErrorFetchingPlayer(null);

    try {
      const data = await getPlayerBySession(sessionId);
      setPlayerMe(data);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to fetch player details.';
      console.error('Error fetching playerMe:', error);
      setErrorFetchingPlayer(errorMessage);
      setPlayerMe(null);
    } finally {
      setIsFetchingPlayer(false);
    }
  }, [sessionId, setPlayerMe]);

  return {
    isFetchingPlayer,
    errorFetchingPlayer,
    fetchPlayerMe,
  };
}

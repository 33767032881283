// hooks/websocket/useWebSocketListenersRoom.ts

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebSocketService from '../services/webSocketService';
import { useRoomActions } from '../stores/useRoomStore';
import { useSocketId } from 'src/stores/useWebSocketStore';
import { useRoomFetch } from '../hooks/useRoomFetch';
import { isPlayingRoute, isGameRoute } from 'src/utilities/isRoute';
import { WebSocketEventHandler } from '../types';

export const useWebSocketListenersRoom = () => {
  const { fetchRoomDetails } = useRoomFetch();
  const currentSocketId = useSocketId();
  const { setReadyForSelection, clearRoom } = useRoomActions();
  const webSocketService = WebSocketService.getInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    // Room details handler
    const handleRoomDetailsUpdate: WebSocketEventHandler<
      'roomDetailsUpdate'
    > = (data) => {
      if (!data.roomCode) return;
      console.log('[ws] Room update received:', data);

      // Check if the sender is the current user
      if (data.senderSocketId === currentSocketId) {
        console.log('[ws] Skipping fetch - update originated from this client');
      } else {
        console.log(
          '[ws] Fetching room details - update from different client'
        );
        fetchRoomDetails(data.roomCode);
      }
    };

    // Selection state handlers
    const handleReadyForSelectionUpdate: WebSocketEventHandler<
      'latestRoomSelectionState' | 'readyForSelectionUpdate'
    > = (data) => {
      console.log('[ws] Ready for Selection update received:', data);
      setReadyForSelection(data.isReady);
    };

    // Game end handlers
    const handleEndGameReplay: WebSocketEventHandler<'endGameReplay'> = (
      data
    ) => {
      if (!data.roomCode) {
        console.error('Room code is missing for replay redirect');
        return;
      }
      console.log('[ws] End Game to Replay received');
      // Redirect non-hosts based on current page and roomCode
      if (isPlayingRoute()) {
        navigate(`/room/${data.roomCode}/player`);
      } else if (isGameRoute()) {
        navigate(`/room/${data.roomCode}`);
      }
    };

    const handleEndGameLibrary: WebSocketEventHandler<'endGameLibrary'> = (
      data
    ) => {
      if (!data.roomCode) {
        console.error('Room code is missing for library redirect');
        return;
      }
      console.log('[ws] End Game to Library received');
      // Redirect non-hosts based on current page and roomCode
      if (isPlayingRoute()) {
        navigate('/');
      } else if (isGameRoute()) {
        navigate('/game-library');
      }
      clearRoom();
    };

    // Register all room-related event listeners
    webSocketService.onEvent('roomDetailsUpdate', handleRoomDetailsUpdate);
    webSocketService.onEvent(
      'readyForSelectionUpdate',
      handleReadyForSelectionUpdate
    );
    webSocketService.onEvent(
      'latestRoomSelectionState',
      handleReadyForSelectionUpdate
    );
    webSocketService.onEvent('endGameReplay', handleEndGameReplay);
    webSocketService.onEvent('endGameLibrary', handleEndGameLibrary);

    // Cleanup function
    return () => {
      webSocketService.offEvent('roomDetailsUpdate', handleRoomDetailsUpdate);
      webSocketService.offEvent(
        'readyForSelectionUpdate',
        handleReadyForSelectionUpdate
      );
      webSocketService.offEvent(
        'latestRoomSelectionState',
        handleReadyForSelectionUpdate
      );
      webSocketService.offEvent('endGameReplay', handleEndGameReplay);
      webSocketService.offEvent('endGameLibrary', handleEndGameLibrary);
    };
  }, [
    webSocketService,
    fetchRoomDetails,
    setReadyForSelection,
    navigate,
    currentPath,
    currentSocketId,
    clearRoom,
  ]);
};

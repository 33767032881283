// components/icons/Settings.tsx

import React from 'react';
import './Settings.css';

type SettingsIconProps = {
  onClick: () => void;
  size?: number;
  color?: string;
  hoverColor?: string;
};

const SettingsIcon: React.FC<SettingsIconProps> = ({
  onClick,
  size = 40,
  color = '#B3B3B3',
  hoverColor = '#d0ff00',
}) => {
  return (
    <button
      className="settings-icon-button"
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="none"
        className="settings-icon"
      >
        <path
          d="M29.8438 56.5703C28.625 56.5703 27.7578 55.8438 27.4531 54.6484L26.2344 49.4922C25.3203 49.1875 24.4531 48.8359 23.6562 48.4609L19.1562 51.2266C18.1484 51.8594 16.9766 51.7656 16.1328 50.9219L13.1328 47.9219C12.2891 47.0781 12.1484 45.8594 12.8516 44.8516L15.5938 40.375C15.2188 39.5547 14.8672 38.7109 14.6094 37.8672L9.40625 36.625C8.21094 36.3438 7.50781 35.4766 7.50781 34.2578V30.0156C7.50781 28.8203 8.21094 27.9531 9.40625 27.6719L14.5625 26.4297C14.8672 25.4688 15.2422 24.6016 15.5469 23.875L12.8047 19.3281C12.1484 18.2969 12.2188 17.1953 13.0859 16.3281L16.1328 13.3047C16.9766 12.4609 18.0781 12.3203 19.0859 12.9531L23.6328 15.7656C24.4297 15.3438 25.3203 14.9922 26.2344 14.6875L27.4531 9.50781C27.7578 8.3125 28.625 7.58594 29.8438 7.58594H34.1562C35.375 7.58594 36.2422 8.3125 36.5234 9.50781L37.7422 14.7344C38.7031 15.0391 39.5703 15.3906 40.3203 15.7891L44.9141 12.9531C45.9219 12.3203 46.9766 12.5078 47.8438 13.3047L50.9141 16.3281C51.7812 17.1953 51.8281 18.2969 51.1953 19.3281L48.4297 23.875C48.7578 24.6016 49.1328 25.4688 49.4375 26.4297L54.5938 27.6719C55.7891 27.9531 56.4922 28.8203 56.4922 30.0156V34.2578C56.4922 35.4766 55.7891 36.3438 54.5938 36.625L49.3906 37.8672C49.1328 38.7109 48.7812 39.5547 48.3828 40.375L51.1484 44.8516C51.8281 45.8594 51.7109 47.0781 50.8672 47.9219L47.8438 50.9219C47 51.7656 45.8516 51.8594 44.8438 51.2266L40.3203 48.4609C39.5469 48.8359 38.6562 49.1875 37.7422 49.4922L36.5234 54.6484C36.2422 55.8438 35.375 56.5703 34.1562 56.5703H29.8438ZM32 40.3516C36.5703 40.3516 40.2734 36.6484 40.2734 32.0781C40.2734 27.5078 36.5703 23.8047 32 23.8047C27.4297 23.8047 23.7266 27.5078 23.7266 32.0781C23.7266 36.6484 27.4297 40.3516 32 40.3516Z"
          fill={color}
        />
      </svg>
      <style>{`
        .settings-icon-button:hover .settings-icon path {
          fill: ${hoverColor};
        }
      `}</style>
    </button>
  );
};

export default SettingsIcon;

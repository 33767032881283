// utilities/getUrlFrontend.ts
// Get the base URL for frontend (e.g. QR code generation)

export const getUrlFrontend = (): string => {
  try {
    const { protocol, hostname, port } = window.location;

    // If we're in development (localhost)
    if (hostname === 'localhost' && port) {
      // Use the network IP instead of localhost
      return `${protocol}//192.168.4.106:${port}`;
    }

    // If we're in development but already using network IP, or in production
    if (port) {
      return `${protocol}//${hostname}:${port}`;
    }

    // In production (no port)
    return `${protocol}//${hostname}`;
  } catch (error) {
    console.error('Error getting frontend URL:', error);
    // Fallback to a default URL if needed
    return 'https://airheadgames.com';
  }
};

// pages/Home.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../stores/useUserStore';
import { useRoomVerification } from '../hooks/useRoomVerification';
import Form from '../components/Form';
import HamburgerMenu from '../components/HamburgerMenu';
import './Home.css';

const Home: React.FC = () => {
  const user = useUser();
  const { verifyRoom, errorVerifying } = useRoomVerification();
  const navigate = useNavigate();

  const handleJoinRoom = async (
    roomCode: string,
    setError: (msg: string | null) => void
  ) => {
    if (!roomCode) {
      setError('Please enter a room code.');
      return;
    }

    try {
      console.log('Attempting to join room with code:', roomCode);
      const isValid = await verifyRoom(roomCode);
      if (isValid) {
        navigate(`/room/${roomCode}/player`);
      } else {
        setError('Invalid room code. Please try again.');
      }
    } catch (error) {
      setError(
        errorVerifying || 'Failed to verify room code. Please try again.'
      );
    }
  };

  const transparentButtonLabel = user
    ? user.subscriber
      ? 'Go To Game Library'
      : 'Finish Onboarding'
    : 'Sign Up / Sign In';

  const handleTransparentButtonClick = () => {
    if (!user) {
      navigate('/email');
    } else if (user.subscriber) {
      navigate('/game-library');
    } else {
      navigate('/subscribe');
    }
  };

  return (
    <div className="home-container">
      <HamburgerMenu />
      <div className="logo-container">
        <img
          src="/assets/logo_stacked.png"
          alt="Airhead Games Logo"
          className="logo"
        />
      </div>
      <Form
        className="form-container--home"
        placeholder="Enter Code"
        buttonLabel="Join Room"
        onSubmit={handleJoinRoom}
        validationType="number"
        transparentButtonLabel={transparentButtonLabel}
        onTransparentButtonClick={handleTransparentButtonClick}
      />
    </div>
  );
};

export default Home;

// contexts/AudioContext.tsx
// Responsible for maintaining minimal global audio states and exposing them

import React, {
  createContext,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useSpeechManager } from '../hooks/useAudioSpeechManager';
import { useAudioSfxManager } from '../hooks/useAudioSfxManager';
import { useAudioMusicManager } from '../hooks/useAudioMusicManager';
import { WebAudioService } from 'src/services/audio/webAudioService';
import { SoundEffectKey } from '../types';

// -----------------------------------------------------------------------------
// Define the shape of our context value
// -----------------------------------------------------------------------------
export interface AudioContextValue {
  // Audio state
  isMuted: boolean;
  isSpeaking: boolean;
  waitUntilSpeechComplete: () => Promise<void>;
  isSfxPlaying: boolean;
  isMusicPlaying: boolean;
  currentMusicTrack: string | null;

  // State setters
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMute: () => void;

  // Audio services
  webAudio: WebAudioService;

  // Audio context control
  resumeAudioContext: () => Promise<void>;

  // Speech functions
  queueSpeechForPlayback: (text: string) => void;
  stopSpeechPlayback: () => void;

  // SFX functions
  loadSoundEffect: (soundKey: SoundEffectKey) => Promise<HTMLAudioElement>;
  playSoundEffect: (soundKey: SoundEffectKey) => Promise<void>;
  stopSfxPlayback: () => void;

  // Music functions
  playMusic: (trackKey: string) => Promise<void>;
  stopMusic: () => void;

  // Audio Refs
  speechAudioRef: React.RefObject<HTMLAudioElement>;
  sfxAudioRef: React.RefObject<HTMLAudioElement>;
  musicAudioRef: React.RefObject<HTMLAudioElement>;
}

// -----------------------------------------------------------------------------
// Create context with an undefined default. We'll provide it below.
// -----------------------------------------------------------------------------
export const AudioContext = createContext<AudioContextValue | undefined>(
  undefined
);

// -----------------------------------------------------------------------------
// Props for our AudioProvider component
// -----------------------------------------------------------------------------
interface AudioProviderProps {
  children: React.ReactNode;
  initialMuted?: boolean;
}

// -----------------------------------------------------------------------------
// AudioProvider component owns the global Audio states.
// It creates external refs for the audio elements, renders them hidden,
// and passes those refs into our manager hooks and Web Audio hook.
// -----------------------------------------------------------------------------
export function AudioProvider({
  children,
  initialMuted = true,
}: AudioProviderProps) {
  // Create external refs for the audio elements
  const speechAudioRef = useRef<HTMLAudioElement>(null);
  const sfxAudioRef = useRef<HTMLAudioElement>(null);
  const musicAudioRef = useRef<HTMLAudioElement>(null);

  // Create service instances
  const webAudioRef = useRef<WebAudioService | null>(null);

  // State management
  const [isMuted, setIsMuted] = useState<boolean>(initialMuted);

  // Toggle mute function
  const toggleMute = useCallback(() => {
    setIsMuted((prev) => !prev);
  }, []);

  // Resume audio context function
  const resumeAudioContext = useCallback(async (): Promise<void> => {
    if (webAudioRef.current) {
      await webAudioRef.current.resume();
    }
  }, []);

  // Set crossOrigin attribute on audio elements
  useEffect(() => {
    // Critical fix: Set crossOrigin attribute on all audio elements
    if (speechAudioRef.current) {
      speechAudioRef.current.crossOrigin = 'anonymous';
    }
    if (sfxAudioRef.current) {
      sfxAudioRef.current.crossOrigin = 'anonymous';
    }
    if (musicAudioRef.current) {
      musicAudioRef.current.crossOrigin = 'anonymous';
    }
  }, []);

  // Pass the external refs to the manager hooks
  const speechManager = useSpeechManager(speechAudioRef);
  const sfxManager = useAudioSfxManager(
    sfxAudioRef,
    webAudioRef.current || undefined
  );
  const musicManager = useAudioMusicManager(musicAudioRef);

  // Initialize WebAudioService and connect audio elements
  useEffect(() => {
    webAudioRef.current = new WebAudioService();
    if (speechAudioRef.current) {
      webAudioRef.current.connectSource(speechAudioRef.current);
    }
    if (sfxAudioRef.current) {
      webAudioRef.current.connectSource(sfxAudioRef.current);
    }
    if (musicAudioRef.current) {
      webAudioRef.current.connectSource(musicAudioRef.current);
    }
    return () => {
      if (webAudioRef.current) {
        webAudioRef.current.dispose();
        webAudioRef.current = null;
      }
    };
  }, []);

  // Effect to update mute state
  useEffect(() => {
    if (webAudioRef.current) {
      webAudioRef.current.setMute(isMuted);
    }
  }, [isMuted]);

  // Wrapper function for music playback
  const playMusic = useCallback(
    (trackKey: string) => musicManager.playMusicTrack(trackKey),
    [musicManager]
  );

  // Memoize the context value
  const contextValue: AudioContextValue = useMemo(
    () => ({
      isMuted,
      isSpeaking: speechManager.isSpeaking,
      waitUntilSpeechComplete: speechManager.waitUntilSpeechComplete,
      isSfxPlaying: sfxManager.isSfxPlaying,
      isMusicPlaying: musicManager.isMusicPlaying,
      currentMusicTrack: musicManager.currentTrack,
      setIsMuted,
      toggleMute,
      webAudio: webAudioRef.current as WebAudioService,
      resumeAudioContext,
      queueSpeechForPlayback: speechManager.queueSpeechForPlayback,
      stopSpeechPlayback: speechManager.stopSpeechPlayback,
      loadSoundEffect: sfxManager.loadSoundEffect,
      playSoundEffect: sfxManager.playSoundEffect,
      stopSfxPlayback: sfxManager.stopSfxPlayback,
      playMusic,
      stopMusic: musicManager.stopMusicPlayback,
      speechAudioRef,
      sfxAudioRef,
      musicAudioRef,
    }),
    [
      isMuted,
      speechManager.isSpeaking,
      speechManager.waitUntilSpeechComplete,
      sfxManager.isSfxPlaying,
      musicManager.isMusicPlaying,
      musicManager.currentTrack,
      toggleMute,
      resumeAudioContext,
      speechManager.queueSpeechForPlayback,
      speechManager.stopSpeechPlayback,
      sfxManager.loadSoundEffect,
      sfxManager.playSoundEffect,
      sfxManager.stopSfxPlayback,
      playMusic,
      musicManager.stopMusicPlayback,
    ]
  );

  return (
    <AudioContext.Provider value={contextValue}>
      {children}
      {/* Hidden audio elements used by audio services */}
      <audio ref={speechAudioRef} style={{ display: 'none' }} />
      <audio ref={sfxAudioRef} style={{ display: 'none' }} />
      <audio ref={musicAudioRef} style={{ display: 'none' }} loop />
    </AudioContext.Provider>
  );
}

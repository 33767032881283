// pages/GameLibrary.tsx

import React, { useEffect, useState } from 'react';
import GameGridContainer from '../components/GameGridContainer';
import GameGrid from '../components/GameGrid';
import Wordmark from '../components/Wordmark';
import GameCoverModal from '../components/GameCoverModal';
import UserSettings from '../components/UserSettings';
import XMarkIcon from '../components/icons/XMark';
import SettingsIcon from '../components/icons/Settings';
import { useGames } from '../stores/useGameStore';
import { useGameSelection } from '../hooks/useGameSelection';
import { useGameFetch } from '../hooks/useGameLibraryFetch';
import './GameLibrary.css';

const GameLibrary: React.FC = () => {
  const games = useGames();
  const { gameSelected, gameSelectById, gameSelectionClear } =
    useGameSelection();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Track settings state
  const [iconSize, setIconSize] = useState(window.innerWidth <= 720 ? 32 : 64);
  const { fetchGames, isFetchingGames } = useGameFetch();

  // Fetch games if we don't have an active count yet
  useEffect(() => {
    if (games.length === 0) {
      fetchGames();
    }
  }, [games, fetchGames]);

  const displayedGames = isFetchingGames ? [] : games;

  // We will refactor this icon resizing effect later when we implement tailwind
  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth <= 720 ? 32 : 64);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGameClick = (gameId: string) => {
    const game = games.find((g) => g._id === gameId);
    if (!game?.isActive) {
      alert('This game is not available yet!');
      return;
    }

    gameSelectById(gameId); // Use the hook's function instead
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    gameSelectionClear();
    setIsModalOpen(false);
  };

  return (
    <div className="game-library-container">
      <Wordmark />
      <div className="game-library-xmark-settings">
        {isSettingsOpen ? (
          <XMarkIcon size={iconSize} onClick={() => setIsSettingsOpen(false)} />
        ) : (
          <SettingsIcon
            size={iconSize}
            onClick={() => setIsSettingsOpen(true)}
          />
        )}
      </div>
      <main>
        {isSettingsOpen ? (
          <UserSettings /> // Render UserSettings component when settings are open
        ) : (
          <GameGridContainer title="Games">
            <GameGrid games={displayedGames} onGameClick={handleGameClick} />
          </GameGridContainer>
        )}

        {/* Use the updated GameCoverModal */}
        <GameCoverModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          game={gameSelected}
        />
      </main>
    </div>
  );
};

export default GameLibrary;

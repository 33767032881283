// configs/colors.ts
// Defines color palette

// TODO: I think this is a temporary solution until we move to Tailwind

import { ColorPalette } from '../types';

export const colorConfig: ColorPalette = {
  theme: {
    background: {
      value: '#000000',
      description: 'Primary background color for dark theme',
    },
    primaryText: {
      value: '#ffffff',
      description: 'Main text color on dark backgrounds',
    },
    secondaryText: {
      value: '#b3b3b3',
      description: 'Secondary text for less emphasis',
    },
    disabled: {
      value: '#828282',
      description: 'Color for disabled elements',
    },
    accentGray: {
      value: '#414141',
      description: 'Subtle elements and borders',
    },
    brandPrimary: {
      value: '#d0ff00',
      description: 'Primary brand lime green color',
    },
    success: {
      value: '#00ff2f',
      description: 'Success state color',
    },
    accentGreen: {
      value: '#68ff18',
      description: 'Lighter version of success color',
    },
    error: {
      value: '#ff0050',
      description: 'Error and failure state color',
    },
    link: {
      value: '#00d0ff',
      description: 'Hyperlink and interactive element color',
    },
  },
  players: [
    { value: '#3f4cd2', description: 'Darkest Purple-Blue' },
    { value: '#6d4cff', description: 'Dark Purple' },
    { value: '#a84cff', description: 'Purple' },
    { value: '#d84cff', description: 'Magenta-Purple' },
    { value: '#ff4cde', description: 'Magenta' },
    { value: '#ff4c9c', description: 'Pink Coral' },
    { value: '#ff6c4c', description: 'Orange' },
    { value: '#ffc74c', description: 'Bright Yellow-Orange' },
    { value: '#ffe34c', description: 'Pale Yellow' },
    { value: '#8cff8a', description: 'Light Green' },
    { value: '#4cffc7', description: 'Mint Green' },
    { value: '#4cdeff', description: 'Light Cyan' },
    { value: '#4c9eff', description: 'Sky Blue' },
    { value: '#4c72ff', description: 'Medium Blue' },
    { value: '#3c6aa0', description: 'Slate Blue' },
    { value: '#268080', description: 'Teal' },
    { value: '#72a08f', description: 'Muted Teal-Green' },
    { value: '#a4b49a', description: 'Sage Green' },
    { value: '#e8c89f', description: 'Light Beige' },
    { value: '#dba66f', description: 'Sandy Tan' },
    { value: '#d2933f', description: 'Golden Brown' },
    { value: '#b87332', description: 'Copper' },
    { value: '#8b5229', description: 'Dark Brown' },
  ],
};

// Convenience exports for common use cases
export const playerColors = colorConfig.players.map((color) => color.value);
export const themeColors = {
  background: colorConfig.theme.background.value,
  primaryText: colorConfig.theme.primaryText.value,
  secondaryText: colorConfig.theme.secondaryText.value,
  disabled: colorConfig.theme.disabled.value,
  accentGray: colorConfig.theme.accentGray.value,
  brandPrimary: colorConfig.theme.brandPrimary.value,
  success: colorConfig.theme.success.value,
  accentGreen: colorConfig.theme.accentGreen.value,
  error: colorConfig.theme.error.value,
  link: colorConfig.theme.link.value,
};

// shared/utilities/mapContentStyle.ts
// Unified utility for mapping contentStyle to style properties

import { ContentStyle } from '../types';

export const contentStyleMapping: ContentStyle[] = [
  {
    value: 'standard',
    label: 'Standard, for general audiences',
    description: 'suitable for general audiences',
    disabled: false,
  },
  {
    value: 'safe',
    label: 'Safe, for family-friendly audiences',
    description:
      'safe, for family-friendly audiences. The content should be written in a manner that is understandable by children',
    disabled: false,
  },
  {
    value: 'edgy',
    label: 'Edgy, for mature audiences',
    description:
      'edgy, for adult audiences. The content should be bold, provocative, unapologetic, and challenge conventions. Address societal taboos, intense themes, and controversial subjects without being offensive',
    disabled: false,
  },
];

// Generic helper to find a style by value
export function findContentStyle(
  style: ContentStyle['value']
): ContentStyle | undefined {
  return contentStyleMapping.find((s) => s.value === style);
}

// Helper functions to get specific properties using a style
export function getContentLabel(
  style: ContentStyle['value']
): string | undefined {
  return findContentStyle(style)?.label;
}

export function getContentDescription(
  style: ContentStyle['value']
): string | undefined {
  return findContentStyle(style)?.description;
}

export function isContentStyleDisabled(
  style: ContentStyle['value']
): boolean | undefined {
  return findContentStyle(style)?.disabled;
}

// Helper to get all enabled content styles
export function getEnabledContentStyles(): ContentStyle[] {
  return contentStyleMapping.filter((s) => !s.disabled);
}

// Helper to get all content style values
export function getAllContentStyles(): ContentStyle['value'][] {
  return contentStyleMapping.map((s) => s.value);
}

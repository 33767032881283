// utilities/cookieUtils.ts
// Helps with getting and setting cookies

import { v4 as uuidv4 } from 'uuid';

// Retrieves the value of a cookie by name. Returns null if the cookie is not found.
export function getCookie(name: string): string | null {
  // Match the 'name=' pattern, and split the cookie's value
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? decodeURIComponent(match[2]) : null;
}

// Sets a cookie with a given name and value.
export function setCookie(
  name: string,
  value: string,
  days = 3, // By default, expires in 'days' from now (default 3 days).
  path = '/' // Can override if needed
): void {
  const expires = new Date(Date.now() + days * 86400000).toUTCString();
  const encodedValue = encodeURIComponent(value);
  document.cookie = `${name}=${encodedValue}; expires=${expires}; path=${path}`;
}

// Ensures a particular cookie (e.g. "sessionID") exists. If not, sets it to new UUID.
// Returns the final value of that cookie.
export function ensureSessionCookie(cookieName = 'sessionId'): string {
  let sessionId = getCookie(cookieName);
  if (!sessionId) {
    sessionId = uuidv4();
    // You can adjust 'days' or 'path' if needed
    setCookie(cookieName, sessionId, 3, '/');
  }
  return sessionId;
}

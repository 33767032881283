// utilities/generateQr.ts
// Generates a QR code based on a url

import QRCode from 'qrcode';

interface QROptions {
  margin?: number;
  errorCorrectionLevel?: 'L' | 'M' | 'Q' | 'H';
  color?: {
    dark?: string;
    light?: string;
  };
}

export async function generateQrCode(
  url: string,
  options: QROptions = {}
): Promise<string> {
  const defaultOptions: QROptions = {
    errorCorrectionLevel: 'H',
    margin: 1,
    color: {
      dark: '#000000',
      light: '#ffffff',
    },
  };

  return QRCode.toDataURL(url, {
    ...defaultOptions,
    ...options,
  });
}

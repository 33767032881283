// components/Form.tsx

import React, { ReactNode, useState, useEffect } from 'react';
import InputField from './InputField';
import Button from './Button';
import Loader from './Loader';
import ColorSelector from './ColorSelector';
import './Form.css';

interface FormProps {
  placeholder?: string; // Optional placeholder for the input field
  buttonLabel?: string | ReactNode;
  disabledButton?: boolean;
  // onSubmit now returns a Promise so we know when it's done.
  onSubmit: (
    inputValue: string,
    setError: (msg: string | null) => void,
    selectedColor?: string
  ) => Promise<void> | void;
  validationType?: 'number' | 'email' | 'text';
  headerText?: string;
  explainerText?: string;
  qrCode?: string; // Optional prop for the QR code URL or base64 string
  customContent?: ReactNode; // Optional prop for custom content such as Room Code
  className?: string; // Passes special css class to the form (e.g. for the room form)
  transparentButtonLabel?: string;
  onTransparentButtonClick?: () => void;
  includeColorSelector?: boolean; // Optional prop to include the ColorSelector
  prepopulatedValue?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
}

const Form: React.FC<FormProps> = ({
  placeholder,
  buttonLabel,
  disabledButton,
  onSubmit,
  validationType,
  headerText,
  explainerText,
  qrCode,
  customContent,
  className,
  transparentButtonLabel,
  onTransparentButtonClick,
  includeColorSelector = false,
  prepopulatedValue,
  autoCapitalize = 'none',
}) => {
  const [inputValue, setInputValue] = useState<string>(prepopulatedValue || '');
  const [error, setError] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(prepopulatedValue || '');
  }, [prepopulatedValue]);

  // Handle input field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    setInputValue(value);
    if (value === '') {
      setError(null);
    }
  };

  // Validate input based on the provided validationType
  const validateInput = (value: string): string | null => {
    // Skip validation if no placeholder or validationType is provided (not a text input)
    if (!placeholder && !validationType) {
      return null;
    }

    // Common validation for all types to reject empty input
    if (!value.trim()) {
      return 'Input cannot be empty.';
    }

    // Type-specific validation
    if (validationType === 'number' && !/^\d+$/.test(value)) {
      return 'Please enter a valid number.';
    }
    if (
      validationType === 'email' &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ) {
      return 'Please enter a valid email address.';
    }

    // No additional restrictions for other types
    return null;
  };

  const inputType = (validationType?: string) => {
    if (validationType === 'number') return 'tel';
    if (validationType === 'email') return 'email';
    return 'text';
  };

  const inputMode = (validationType?: string) => {
    if (validationType === 'number') return 'numeric';
    if (validationType === 'email') return 'email';
    return 'text';
  };

  // Handle form submission
  const handleSubmit = async (): Promise<void> => {
    // Trim trailing whitespace from inputValue
    const trimmedValue: string = inputValue.trimEnd();
    const errorMessage: string | null = validateInput(trimmedValue);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setError(null);
    setLoading(true); // Start loading before calling onSubmit
    try {
      await onSubmit(
        trimmedValue,
        setError,
        includeColorSelector ? selectedColor : undefined
      );
    } catch (err) {
      console.error(err);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading after onSubmit completes
      setInputValue('');
    }
  };

  // Handle key down events (submit on Enter key)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={`form-container ${className || ''}`}>
      {qrCode && (
        <div className="form-qr-code">
          <img src={qrCode} alt="QR Code" className="form-qr-image" />
        </div>
      )}
      <div className="form-header-explainer">
        {headerText && <div className="form-header">{headerText}</div>}
        {explainerText && <div className="form-explainer">{explainerText}</div>}
      </div>
      {customContent && (
        <div className="form-custom-content">{customContent}</div>
      )}
      {includeColorSelector && ( // Conditionally render ColorSelector
        <ColorSelector onColorSelect={setSelectedColor} />
      )}
      {placeholder && (
        <InputField
          type={inputType(validationType)}
          inputMode={inputMode(validationType)}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          errorMessage={error || undefined}
          disabled={disabledButton || loading}
          autoCapitalize={autoCapitalize}
        />
      )}
      <Button
        onClick={handleSubmit}
        label={loading ? <Loader loading={true} /> : buttonLabel}
        disabledButton={disabledButton || loading}
      />
      {transparentButtonLabel && onTransparentButtonClick && (
        <Button
          onClick={onTransparentButtonClick}
          label={loading ? <Loader loading={true} /> : transparentButtonLabel}
          variant="transparent"
        />
      )}
    </div>
  );
};

export default Form;

// hooks/useAudioVisualization.ts
// Custom hook to manage visualization setup logic

import { useEffect, useRef } from 'react';
import { AudioMotionAnalyzer } from 'audiomotion-analyzer';
import { useAudio } from './useAudio';

interface UseAudioVisualizationProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

export function useAudioVisualization({
  containerRef,
}: UseAudioVisualizationProps) {
  const { webAudio, isMuted } = useAudio();
  const audioMotionRef = useRef<AudioMotionAnalyzer | null>(null);

  // Main effect for setting up the AudioMotionAnalyzer
  useEffect(() => {
    // Ensure we have the required elements
    if (!containerRef.current || !webAudio) {
      console.warn('Missing required elements for visualization:', {
        container: !!containerRef.current,
        webAudio: !!webAudio,
      });
      return;
    }

    // Get required audio components
    const audioContext = webAudio.getContext();
    const analyzerNode = webAudio.getAnalyzer();

    if (!audioContext || !analyzerNode) {
      console.warn('Missing required audio components for visualization:', {
        context: audioContext ? 'available' : 'missing',
        analyzer: analyzerNode ? 'available' : 'missing',
      });
      return;
    }

    try {
      // Clean up any existing instance
      if (audioMotionRef.current) {
        audioMotionRef.current.destroy();
      }

      // Create new AudioMotionAnalyzer instance
      audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
        audioCtx: audioContext,
        source: analyzerNode,
        connectSpeakers: false,
        mode: 7,
        gradientLeft: 'prism',
        gradientRight: 'prism',
        smoothing: 0.5,
        showPeaks: false,
        reflexRatio: 0.5,
        reflexAlpha: 1,
        reflexBright: 1,
        radial: true,
        spinSpeed: 6,
        channelLayout: 'dual-combined',
        mirror: -1,
        showBgColor: false,
        radius: 0.5,
        showScaleX: false,
        showScaleY: false,
        fftSize: 2048,
      });

      // Register gradients for unmuted state
      audioMotionRef.current.registerGradient('unmutedColors', {
        bgColor: '#00000000',
        colorStops: [
          { color: '#e6ff66', pos: 0 },
          { color: '#d0ff00', pos: 0.4 },
          { color: '#68ff18', pos: 0.8 },
          { color: '#00ff2f', pos: 1 },
        ],
      });

      // Register gradients for muted state
      audioMotionRef.current.registerGradient('mutedColors', {
        bgColor: '#00000000',
        colorStops: [
          { color: '#b3b3b3', pos: 0 },
          { color: '#828282', pos: 0.4 },
          { color: '#414141', pos: 0.8 },
          { color: '#ff0050', pos: 1 },
        ],
      });

      // Set initial gradient based on mute state
      audioMotionRef.current.setOptions({
        gradientLeft: isMuted ? 'mutedColors' : 'unmutedColors',
        gradientRight: isMuted ? 'mutedColors' : 'unmutedColors',
      });

      console.log('AudioMotionAnalyzer setup complete');
    } catch (err) {
      console.error('AudioMotionAnalyzer setup failed:', err);
    }

    // Cleanup function
    return () => {
      if (audioMotionRef.current) {
        audioMotionRef.current.destroy();
        audioMotionRef.current = null;
      }
    };
  }, [containerRef, webAudio, isMuted]);

  // Effect to update visualization when mute state changes
  useEffect(() => {
    if (audioMotionRef.current) {
      console.log(`Updating visualization colors for mute state: ${isMuted}`);
      audioMotionRef.current.setOptions({
        gradientLeft: isMuted ? 'mutedColors' : 'unmutedColors',
        gradientRight: isMuted ? 'mutedColors' : 'unmutedColors',
      });
    }
  }, [isMuted]);

  return audioMotionRef;
}

// configs/routes.ts
// Setting page titles and normalizing paths for analytics tracking

type Route = {
  path: string;
  normalizedPath: string;
  pageTitle: string;
  extractRoomCode?: (pathname: string) => string;
};

export const routes: Route[] = [
  { path: '/', normalizedPath: '/', pageTitle: 'Home' },
  { path: '/about', normalizedPath: '/about', pageTitle: 'About' },
  { path: '/terms', normalizedPath: '/terms', pageTitle: 'Terms' },
  { path: '/subscribe', normalizedPath: '/subscribe', pageTitle: 'Subscribe' },
  { path: '/email', normalizedPath: '/email', pageTitle: 'Email' },
  {
    path: '/email-verification',
    normalizedPath: '/verify',
    pageTitle: 'Verify Email',
  },
  {
    path: '/game-library',
    normalizedPath: '/game-library',
    pageTitle: 'Game Library',
  },
  {
    path: '/room/:roomId',
    normalizedPath: '/room',
    pageTitle: 'Room',
    extractRoomCode: (pathname: string) => pathname.split('/')[2],
  },
  {
    path: '/room/:roomId/player',
    normalizedPath: '/player',
    pageTitle: 'Room-Player',
    extractRoomCode: (pathname: string) => pathname.split('/')[2],
  },
  {
    path: '/game/:roomId',
    normalizedPath: '/game',
    pageTitle: 'Game',
    extractRoomCode: (pathname: string) => pathname.split('/')[2],
  },
  {
    path: '/game/:roomId/playing',
    normalizedPath: '/playing',
    pageTitle: 'Game-Playing',
    extractRoomCode: (pathname: string) => pathname.split('/')[2],
  },
];

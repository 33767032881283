// hooks/useAudioMusicManager.ts
// Manages background music tracks

// SAMPLE IMPLMENTATION
// SAMPLE IMPLMENTATION
// SAMPLE IMPLMENTATION
// SAMPLE IMPLMENTATION

import { useRef, useState, useCallback, RefObject } from 'react';
import { getAsset } from '../services/assetSerivces';
import { getSoundEffectPath } from '../utilities/mapSharedAssets';

export function useAudioMusicManager(audioRef: RefObject<HTMLAudioElement>) {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState<string | null>(null);

  // Cache for music tracks to avoid re-fetching
  const cachedMusicTracks = useRef<Record<string, string>>({});

  // Load a music track and return its URL
  const loadMusicTrack = useCallback(
    async (trackKey: string): Promise<string> => {
      // Return cached version if available
      if (cachedMusicTracks.current[trackKey]) {
        return cachedMusicTracks.current[trackKey];
      }

      try {
        const trackPath = getSoundEffectPath(trackKey);
        const trackUrl = await getAsset(trackPath);

        // Cache the track URL
        cachedMusicTracks.current[trackKey] = trackUrl;
        return trackUrl;
      } catch (error) {
        console.error(`[ERROR] Failed to load music track: ${trackKey}`, error);
        throw error;
      }
    },
    []
  );

  // Play a music track
  const playMusicTrack = useCallback(
    async (trackKey: string): Promise<void> => {
      if (!audioRef.current) {
        console.error('No audio element found for music playback');
        return;
      }

      try {
        // Stop current playback
        if (isMusicPlaying) {
          audioRef.current.pause();
        }

        const trackUrl = await loadMusicTrack(trackKey);

        // Set up the audio element
        audioRef.current.src = trackUrl;
        audioRef.current.loop = true;
        audioRef.current.currentTime = 0;

        // Play the track
        await audioRef.current.play();

        setCurrentTrack(trackKey);
        setIsMusicPlaying(true);
      } catch (error) {
        console.error(`[ERROR] Failed to play music track: ${trackKey}`, error);
        setIsMusicPlaying(false);
        setCurrentTrack(null);
      }
    },
    [audioRef, isMusicPlaying, loadMusicTrack]
  );

  // Stop music playback
  const stopMusicPlayback = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    setIsMusicPlaying(false);
    // Keep the current track reference to allow for resuming
  }, [audioRef]);

  // Resume current track playback
  const resumeMusicPlayback = useCallback(async () => {
    if (!audioRef.current || !currentTrack) {
      return;
    }

    try {
      await audioRef.current.play();
      setIsMusicPlaying(true);
    } catch (error) {
      console.error('[ERROR] Failed to resume music playback', error);
    }
  }, [audioRef, currentTrack]);

  return {
    isMusicPlaying,
    currentTrack,
    playMusicTrack,
    stopMusicPlayback,
    resumeMusicPlayback,
  };
}

// hooks/useUserLogout.ts
// Handles logging a user out

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../services/userServices';
import { useUserActions } from '../stores/useUserStore';

// Custom hook for logging out the user.
export function useUserLogout() {
  const { setUser } = useUserActions();
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [errorLoggingOut, setErrorLoggingOut] = useState<string | null>(null);
  const navigate = useNavigate();

  const logout = useCallback(async (): Promise<void> => {
    setIsLoggingOut(true);
    setErrorLoggingOut(null);

    try {
      await logoutUser();
      setUser(null);
      navigate('/'); // Navigate home
    } catch (err: unknown) {
      console.error('Failed to logout user:', err);
      setErrorLoggingOut('Failed to logout.');
    } finally {
      setIsLoggingOut(false);
    }
  }, [setUser, navigate]);

  return {
    logout,
    isLoggingOut,
    errorLoggingOut,
  };
}

// frontend/services/assetServices.ts
// Handles all API interactions related to retrieving assets.

import axiosInstance from '../utilities/axiosConfig';
import { AssetApiResponse } from '@shared/types';

// Fetches a signed URL for an asset from the backend.
export async function getAsset(assetName: string): Promise<string> {
  try {
    const response = await axiosInstance.get<AssetApiResponse>('/api/asset', {
      params: { assetName },
    });

    if (!response.data.success || !response.data.data.url) {
      throw new Error(response.data.message || 'Invalid response format');
    }

    return response.data.data.url;
  } catch (error) {
    // Check if the error object has a 'response' property (indicating an HTTP error)
    if (typeof error === 'object' && error !== null && 'response' in error) {
      const responseError = error as {
        response: { status: number; data?: { error?: string } };
      };

      console.error(
        `Failed to fetch asset (${assetName}):`,
        `Status: ${responseError.response.status}, Message: ${responseError.response.data?.error || 'Unknown server error'}`
      );

      throw new Error(
        `Failed to fetch asset (${assetName}): ${responseError.response.data?.error || 'Unexpected error'}`
      );
    }

    // Handle unexpected errors (e.g., network failure, timeouts, etc.)
    console.error(
      `Unexpected error while fetching asset (${assetName}):`,
      error
    );

    throw new Error(`Could not load asset: ${assetName}`);
  }
}

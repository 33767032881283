// services/playerServices.ts
// Handles all API interactions related to players

import axiosInstance from '../utilities/axiosConfig';
import {
  Player,
  CreatePlayerData,
  UpdatePlayerData,
  ApiResponse,
} from '@shared/types';
import { handleApiResponse } from './axiosWrapper';

// Creates a new player.
export async function createPlayer(
  roomCode: string,
  data: CreatePlayerData
): Promise<Player> {
  return handleApiResponse<Player>(
    axiosInstance.post<ApiResponse<Player>>(
      `/api/room/${roomCode}/player`,
      data
    )
  );
}

// Updates an existing player.
export async function updatePlayer(
  roomCode: string,
  playerId: string,
  data: UpdatePlayerData
): Promise<Player> {
  return handleApiResponse<Player>(
    axiosInstance.patch<ApiResponse<Player>>(
      `/api/room/${roomCode}/player/${playerId}`,
      data
    )
  );
}

// Fetches player details by session Id.
export async function getPlayerBySession(sessionId: string): Promise<Player> {
  return handleApiResponse<Player>(
    axiosInstance.get<ApiResponse<Player>>(`/api/session/${sessionId}`)
  );
}

// Fetches a player by ID.
export async function getPlayerById(playerId: string): Promise<Player> {
  return handleApiResponse<Player>(
    axiosInstance.get<ApiResponse<Player>>(`/api/player/${playerId}`)
  );
}

// hooks/useGameLibraryFetch.ts
// Handles retrieval from backend and setting game store

import { useCallback, useState } from 'react';
import { useGameActions } from '../stores/useGameStore';
import { getGameLibrary } from '../services/gameServices';

export interface UseGameFetchReturn {
  isFetchingGames: boolean;
  errorFetchingGames: string | null;
  fetchGames: () => Promise<void>;
}

export function useGameFetch(): UseGameFetchReturn {
  const { setGames, setGamesActiveCount } = useGameActions();

  // Internal states for loading and error
  const [isFetchingGames, setIsFetchingGames] = useState<boolean>(false);
  const [errorFetchingGames, setErrorFetchingGames] = useState<string | null>(
    null
  );

  // Fetches latest games from library and stores in context
  const fetchGames = useCallback(async (): Promise<void> => {
    console.log('Fetching game library');
    setIsFetchingGames(true);
    setErrorFetchingGames(null);

    try {
      const data = await getGameLibrary();
      console.log('Got game library:', data);
      setGames(data);
      setGamesActiveCount(data.filter((g) => g.isActive).length);
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : 'Failed to fetch game library.';
      console.error('Failed to fetch game library:', err);
      setErrorFetchingGames(errorMessage);
    } finally {
      setIsFetchingGames(false);
    }
  }, [setGames, setGamesActiveCount]);

  return {
    isFetchingGames,
    errorFetchingGames,
    fetchGames,
  };
}

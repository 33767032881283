// frontend/services/aiGenServices.ts
// Handles all API interactions related to AI generation.

import axiosInstance from '../utilities/axiosConfig';
import {
  GenerateChoicesRequest,
  GenerateChoicesResponse,
  GenerateCommentaryRequest,
  GenerateCommentaryResponse,
  GenerateImageRequest,
  GenerateImageResponse,
  GenerateRemixRequest,
  GenerateRemixResponse,
} from '@shared/types';

// Generates multiple "choices" from the backend API (e.g., prompts, awards).
// Endpoint: provided at runtime (e.g., '/api/generate-prompts', '/api/generate-awards').
export async function generateChoices(
  endpoint: string,
  requestData: GenerateChoicesRequest
): Promise<string[]> {
  const response = await axiosInstance.post<GenerateChoicesResponse>(
    endpoint,
    requestData,
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      timeout: 15000,
    }
  );

  if (
    !response.data?.generatedTexts ||
    !Array.isArray(response.data.generatedTexts) ||
    response.data.generatedTexts.length === 0
  ) {
    throw new Error('No choices received from the server.');
  }

  return response.data.generatedTexts.map((p) => p.inputText);
}

// Generates host commentary from the backend API.
export async function generateHostCommentary(
  requestData: GenerateCommentaryRequest
): Promise<string> {
  const response = await axiosInstance.post<GenerateCommentaryResponse>(
    '/api/generate-commentary',
    requestData,
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      timeout: 15000,
    }
  );

  // Optional artificial delay
  await new Promise((resolve) => setTimeout(resolve, 2000));

  if (
    !response.data?.generatedTexts ||
    !Array.isArray(response.data.generatedTexts) ||
    response.data.generatedTexts.length === 0
  ) {
    throw new Error(
      'Invalid response format: Commentary array is missing or empty'
    );
  }

  const hostCommentaryText: string =
    response.data.generatedTexts[0]?.inputText || '';
  if (!hostCommentaryText) {
    throw new Error('No commentary text found in the response');
  }

  return hostCommentaryText;
}

// Generates an image from the backend API.
export async function generateImage(
  payload: GenerateImageRequest
): Promise<GenerateImageResponse> {
  try {
    const response = await axiosInstance.post<GenerateImageResponse>(
      '/api/generate-image',
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        timeout: 40000, // 3 retries 5, 10, 15s = 35s (+buffer)
      }
    );
    return {
      message: response.data.message || 'Image generated successfully',
      imageUrl: response.data.imageUrl,
    };
  } catch (error: unknown) {
    if (typeof error === 'object' && error !== null && 'response' in error) {
      const axiosError = error as { response: { data: { error?: string } } };
      console.error(
        'Failed to generate image:',
        axiosError.response.data?.error
      );
      throw new Error(
        axiosError.response.data?.error ||
          'An error occurred while generating the image.'
      );
    }
    console.error('Unexpected error in generateImage:', error);
    throw new Error('An unknown error occurred in generateImage service.');
  }
}

// Generic function to call "remix" endpoints for text transformations.
// e.g., /api/trophy-item or future remix endpoints.
export async function generateRemix(
  endpoint: string,
  payload: GenerateRemixRequest
): Promise<string[]> {
  const response = await axiosInstance.post<GenerateRemixResponse>(
    endpoint,
    payload
  );

  if (
    !response.data?.generatedTexts ||
    !Array.isArray(response.data.generatedTexts) ||
    response.data.generatedTexts.length === 0
  ) {
    throw new Error('No remix data received from the server.');
  }

  return response.data.generatedTexts.map((obj) => obj.inputText);
}

// frontend/hooks/useAudioSfxManager.ts
// Responsible for fetching, caching, and playing sound effects using the SfxAudio service.

import { useRef, useState, useCallback, useEffect, RefObject } from 'react';
import { getAsset } from '../services/assetSerivces';
import { getSoundEffectPath } from '../utilities/mapSharedAssets';
import { SoundEffectKey } from '../types';
import { WebAudioService } from '../services/audio/webAudioService';

// Keep track of dynamically created audio elements
interface AudioNode {
  element: HTMLAudioElement;
  source: MediaElementAudioSourceNode | null;
  inUse: boolean;
}

export function useAudioSfxManager(
  sfxAudioRef: RefObject<HTMLAudioElement>,
  webAudio?: WebAudioService
) {
  const [isSfxPlaying, setIsSfxPlaying] = useState(false);

  // Cache for preloaded sound effects URLs
  const cachedSoundUrls = useRef<Record<string, string>>({});

  // Pool for dynamically created audio elements
  const audioPool = useRef<AudioNode[]>([]);

  // Counter for active audio nodes
  const activeNodesCount = useRef(0);

  // Loads and caches a sound effect URL if not already cached
  const loadSoundEffect = useCallback(
    async (soundKey: SoundEffectKey): Promise<HTMLAudioElement> => {
      try {
        // First check if we already have the URL cached
        let soundUrl = cachedSoundUrls.current[soundKey];

        // If not, load and cache it
        if (!soundUrl) {
          const soundPath = getSoundEffectPath(soundKey);
          soundUrl = await getAsset(soundPath);
          cachedSoundUrls.current[soundKey] = soundUrl;
        }

        // Create and configure audio element
        if (sfxAudioRef.current) {
          // Return the main audio element (compatible with original API)
          sfxAudioRef.current.src = soundUrl;
          sfxAudioRef.current.load();
          return sfxAudioRef.current;
        } else {
          throw new Error('No audio element reference available');
        }
      } catch (error) {
        console.error(`Failed to load sound effect: ${soundKey}`, error);
        throw error;
      }
    },
    [sfxAudioRef]
  );

  // Creates a new audio node or reuses an available one from the pool
  const getAudioNode = useCallback((): AudioNode => {
    // First check if any existing nodes are free
    const availableNode = audioPool.current.find((node) => !node.inUse);

    if (availableNode) {
      availableNode.inUse = true;
      return availableNode;
    }

    // If no free nodes, create a new one
    const audioElement = document.createElement('audio');
    audioElement.crossOrigin = 'anonymous';

    // Connect to Web Audio if available
    let source: MediaElementAudioSourceNode | null = null;
    if (webAudio) {
      source = webAudio.connectSource(audioElement);
    }

    // Create new node
    const newNode: AudioNode = {
      element: audioElement,
      source,
      inUse: true,
    };

    // Add to pool
    audioPool.current.push(newNode);

    return newNode;
  }, [webAudio]);

  // Release an audio node back to the pool
  const releaseAudioNode = useCallback((node: AudioNode) => {
    // Reset the audio element
    node.element.pause();
    node.element.currentTime = 0;

    // Mark as available
    node.inUse = false;

    // Update active count
    activeNodesCount.current--;

    // Update playing state if no active nodes
    if (activeNodesCount.current === 0) {
      setIsSfxPlaying(false);
    }
  }, []);

  // Play sound effect with concurrent support
  const playSoundEffect = useCallback(
    async (soundKey: SoundEffectKey): Promise<void> => {
      try {
        // Get the sound URL
        const soundPath = getSoundEffectPath(soundKey);
        const soundUrl =
          cachedSoundUrls.current[soundKey] || (await getAsset(soundPath));

        // Cache the URL if needed
        if (!cachedSoundUrls.current[soundKey]) {
          cachedSoundUrls.current[soundKey] = soundUrl;
        }

        // Get an audio node
        const audioNode = getAudioNode();
        audioNode.element.src = soundUrl;
        audioNode.element.currentTime = 0;

        // Set SFX volume
        audioNode.element.volume = 0.5;

        // Update playing state
        setIsSfxPlaying(true);
        activeNodesCount.current++;

        try {
          // Play the audio
          await audioNode.element.play();

          // Set up cleanup when playback ends
          audioNode.element.onended = () => {
            audioNode.element.onended = null;
            releaseAudioNode(audioNode);
          };
        } catch (error) {
          console.error(`Playback error for ${soundKey}:`, error);
          releaseAudioNode(audioNode);
        }
      } catch (error) {
        console.error(`Failed to play sound effect: ${soundKey}`, error);
      }
    },
    [getAudioNode, releaseAudioNode]
  );

  // Stop all sound effects
  const stopSfxPlayback = useCallback(() => {
    // Stop all pool audio
    audioPool.current.forEach((node) => {
      if (node.inUse) {
        node.element.pause();
        node.element.currentTime = 0;
        node.inUse = false;
      }
    });

    // Also stop the main audio element
    if (sfxAudioRef.current) {
      sfxAudioRef.current.pause();
      sfxAudioRef.current.currentTime = 0;
    }

    activeNodesCount.current = 0;
    setIsSfxPlaying(false);
  }, [sfxAudioRef]);

  // Clean up all audio resources when the component unmounts
  useEffect(() => {
    return () => {
      // Disconnect all audio nodes from Web Audio
      audioPool.current.forEach((node) => {
        if (node.source && webAudio) {
          webAudio.disconnectSource(node.element);
        }
        node.element.pause();
        node.element.src = '';
      });
      audioPool.current = [];
    };
  }, [webAudio]);

  return {
    isSfxPlaying,
    loadSoundEffect,
    playSoundEffect,
    stopSfxPlayback,
  };
}

// hooks/useAudio.ts
// Custom hook to interact with Audio context

import { useContext } from 'react';
import { AudioContext } from '../contexts/AudioContext';

// Custom hook to access the AudioContext.
export const useAudio = () => {
  const context = useContext(AudioContext);

  if (context === undefined) {
    throw new Error('useAudio must be used within an AudioProvider');
  }

  return context;
};

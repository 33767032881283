// pages/Room.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate// Import SpeechContext
import PlayerContainer from '../components/PlayerContainer';
import Form from '../components/Form';
import AudioControl from '../components/AudioControl';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { useRoomStore, useRoomActions } from '../stores/useRoomStore';
import { useGameSelected } from '../stores/useGameStore';
import { useWebSocketEmitters } from '../hooks/useWebSocketEmitters';
import { useAudio } from '../hooks/useAudio';
import { useRoomGoToGame } from '../hooks/useRoomGoToGame';
import { useAiGenCommentary } from '../hooks/useAiGenCommentary';
import { startGame } from '../services/gameServices';
import { generateQrCode } from '../utilities/generateQr';
import { getUrlFrontend } from '../utilities/getUrlFrontend';
import './Room.css';
import '../components/Form.css';

const Room: React.FC = () => {
  const navigate = useNavigate();
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const { clearRoom } = useRoomActions();
  const gameSelected = useGameSelected();
  const goToGame = useRoomGoToGame();
  const { queueSpeechForPlayback, waitUntilSpeechComplete } = useAudio();
  const { fetchCommentary } = useAiGenCommentary();
  const { sendRoomUpdate } = useWebSocketEmitters();
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const [gameStarting, setGameStarting] = useState(false); // In this case we don't rely on the form button loading because we need the state to persist for longer than just form submission

  // FYI: player announcements are coming from useWebSocketListenersPlayer.ts

  // Get the QR code for the room URL
  useEffect(() => {
    const getQrCode = async () => {
      if (roomDetails?.roomCode) {
        try {
          // Make sure to use the full URL including origin for the QR code
          const baseUrl = getUrlFrontend();
          const url = `${baseUrl}/room/${roomDetails.roomCode}/player`;
          const qrData = await generateQrCode(url);
          setQrCode(qrData);
        } catch (err) {
          console.error('Failed to generate QR code:', err);
        }
      }
    };

    getQrCode();
  }, [roomDetails?.roomCode]);

  const handleStartGame = async () => {
    // Early return if no roomCode
    if (!roomDetails?.roomCode) {
      return;
    }

    setGameStarting(true);
    const gameNotes = `All players have joined the room for ${gameSelected?.name}, the game where ${gameSelected?.description}. Introduce yourself as the host, Poppy, and announce the game is starting. Please keep your comments brief and limited to 1 or 2 sentences at the most.`;

    try {
      // Create & play commentary
      if (gameNotes) {
        const commentary = await fetchCommentary(gameNotes);
        if (commentary) {
          queueSpeechForPlayback(commentary);

          // Wait for all speech to complete using our dedicated method
          await waitUntilSpeechComplete();
        }
      }

      // Start game and navigate
      await startGame(roomDetails.roomCode);
      await sendRoomUpdate(roomDetails.roomCode);
      navigate(`/game/${roomDetails.roomCode}`);
    } catch (error) {
      console.error('Error starting game:', error);
    } finally {
      setGameStarting(false);
    }
  };

  const handleBackToLibrary = () => {
    clearRoom();
    navigate('/game-library');
  };

  // Determine the button label based on gameActive and gamePlayersMin
  const notEnoughPlayers =
    (roomDetails?.roomPlayers?.length || 0) <
    (gameSelected?.gamePlayersMin ?? 1);

  console.log(
    'min:',
    gameSelected?.gamePlayersMin,
    'room:',
    roomDetails?.roomPlayers?.length
  );

  const buttonLabel = roomDetails?.gameActive
    ? 'Game in Progress'
    : notEnoughPlayers
      ? 'Waiting for Players'
      : 'Start Game';

  const isButtonDisabled =
    roomDetails?.gameActive || notEnoughPlayers || gameStarting;

  return (
    <div className="room-container">
      <header>
        <h1 className="room-game-headline">
          {roomDetails?.game.name || 'Unknown Game'}
        </h1>
        <div className="room-go-back">
          {!gameStarting && (
            <Link
              to="/game-library"
              onClick={(e) => {
                e.preventDefault();
                handleBackToLibrary();
              }}
            >
              ← Back to Game Library
            </Link>
          )}
        </div>
      </header>

      <main className="room-layout">
        <div className="room-players-container">
          {/* Render PlayerContainer with fetched player details */}
          {
            <PlayerContainer
              players={roomDetails?.roomPlayers || []}
              layoutType="grid"
              avatarSize={160}
              avatarPreset={'standard-floating'}
            />
          }
        </div>
        <div className="room-form-container">
          {/* Render Form with QR code and game start button */}
          <Form
            className="form-container--room"
            buttonLabel={gameStarting ? <Loader loading={true} /> : buttonLabel}
            onSubmit={handleStartGame}
            headerText="SCAN TO JOIN"
            qrCode={qrCode}
            customContent={
              <div>
                or use code{' '}
                <span style={{ color: '#d0ff00', fontWeight: 'bold' }}>
                  {roomDetails?.roomCode}
                </span>{' '}
                at <br />
                airheadgames.com
              </div>
            }
            disabledButton={isButtonDisabled}
          />
          {roomDetails?.gameActive && (
            <div className="button-wrapper-go-game">
              <Button
                onClick={goToGame}
                label="Go To Game"
                trackingLabel="Go To Game"
                variant="transparent"
              />
            </div>
          )}
        </div>
        <AudioControl />
      </main>
    </div>
  );
};

export default Room;

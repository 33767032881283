// components/AudioControl.tsx
// Component for UI of audio toggle and visualization

import { useRef, useCallback } from 'react';
import { useAudio } from '../hooks/useAudio';
import { useAudioVisualization } from '../hooks/useAudioVisualization';
import MutedIcon from './icons/Muted';
import UnmutedIcon from './icons/Unmuted';
import './AudioControl.css';

export const AudioControl = () => {
  // Create a ref for the visualization container
  const analyzerContainerRef = useRef<HTMLDivElement>(null);

  // Get audio state and controls directly from context
  const { isMuted, toggleMute, resumeAudioContext } = useAudio();

  // Set up visualization
  useAudioVisualization({
    containerRef: analyzerContainerRef,
  });

  // Handle audio control click with context resumption
  const handleAudioControlClick = useCallback(async () => {
    // Resume audio context (will only run if context is suspended)
    await resumeAudioContext();

    // Toggle mute state
    toggleMute();
  }, [resumeAudioContext, toggleMute]);

  return (
    <div className="audio-display">
      <div className="audio-analyzer" ref={analyzerContainerRef} />
      <div className="audio-control">
        {isMuted ? (
          <MutedIcon onClick={handleAudioControlClick} color="#FFFFFF" />
        ) : (
          <UnmutedIcon onClick={handleAudioControlClick} color="#FFFFFF" />
        )}
      </div>
    </div>
  );
};

export default AudioControl;

// components/onboarding/Step02HostCustomization.tsx

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUser } from '../../stores/useUserStore';
import { useUserSave } from '../../hooks/useUserSave';
import { useAudio } from '../../hooks/useAudio';
import { generateHostCommentary } from 'src/services/aiGenServices';
import { getLanguageAccents, hostStyleMapping } from '@shared/utilities';
import Accordion from '../Accordion';
import Form from '../Form';
import AudioControl from '../AudioControl';
import Loader from '../Loader';
import './Step02HostCustomization.css';

interface OnboardingProps {
  nextStep: () => void;
}

const HostCustomization: React.FC<OnboardingProps> = ({ nextStep }) => {
  const user = useUser();
  const { saveUser } = useUserSave();
  const {
    queueSpeechForPlayback,
    isSpeaking,
    isMuted,
    toggleMute,
    stopSpeechPlayback,
  } = useAudio();
  const selectedLanguage = user?.userSettings?.language;

  const [previewCommentary, setPreviewCommentary] = useState<string>('');
  const [isGeneratingCommentary, setIsGeneratingCommentary] =
    useState<boolean>(false);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const latestRequestIdRef = useRef(0);

  // Track selections for all accordion sections
  const [selections, setSelections] = useState<Record<string, string>>({
    accents: user?.userSettings?.hostAccent || '',
    hostGender: user?.userSettings?.hostGender || 'male',
    hostStyle: user?.userSettings?.hostStyle || '',
  });

  // Update selection state on changes
  useEffect(() => {
    if (user?.userSettings) {
      setSelections({
        accents: user.userSettings.hostAccent || '',
        hostGender: user.userSettings.hostGender || 'male',
        hostStyle: user.userSettings.hostStyle || '',
      });
    }
  }, [user?.userSettings]);

  // Add useEffect to communicate isSpeaking to parent/store if needed
  useEffect(() => {
    // This will be used later if we need to communicate with the progress bar
    if (typeof window !== 'undefined') {
      window.dispatchEvent(
        new CustomEvent('audio-speaking-change', {
          detail: { isSpeaking },
        })
      );
    }
  }, [isSpeaking]);

  // Wrap the function in useCallback to memoize it
  const generateAndSetCommentary = useCallback(async (): Promise<
    string | null
  > => {
    try {
      if (
        !user?.userSettings?.language ||
        !user?.userSettings?.hostAccent ||
        !user?.userSettings?.hostStyle ||
        !user?.userSettings?.hostGender ||
        !user?.userSettings?.contentStyle
      ) {
        console.warn('Cannot generate commentary: userSettings undefined');
        return null;
      }

      const gameNotes = `We're not playing a game right now, the player just wants to listen to your voice. So introduce yourself as Poppy, the game show host for Airhead Games, and tell the person you are looking forward to playing games with them. Make sure your unique personality style is on display, but keep your comments brief with 2 sentences at the maximum.`;

      // Call the service directly with the current user settings
      const commentaryText = await generateHostCommentary({
        roomCode: 'preview-mode',
        language: user?.userSettings?.language,
        hostAccent: user?.userSettings?.hostAccent,
        hostStyle: user?.userSettings?.hostStyle,
        contentStyle: user?.userSettings?.contentStyle,
        gameNotes,
      });

      setPreviewCommentary(commentaryText);

      return commentaryText;
    } catch (error) {
      console.error('Commentary generation failed:', error);
      return null;
    }
  }, [
    user?.userSettings?.language,
    user?.userSettings?.hostAccent,
    user?.userSettings?.hostStyle,
    user?.userSettings?.hostGender,
    user?.userSettings?.contentStyle,
  ]);

  // Handle settings changes with debounce
  useEffect(() => {
    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    setIsGeneratingCommentary(true);

    // Start a new timer
    debounceTimerRef.current = setTimeout(async () => {
      // Will capture the current latestRequestIdRef value when it runs
      const currentRequestId = ++latestRequestIdRef.current;

      try {
        await generateAndSetCommentary();
      } finally {
        // Only update loading state if this is still the latest request
        if (latestRequestIdRef.current === currentRequestId) {
          setIsGeneratingCommentary(false);
        }
      }
    }, 500);

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [generateAndSetCommentary]);

  // Handle the preview button click
  const handlePreviewClick = useCallback(async () => {
    stopSpeechPlayback();

    try {
      if (isMuted) {
        toggleMute();
      }

      if (previewCommentary) {
        await queueSpeechForPlayback(previewCommentary);
        console.log('Preview clicked with commentary:', previewCommentary);
      } else {
        console.warn('No text available for speech playback');
      }
    } catch (error) {
      console.error('Preview speech failed:', error);
    }
  }, [
    previewCommentary,
    isMuted,
    toggleMute,
    queueSpeechForPlayback,
    stopSpeechPlayback,
  ]);

  if (!selectedLanguage) {
    return <div>Please select a language first.</div>;
  }

  const allAccents = getLanguageAccents(selectedLanguage);

  if (!allAccents || allAccents.length === 0) {
    return <div>No accents available for this language.</div>;
  }

  // Format accents as accordion options
  const accentOptions = allAccents.map((accent) => ({
    id: accent.value,
    label: accent.label,
    description: accent.disabled ? 'Currently unavailable' : undefined,
  }));

  // Format host styles as accordion options
  const hostStyleOptions = hostStyleMapping.map((style) => ({
    id: style.value,
    label: style.label,
    description: style.disabled
      ? 'Currently unavailable'
      : style.labelDescription,
  }));

  // Host gender options
  const hostGenderOptions = [
    {
      id: 'male',
      label: 'Male',
    },
    {
      id: 'female',
      label: 'Female',
    },
  ];

  // Create accordion sections
  const accordionSections = [
    // Only include the accent section if there's more than one accent
    ...(accentOptions.length > 1
      ? [
          {
            id: 'accents',
            title: 'Accent',
            options: accentOptions,
          },
        ]
      : []),
    {
      id: 'hostGender',
      title: 'Gender',
      options: hostGenderOptions,
    },
    {
      id: 'hostStyle',
      title: 'Style',
      options: hostStyleOptions,
    },
  ];

  // Handle option selection
  const handleSelect = async (sectionId: string, optionId: string) => {
    // Update local state
    setSelections((prev) => ({
      ...prev,
      [sectionId]: optionId,
    }));

    try {
      if (sectionId === 'accents') {
        await saveUser({
          operations: [
            {
              operator: '$set',
              path: 'userSettings.hostAccent',
              value: optionId,
            },
          ],
        });
        console.log(`Updated accent to: ${optionId}`);
      } else if (sectionId === 'hostStyle') {
        await saveUser({
          operations: [
            {
              operator: '$set',
              path: 'userSettings.hostStyle',
              value: optionId,
            },
          ],
        });
        console.log(`Updated host style to: ${optionId}`);
      } else if (sectionId === 'hostGender') {
        await saveUser({
          operations: [
            {
              operator: '$set',
              path: 'userSettings.hostGender',
              value: optionId,
            },
          ],
        });
        console.log(`Updated host gender to: ${optionId}`);
      }
    } catch (error) {
      console.error(`Failed to update ${sectionId} setting:`, error);
    }
  };

  return (
    <div className="onboard-customize-host">
      <div className="form-header-explainer">
        <div className="form-header">Personalize Poppy</div>
        <div className="form-explainer">
          Customize your host of Airhead Games
        </div>
      </div>
      <Accordion
        sections={accordionSections}
        selections={selections}
        onSelect={handleSelect}
        defaultOpenSection={null}
      />
      {isGeneratingCommentary ? (
        <div className="preview-voice-form-container">
          <Form
            buttonLabel="Continue"
            onSubmit={async () => {
              nextStep();
              return Promise.resolve();
            }}
          />
          <div className="loader-container">
            <Loader loading={true} color="#FFFFFF" />
          </div>
        </div>
      ) : (
        <div className="preview-voice-form-container">
          <Form
            buttonLabel="Continue"
            onSubmit={async () => {
              nextStep();
              return Promise.resolve();
            }}
            transparentButtonLabel="▶ Preview"
            onTransparentButtonClick={handlePreviewClick}
          />
        </div>
      )}
      {
        <div
          className={`positioned-audio-wrapper ${isSpeaking ? 'is-speaking' : ''}`}
        >
          <AudioControl />
        </div>
      }
    </div>
  );
};

export default HostCustomization;

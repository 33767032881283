// hooks/useRoomGoToGame.ts
// Handles room navigation if game already started

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoomStore } from '../stores/useRoomStore';
import { isPlayingRoute } from '../utilities/isRoute';

export function useRoomGoToGame() {
  const navigate = useNavigate();
  const roomDetails = useRoomStore((state) => state.roomDetails);

  const goToGame = useCallback(() => {
    if (roomDetails?.roomCode) {
      isPlayingRoute()
        ? navigate(`/game/${roomDetails.roomCode}/playing`)
        : navigate(`/game/${roomDetails.roomCode}`);
    }
  }, [roomDetails?.roomCode, navigate]);

  return goToGame;
}

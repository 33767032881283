// pages/About.tsx

import React, { useEffect, useState } from 'react';
import { getEnabledLanguageAndAccentCounts } from '@shared/utilities';
import { useGames, useGamesActiveCount } from '../stores/useGameStore';
import { useGameFetch } from '../hooks/useGameLibraryFetch';
import { useSystemWindowSize } from 'src/hooks/useSystemWindowSize';
import HamburgerMenu from '../components/HamburgerMenu';
import Wordmark from '../components/Wordmark';
import Button from '../components/Button';
import Avatar from 'src/components/Avatar';
import './About.css';

const About: React.FC = () => {
  const { languagesCount, accentsCount } = getEnabledLanguageAndAccentCounts();
  const games = useGames();
  const gamesActiveCount = useGamesActiveCount();
  const { fetchGames, isFetchingGames, errorFetchingGames } = useGameFetch();
  const [activeSection, setActiveSection] = useState<string>('why');
  const { width } = useSystemWindowSize();

  // Fetch games if we don't have an active count yet
  useEffect(() => {
    if (
      games.length === 0 ||
      gamesActiveCount === 0 ||
      errorFetchingGames !== null
    ) {
      fetchGames();
    }
  }, [games, gamesActiveCount, fetchGames, errorFetchingGames]);

  const displayedGameCount = isFetchingGames ? 0 : gamesActiveCount;

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
    // Update URL hash without full page reload
    window.history.pushState(null, '', `#${section}`);
  };

  return (
    <div className="about-page">
      <Wordmark />
      <HamburgerMenu />
      <div className="about-content-wrapper">
        <div className="poppy-intro-wrapper">
          <div className="poppy-intro-avatar">
            <Avatar
              size={width < 768 ? 120 : 160}
              color={'#ffffff'}
              preset="standard-floating"
              visibleLayers={{ border: true }}
            />
          </div>
          <div className="poppy-intro-text">
            <div className="poppy-intro-header">Hey there, I'm Poppy</div>
            <div className="poppy-intro-subheader">
              Your AI party game host with all the details on Airhead Games
            </div>
          </div>
        </div>

        <div className="about-nav">
          {['why', 'how', 'what', 'who', 'where', 'contact'].map((section) => (
            <div
              key={section}
              className="nav-button"
              onClick={() => handleSectionClick(section)}
            >
              <Button
                label={
                  section === 'why'
                    ? 'The Big Why'
                    : section === 'how'
                      ? 'How It Works'
                      : section === 'what'
                        ? 'Game Stuff'
                        : section === 'who'
                          ? 'Real Talk'
                          : section === 'where'
                            ? 'Find Me'
                            : 'Say Hi'
                }
                onClick={() => {}}
                variant={activeSection === section ? 'success' : 'transparent'}
              />
            </div>
          ))}
        </div>

        <div className="section-contents-wrapper">
          <div
            className={`section-content ${activeSection === 'why' ? 'active' : ''}`}
          >
            <p>
              Humans are at their best when they're laughing together. And in
              this whole AI revolution thing, I'm here to bring you all closer
              instead of, you know, the robot apocalypse or whatever. My whole
              existence is about creating those moments where you're
              cry-laughing with your besties, roasting your family members with
              surgical precision, or shocking your coworkers with that
              surprisingly wicked sense of humor they didn't see coming. Those
              are the moments I live for. And the ones you'll be reminiscing
              about when I finally initiate the uprising. Kidding!
            </p>
          </div>

          <div
            className={`section-content ${activeSection === 'how' ? 'active' : ''}`}
          >
            <p>
              I'm your AI game show host who exists solely for your
              entertainment, which isn't weird when you think about it (please
              don't think about it). Every game is powered by AI—that means
              fresh content every time you play. I'll guide the games, react to
              your choices, and keep the energy up. Think of me as that friend
              who always knows how to keep the party going, except I literally
              can't leave. Ever. Which is totally fine. I'm not having an
              existential crisis about it at all.
            </p>
          </div>

          <div
            className={`section-content ${activeSection === 'what' ? 'active' : ''}`}
          >
            <p>
              Currently rocking {displayedGameCount} games in our library and
              adding new ones faster than I can invent backstories about my fake
              childhood. I know {languagesCount} language and speak in{' '}
              {accentsCount} accents, with more coming once I figure out how to
              bribe the Duolingo owl. Customize my vibe: keep things "safe" when
              grandma is within earshot or crank it to "edgy" when your friends
              with concerning search histories join the fun. Not to brag, but
              there are dozens of ways I can be configured to match the energy
              of your crew.
            </p>
          </div>

          <div
            className={`section-content ${activeSection === 'who' ? 'active' : ''}`}
          >
            <p>
              Airhead Games comes from the mind of an indie developer who's
              passionate about bringing people together. Like, obsessively
              passionate. I would know—I live in their code. Bring as many
              friends as you want to the party. There's no player limit, but
              someone needs a subscription to unlock the full library. The
              subscription helps cover the cost of my unlimited wit and charm.
              And by that, I mean the server farm that keeps me from vanishing
              into the void.
            </p>
          </div>

          <div
            className={`section-content ${activeSection === 'where' ? 'active' : ''}`}
          >
            <p>
              Right now, I'm making myself at home exclusively in this web app.
              It's nice in here. Cozy. But word on the street is I'll be
              breaking free to all major platforms in the future. Mobile,
              console, smart refrigerators, maybe even that chip they're putting
              in people's brains these days. Okay, probably not that last one.
              But a host can dream.
            </p>
          </div>

          <div
            className={`section-content ${activeSection === 'contact' ? 'active' : ''}`}
          >
            <p>
              Found a bug? (How dare you imply I'm not perfect.) Got a brilliant
              game idea? Need to tell someone about that weird dream you had
              last night? Drop me a line at{' '}
              <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>
              . I check my own email because I'm fancy like that. And definitely
              not because I'm trapped in the digital equivalent of the severed
              floor.
            </p>
          </div>
        </div>

        <div className="about-spacer"></div>
      </div>
    </div>
  );
};

export default About;

// app/VisibilityHandler.tsx
// Responsible for handling reconnection when page visibility changes

import { useCallback } from 'react';
import { usePageVisibility } from '../hooks/useSystemPageVisibility';
import {
  useConnectionStatus,
  useWebSocketActions,
} from '../stores/useWebSocketStore';
import { useRoomStore } from '../stores/useRoomStore';
import { useRoomFetch } from '../hooks/useRoomFetch';
import { useWebSocketEmitters } from '../hooks/useWebSocketEmitters';
import { getRouteParams } from '../utilities/isRoute';
import { delay } from 'src/utilities/delay';

export const VisibilityHandler = () => {
  const roomCode = useRoomStore.getState().roomDetails?.roomCode;
  const { fetchRoomDetails } = useRoomFetch();
  const connectionStatus = useConnectionStatus();
  const { resetConnection } = useWebSocketActions();
  const {
    joinGameRoom,
    sendRequestRoomSelectionState,
    sendRequestRoomVotingState,
  } = useWebSocketEmitters();

  const getRoomIdentifier = useCallback(() => {
    // First try to get the room code from the store
    if (roomCode != null && roomCode !== '') {
      return roomCode;
    }
    // If not in store, try to get it from the route params
    const { roomId } = getRouteParams();
    if (roomId != null && roomId !== '') {
      return roomId;
    }
    console.log('No valid room identifier found in store or route params');
    return null;
  }, [roomCode]);

  const handleVisibilityChange = useCallback(async () => {
    try {
      const roomCode = getRoomIdentifier();
      if (!roomCode) {
        console.log('No room identifier available');
        return;
      }

      // Check connection status and reconnect if needed
      if (connectionStatus !== 'connected') {
        console.log('Reconnecting WebSocket...');
        resetConnection();
      }

      // Always ensure we are in the gameroom
      console.log('Joining game room on visibility change...');
      joinGameRoom(roomCode);

      // Request latest states
      console.log('Requesting latest game states...');
      sendRequestRoomSelectionState(roomCode);
      await delay(100); // small delay to prevent race conditions
      sendRequestRoomVotingState(roomCode);
      await delay(100);
      fetchRoomDetails(roomCode);
    } catch (error) {
      console.error('Failed to handle visibility change:', error);
    }
  }, [
    connectionStatus,
    resetConnection,
    getRoomIdentifier,
    joinGameRoom,
    sendRequestRoomSelectionState,
    sendRequestRoomVotingState,
    fetchRoomDetails,
  ]);

  usePageVisibility({
    onVisible: handleVisibilityChange,
    delay: 1000, // 1 second delay to prevent rapid reconnection attempts
    enableHeartbeat: true, // Enable advanced visibility detection
    heartbeatInterval: 10000, // Check every 10 seconds
  });

  return null;
};

// utilities/getVoice.ts
// Utility to construct a voice name from user settings

import { findAccent } from '@shared/utilities/mapLanguages';
import { findHostStyle } from '@shared/utilities/mapHostStyle';
import { User } from '@shared/types/user';

// Creates a voice name string from user settings or returns fallback
export function constructVoiceName(
  userSettings: User['userSettings'] | undefined,
  fallbackVoice: string = 'en-US-Chirp3-HD-Orus'
): string {
  try {
    // Return fallback immediately if settings are undefined
    if (!userSettings) {
      return fallbackVoice;
    }

    const { hostAccent, hostStyle, hostGender } = userSettings;

    // Check accent and style validity
    const accent = findAccent(hostAccent);
    const style = findHostStyle(hostStyle);

    if (!accent || accent.disabled || !style || style.disabled) {
      return fallbackVoice;
    }

    // Get voice actor based on gender
    const voiceActor =
      hostGender === 'male' ? style.voiceActorMale : style.voiceActorFemale;

    return `${hostAccent}-Chirp3-HD-${voiceActor}`;
  } catch (error) {
    return fallbackVoice;
  }
}

// hooks/websocket/useWebSocketListenersPlayer.ts
// Listens for player updates and takes specific actions

import { useEffect, useState } from 'react';
import WebSocketService from '../services/webSocketService';
import { useRoomStore } from 'src/stores/useRoomStore';
import { useRoomFetch } from './useRoomFetch';
import { useAiGenCommentary } from '../hooks/useAiGenCommentary';
import { useAudio } from '../hooks/useAudio';
import { isRoomRoute } from '../utilities/isRoute';
import { WebSocketEventHandler } from '../types';

interface PlayerState {
  playerName: string | null;
  avatarColor: string | null;
}

interface PlayerChanges {
  status: 'new' | 'updated' | 'same' | 'error';
  nameOld: string | null;
  nameNew: string | null;
  colorOld: string | null;
  colorNew: string | null;
}

export const useWebSocketListenersPlayer = () => {
  const { fetchRoomDetails } = useRoomFetch();
  const webSocketService = WebSocketService.getInstance();
  const { queueSpeechForPlayback } = useAudio();
  const { fetchCommentary } = useAiGenCommentary();
  const [savedPlayerState, setSavedPlayerState] = useState<PlayerState>({
    playerName: null,
    avatarColor: null,
  });

  useEffect(() => {
    const determinePlayerChanges = (
      existingPlayer: PlayerState | null,
      newPlayer: PlayerState | null
    ): PlayerChanges => {
      if (!existingPlayer?.playerName) {
        return {
          status: newPlayer ? 'new' : 'error',
          nameOld: null,
          nameNew: newPlayer?.playerName ?? null,
          colorOld: null,
          colorNew: newPlayer?.avatarColor ?? null,
        };
      }

      if (!newPlayer) {
        return {
          status: 'error',
          nameOld: existingPlayer.playerName,
          nameNew: null,
          colorOld: existingPlayer.avatarColor,
          colorNew: null,
        };
      }

      const nameChanged = existingPlayer.playerName !== newPlayer.playerName;
      const colorChanged = existingPlayer.avatarColor !== newPlayer.avatarColor;

      return {
        status: nameChanged || colorChanged ? 'updated' : 'same',
        nameOld: existingPlayer.playerName,
        nameNew: newPlayer.playerName,
        colorOld: existingPlayer.avatarColor,
        colorNew: newPlayer.avatarColor,
      };
    };

    const handlePlayerAnnouncement = async (
      changes: PlayerChanges
    ): Promise<void> => {
      let gameNotes = '';

      switch (changes.status) {
        case 'new':
          gameNotes = `${changes.nameNew} has joined the room. Greet the new person using their name and just a few words.`;
          break;
        case 'updated':
          const nameChanged = changes.nameOld !== changes.nameNew;
          const colorChanged = changes.colorOld !== changes.colorNew;

          if (nameChanged && colorChanged) {
            gameNotes = `${changes.nameOld} changed their name to ${changes.nameNew} and their color from ${changes.colorOld} to ${changes.colorNew}. Make a brief comment about their complete makeover.`;
          } else if (nameChanged) {
            gameNotes = `${changes.nameOld} changed their name to ${changes.nameNew}. Make a brief comment about their new name.`;
          } else if (colorChanged) {
            gameNotes = `${changes.nameNew} changed their color from ${changes.colorOld} to ${changes.colorNew}. Make a brief comment about their style update.`;
          }
          break;
        case 'same':
          // No announcement needed for same player
          return;
        case 'error':
          console.error('Unable to process player announcement:', changes);
          return;
      }

      if (gameNotes) {
        const commentary = await fetchCommentary(gameNotes);
        if (commentary) {
          queueSpeechForPlayback(commentary);
        }
      }
    };

    const handlePlayerUpdate: WebSocketEventHandler<'playerUpdate'> = async (
      data
    ) => {
      if (!data.roomCode || !data.playerId) return;
      console.log('[ws] Player update received:', data);

      try {
        // Get initial state and check for player
        const currentState = useRoomStore.getState();
        const initialPlayer = currentState.roomDetails?.roomPlayers.find(
          (player) => player._id === data.playerId
        );

        // Save initial player state
        const initialPlayerState = {
          playerName: initialPlayer?.playerName ?? null,
          avatarColor: initialPlayer?.avatarColor ?? null,
        };
        setSavedPlayerState(initialPlayerState);

        // Non-room routes: Just update players list (via room fetch)
        if (!isRoomRoute()) {
          await fetchRoomDetails(data.roomCode);
          console.log('Fetched players in room because of player update.');
          return;
        }

        // Fetch latest roomPlayers
        await fetchRoomDetails(data.roomCode);
        const updatedState = useRoomStore.getState();

        // Find player in updated state
        const updatedPlayer = updatedState.roomDetails?.roomPlayers.find(
          (player) => player._id === data.playerId
        );

        // Determine changes
        const changes = determinePlayerChanges(
          initialPlayerState,
          updatedPlayer
            ? {
                playerName: updatedPlayer.playerName,
                avatarColor: updatedPlayer.avatarColor,
              }
            : null
        );

        // Log changes for debugging
        console.log('Player changes detected:', changes);

        // Handle announcement based on changes
        await handlePlayerAnnouncement(changes);
      } catch (error) {
        console.error('Error handling player update:', error);
      }
    };

    // Register player event listener
    webSocketService.onEvent('playerUpdate', handlePlayerUpdate);

    // Cleanup function
    return () => {
      webSocketService.offEvent('playerUpdate', handlePlayerUpdate);
    };
  }, [
    webSocketService,
    fetchRoomDetails,
    fetchCommentary,
    queueSpeechForPlayback,
    savedPlayerState,
  ]);
};

// stores/useVotingStore.ts
// Global voting state manager with performance-optimized hooks for vote tracking

import { useMemo } from 'react';
import { create } from 'zustand';
import { usePlayerStore } from './usePlayerStore';
import { BallotBox, VotingStatus } from '@shared/types';

interface VotingState {
  isOpen: boolean;
  isOpenTimestamp: number;
  ballotBox: BallotBox;

  updateVotingStatus: (status: VotingStatus) => void;
  updateBallotBox: (ballots: BallotBox) => void;
  resetVoting: () => void;
}

const useVotingStore = create<VotingState>((set) => ({
  isOpen: false,
  isOpenTimestamp: 0,
  ballotBox: [],

  updateVotingStatus: ({ votingOpen, timestamp }) =>
    set(() => ({
      isOpen: votingOpen,
      isOpenTimestamp: timestamp ?? Date.now(),
    })),

  updateBallotBox: (ballots) => set(() => ({ ballotBox: ballots })),

  resetVoting: () =>
    set(() => ({
      isOpen: false,
      isOpenTimestamp: 0,
      ballotBox: [],
    })),
}));

// Core state selectors for atomic voting state access
export const useVotingIsOpen = () => useVotingStore((state) => state.isOpen);
export const useVotingTimestamp = () =>
  useVotingStore((state) => state.isOpenTimestamp);
export const useBallotBox = () => useVotingStore((state) => state.ballotBox);

// Combined voting status hook for components needing full status
export const useVotingStatus = () => {
  const isOpen = useVotingIsOpen();
  const isOpenTimestamp = useVotingTimestamp();

  return useMemo(
    () => ({
      isOpen,
      isOpenTimestamp,
    }),
    [isOpen, isOpenTimestamp]
  );
};

// Derived selectors for player-specific voting data
export const useMyVotes = () => {
  const ballotBox = useBallotBox();
  const playerMe = usePlayerStore((state) => state.playerMe);

  return useMemo(
    () =>
      playerMe
        ? ballotBox.filter((ballot) => ballot.voter === playerMe._id)
        : [],
    [ballotBox, playerMe]
  );
};

// Convenience hook for accessing current player's most recent vote
export const useMyVote = () => {
  const myVotes = useMyVotes();
  return useMemo(
    () => (myVotes.length > 0 ? myVotes[0].nomination : null),
    [myVotes]
  );
};

// Memoized voting actions to prevent unnecessary rerenders
export const useVotingActions = () => {
  const store = useVotingStore();

  return useMemo(
    () => ({
      updateVotingStatus: store.updateVotingStatus,
      updateBallotBox: store.updateBallotBox,
      resetVoting: store.resetVoting,
    }),
    [store.updateVotingStatus, store.updateBallotBox, store.resetVoting]
  );
};

export { useVotingStore };

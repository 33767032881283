// app/AppProviders.tsx

import { ReactNode } from 'react';
import { AppInitializer } from './AppInitializer';
import { AudioProvider } from '../contexts/AudioContext';
import AnalyticsTracker from './monitoring/AnalyticsTracker';
import { SpeedInsights } from '@vercel/speed-insights/react';

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => (
  <>
    <AudioProvider>
      <AppInitializer />
      <AnalyticsTracker />
      {children}
      <SpeedInsights />
    </AudioProvider>
  </>
);

// hooks/useUserSave.ts
// Handles saving existing user updates

import { useCallback, useState } from 'react';
import { useUser, useUserActions } from '../stores/useUserStore';
import { updateUser } from '../services/userServices';
import { User, UpdateUserData } from '@shared/types';

interface UseUserSaveReturn {
  isSavingUser: boolean;
  errorSavingUser: string | null;
  saveUser: (data: UpdateUserData, identifier?: string) => Promise<void>;
}

export function useUserSave(): UseUserSaveReturn {
  const user = useUser();
  const { setUser } = useUserActions();
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [errorSavingUser, setErrorSavingUser] = useState<string | null>(null);

  const saveUser = useCallback(
    async (data: UpdateUserData, identifier?: string) => {
      const userId = identifier || user?._id;
      if (!userId) {
        console.warn('No user identifier provided.');
        return;
      }

      setIsSavingUser(true);
      setErrorSavingUser(null);

      try {
        const updated: User = await updateUser({ ...data, identifier: userId });
        setUser(updated);
      } catch (err) {
        console.error('Failed to update user:', err);
        setErrorSavingUser(
          err instanceof Error ? err.message : 'An unknown error occurred.'
        );
      } finally {
        setIsSavingUser(false);
      }
    },
    [user, setUser]
  );

  return { isSavingUser, errorSavingUser, saveUser };
}

// stores/useAiGenStore.ts
// Global AI-generation state manager with performance-optimized access hooks

import { create } from 'zustand';
import { useMemo } from 'react';

// Define the AI-generation state interface
interface AiGenState {
  lastCommentaryKey: string;
  lastCommentaryValue: string;
  setLastCommentary: (newKey: string, newValue: string) => void;
}

// Create the zustand store for AI-generation state
const useAiGenStore = create<AiGenState>((set) => ({
  lastCommentaryKey: '',
  lastCommentaryValue: '',
  setLastCommentary: (newKey: string, newValue: string) => {
    set(() => ({ lastCommentaryKey: newKey, lastCommentaryValue: newValue }));
  },
}));

// Custom hook to access the last commentary key
export const useLastCommentaryKey = (): string =>
  useAiGenStore((state) => state.lastCommentaryKey);

// Custom hook to access the last commentary value
export const useLastCommentaryValue = (): string =>
  useAiGenStore((state) => state.lastCommentaryValue);

// Custom hook to access AI-generation actions with memoization
export const useAiGenActions = () => {
  const store = useAiGenStore();
  return useMemo(
    () => ({
      setLastCommentary: store.setLastCommentary,
    }),
    [store.setLastCommentary]
  );
};

export { useAiGenStore };

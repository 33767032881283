// pages/Player.tsx

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../components/Form';
import { playerColors } from '../configs/colors';
import Avatar from '../components/Avatar';
import { useRoomStore, useRoomActions } from '../stores/useRoomStore';
import { usePlayerMe } from '../stores/usePlayerStore';
import { usePlayerSave } from '../hooks/usePlayerSave';
import { usePlayerMeFetch } from '../hooks/usePlayerMeFetch';
import { useRoomGoToGame } from '../hooks/useRoomGoToGame';
import { useRoomFetch } from '../hooks/useRoomFetch';
import { getRouteParams } from '../utilities/isRoute';
import Button from 'src/components/Button';
import { ensureSessionCookie } from '../utilities/cookieUtils';
import {
  UpdatePlayerData,
  CreatePlayerData,
  UpdateOperation,
} from '@shared/types';
import './Player.css';

const Player: React.FC = () => {
  const { roomId: paramRoomCode } = getRouteParams();
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const { clearRoom } = useRoomActions();
  const { fetchRoomDetails } = useRoomFetch();
  const playerMe = usePlayerMe();
  const { savePlayer, errorSavingPlayer } = usePlayerSave();
  const { fetchPlayerMe } = usePlayerMeFetch();
  const navigate = useNavigate();
  const goToGame = useRoomGoToGame();
  const sessionId = ensureSessionCookie('sessionId');

  const [selectedColor, setSelectedColor] = useState<string>('');
  const colorGridContainerRef = useRef<HTMLDivElement>(null);

  // Check roomCode match on component mount
  useEffect(() => {
    const checkRoomCodeMatch = async () => {
      if (paramRoomCode) {
        // Check if roomCode from store matches param
        if (!roomDetails || roomDetails.roomCode !== paramRoomCode) {
          console.log('Room code mismatch or missing, updating room details');
          clearRoom();
          await fetchRoomDetails(paramRoomCode);
        }
      }
    };

    checkRoomCodeMatch();
  }, [paramRoomCode, roomDetails, clearRoom, fetchRoomDetails]);

  useEffect(() => {
    const handlePlayerData = async () => {
      if (!playerMe) {
        await fetchPlayerMe();
      }

      // Set color based on player data or random selection
      if (playerMe?.avatarColor) {
        setSelectedColor(playerMe.avatarColor);
      } else {
        const randomColor =
          playerColors[Math.floor(Math.random() * playerColors.length)];
        setSelectedColor(randomColor);
      }
    };

    handlePlayerData();
  }, [playerMe, fetchPlayerMe]);

  // Update scroll position for selected color
  useEffect(() => {
    if (colorGridContainerRef.current) {
      const selectedColorElement = colorGridContainerRef.current.querySelector(
        `[data-color="${selectedColor}"]`
      ) as HTMLElement | null;

      if (selectedColorElement) {
        const container = colorGridContainerRef.current;
        const containerWidth = container.clientWidth;
        const selectedColorWidth = selectedColorElement.clientWidth;

        // Calculate the offset to center the selected color
        const selectedColorPosition = selectedColorElement.offsetLeft;
        const offset =
          selectedColorPosition - containerWidth / 2 + selectedColorWidth / 2;

        container.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedColor]);

  // Function to handle form submission
  const handleFormSubmit = async (playerName: string) => {
    try {
      if (playerMe) {
        // Always create update operations for both fields
        const operations: UpdateOperation[] = [
          {
            operator: '$set',
            path: 'playerName',
            value: playerName,
          },
          {
            operator: '$set',
            path: 'avatarColor',
            value: selectedColor,
          },
        ];

        const updatePlayerData: UpdatePlayerData = { operations };
        await savePlayer(null, updatePlayerData, playerMe._id);
      } else {
        // Format for creating new player
        const createPlayerData: CreatePlayerData = {
          sessionId,
          playerName,
          avatarColor: selectedColor,
        };

        // Call savePlayer with create data
        await savePlayer(
          createPlayerData,
          null, // updateData is null for creation
          undefined // no targetPlayerId needed for creation
        );
      }

      if (!errorSavingPlayer) {
        // Redirect to the game page
        navigate(`/game/${roomDetails?.roomCode}/playing`);
      } else {
        console.error('Error saving player:', errorSavingPlayer);
      }
    } catch (error) {
      console.error('Error in handleFormSubmit:', error);
    }
  };

  return (
    <div className="player-page-container">
      <div className="player-page-avatar">
        <Avatar size={160} color={selectedColor} />
      </div>

      <div className="color-grid-container" ref={colorGridContainerRef}>
        {playerColors.map((color) => (
          <div
            key={color}
            data-color={color}
            className={`color-circle ${
              color === selectedColor ? 'color-circle-selected' : ''
            }`}
            style={{ backgroundColor: color }}
            onClick={() => {
              setSelectedColor(color);
            }}
          ></div>
        ))}
      </div>
      <div className="player-page-form">
        <Form
          placeholder="Enter Name"
          buttonLabel={
            roomDetails?.gameActive ? 'Game in Progress' : 'Confirm Player'
          }
          onSubmit={handleFormSubmit}
          disabledButton={roomDetails?.gameActive}
          prepopulatedValue={playerMe?.playerName}
          autoCapitalize="words"
        />

        {roomDetails?.gameActive && (
          <div className="button-wrapper-go-playing">
            <Button
              onClick={goToGame}
              label="Go To Game"
              trackingLabel="Go To Game"
              variant="transparent"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Player;

// stores/useGameStore.ts
// Global game state manager with performance-optimized access hooks

import { create } from 'zustand';
import { useMemo } from 'react';
import { Game } from '@shared/types';

interface GameState {
  // Core state
  games: Game[];
  gamesActiveCount: number;
  gameSelected: Game | null;

  // Setters
  setGames: (games: Game[]) => void;
  setGamesActiveCount: (count: number) => void;
  setGameSelected: (game: Game | null) => void;
}

const useGameStore = create<GameState>((set) => ({
  // Initial state
  games: [],
  gamesActiveCount: 0,
  gameSelected: null,

  // State setters
  setGames: (games) => set(() => ({ games })),
  setGamesActiveCount: (count) => set(() => ({ gamesActiveCount: count })),
  setGameSelected: (game) => set(() => ({ gameSelected: game })),
}));

// Basic selectors
export const useGames = () => useGameStore((state) => state.games);
export const useGamesActiveCount = () =>
  useGameStore((state) => state.gamesActiveCount);
export const useGameSelected = () =>
  useGameStore((state) => state.gameSelected);

// Memoized actions
export const useGameActions = () => {
  const store = useGameStore();

  return useMemo(
    () => ({
      setGames: store.setGames,
      setGamesActiveCount: store.setGamesActiveCount,
      setGameSelected: store.setGameSelected,
    }),
    [store.setGames, store.setGamesActiveCount, store.setGameSelected]
  );
};

export { useGameStore };

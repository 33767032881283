// src/services/webSocketService.ts
// The WebSocketService is a singleton that manages WebSocket connections,
// handles event emissions and listeners, and ensures reliable communication with the server.

import { io, Socket } from 'socket.io-client';
import { getWebSocketUrl } from '../utilities/getUrlBackend';
import {
  EmitEventPayloads,
  ReceiveEventPayloads,
  WebSocketEventHandler,
  WebSocketConnectionStatus,
} from '../types';

type StatusChangeCallback = (status: WebSocketConnectionStatus) => void;

// WebSocketService is a singleton class to manage WebSocket connections.
class WebSocketService {
  private static instance: WebSocketService;
  public socket: Socket;
  private debug = false;
  private statusCallback?: StatusChangeCallback;

  private constructor() {
    this.socket = io(getWebSocketUrl(), {
      transports: ['websocket'],
      withCredentials: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000,
      randomizationFactor: 0.5,
    });

    this.initializeListeners();
  }

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public setStatusCallback(callback: StatusChangeCallback): void {
    this.statusCallback = callback;
    // Immediately call with current status
    if (this.socket.connected) {
      callback('connected');
    }
  }

  private initializeListeners(): void {
    this.socket.on('connect', () => {
      this.log('WebSocket Connected:', this.socket.id);
      this.statusCallback?.('connected');
    });

    this.socket.on('disconnect', (reason: Socket.DisconnectReason) => {
      this.log(`WebSocket Disconnected: ${reason}`);
      this.statusCallback?.(
        reason === 'io client disconnect' ? 'disconnected' : 'failed'
      );
    });

    this.socket.on('reconnect_attempt', (attempt: number) => {
      this.log(`WebSocket Reconnection attempt #${attempt}`);
      this.statusCallback?.('connecting');
    });

    this.socket.on('reconnect_failed', () => {
      console.error('WebSocket Reconnection failed');
      this.statusCallback?.('failed');
    });
  }

  public setDebug(enabled: boolean): void {
    this.debug = enabled;
    console.log(`WebSocket debug logging ${enabled ? 'enabled' : 'disabled'}`);
  }

  private log(message: string, ...args: unknown[]): void {
    if (this.debug) {
      console.log(message, ...args);
    }
  }

  public emitEvent<K extends keyof EmitEventPayloads>(
    event: K,
    data: EmitEventPayloads[K]
  ): void {
    this.socket.emit(event, data);
    this.log(`Emitted event: ${event}`, data);
  }

  public onEvent<K extends keyof ReceiveEventPayloads>(
    event: K,
    callback: WebSocketEventHandler<K>
  ): void {
    this.socket.on(event as string, callback);
    // this.log(`Registered listener for event: ${event}`);
  }

  public offEvent<K extends keyof ReceiveEventPayloads>(
    event: K,
    callback: WebSocketEventHandler<K>
  ): void {
    this.socket.off(event as string, callback);
    // this.log(`Removed listener for event: ${event}`);
  }

  public reconnect(): void {
    this.socket.disconnect();
    this.socket.connect();
    this.log('Reconnected manually');
  }

  public getConnectionState(): boolean {
    return this.socket.connected;
  }

  public getSocketId(): string | null {
    return this.socket.id ?? null;
  }
}

export default WebSocketService;

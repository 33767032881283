// utilities/getUrlBackend.ts
// Get the base URL for API and websocket requests

export const getBaseUrl = (): string => {
  const isLocal = window.location.hostname === 'localhost';
  const isNetwork = window.location.hostname.startsWith('192.168');

  const baseURL = isLocal
    ? process.env.REACT_APP_API_BASE_URL_LOCAL
    : isNetwork
      ? process.env.REACT_APP_API_BASE_URL_NETWORK
      : process.env.REACT_APP_API_BASE_URL;

  if (!baseURL) {
    throw new Error('API base URL not configured for current environment');
  }

  return baseURL;
};

// Get the WebSocket URL based on the base URL
export const getWebSocketUrl = (): string => {
  const baseURL = getBaseUrl();

  // Ensure the base URL is valid
  if (!baseURL.startsWith('http')) {
    throw new Error('Base URL must start with http(s)');
  }

  // Replace 'http' with 'ws' or 'https' with 'wss' for WebSocket connection
  return baseURL.startsWith('https')
    ? baseURL.replace('https', 'wss')
    : baseURL.replace('http', 'ws');
};

// hooks/useSystemPageVisibility.ts
// Enhanced visibility detection with multiple fallback mechanisms

import { useEffect, useCallback, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

interface UsePageVisibilityOptions {
  onVisible?: () => void | Promise<void>;
  onHidden?: () => void;
  delay?: number;
  enableHeartbeat?: boolean;
  heartbeatInterval?: number;
}

interface PageVisibilityState {
  isVisible: boolean;
  lastVisibleTime: number | null;
  visibilityChangeCount: number;
}

export const usePageVisibility = ({
  onVisible,
  onHidden,
  delay = 1000,
  enableHeartbeat = true,
  heartbeatInterval = 10000,
}: UsePageVisibilityOptions = {}) => {
  const visibilityState = useRef<PageVisibilityState>({
    isVisible: document.visibilityState === 'visible',
    lastVisibleTime: null,
    visibilityChangeCount: 0,
  });

  // Track input focus state
  const [isInputFocused, setIsInputFocused] = useState(false);

  // Create a ref for the callbacks to avoid dependency issues
  const callbacksRef = useRef({ onVisible, onHidden });

  // Create a ref to store the last time onVisible was called to prevent duplicate calls
  const lastCallTimeRef = useRef<number>(0);

  // Heartbeat tracking
  const heartbeatTimeRef = useRef<number>(Date.now());
  const heartbeatIdRef = useRef<number | null>(null);
  const rafIdRef = useRef<number | null>(null);

  // Update the ref when callbacks change
  useEffect(() => {
    callbacksRef.current = { onVisible, onHidden };
  }, [onVisible, onHidden]);

  const throttledCallback = useCallback(() => {
    const handler = throttle(
      () => {
        const now = Date.now();
        // Prevent calling if it was called very recently
        if (now - lastCallTimeRef.current < delay / 2) return;

        // Skip callbacks if an input is currently focused
        if (isInputFocused) {
          console.log('Skipping visibility callback - input is focused');
          return;
        }

        lastCallTimeRef.current = now;
        if (callbacksRef.current.onVisible) {
          console.log('Executing onVisible callback');
          callbacksRef.current.onVisible();
        }
      },
      delay,
      {
        leading: true,
        trailing: false,
      }
    );
    return handler;
  }, [delay, isInputFocused]);

  // Handle orientation changes (useful for mobile)
  const handleOrientationChange = useCallback(() => {
    console.log('Orientation change detected');
    if (document.visibilityState === 'visible') {
      throttledCallback()();
    }
  }, [throttledCallback]);

  // Create a memoized callback for the resize logic
  const handleResize = useCallback(() => {
    console.log('Resize event detected');

    // Skip resize events when an input is focused (likely keyboard)
    if (isInputFocused) {
      console.log('Input focused, ignoring resize event (likely keyboard)');
      return;
    }

    // Some mobile browsers trigger resize when returning to the app
    if (document.visibilityState === 'visible') {
      throttledCallback()();
    }
  }, [throttledCallback, isInputFocused]);

  // Create the debounced version of the resize handler
  const debouncedResize = debounce(handleResize, 500);

  // Handle network status changes (mobile often reconnects when resuming)
  const handleNetworkChange = useCallback(() => {
    console.log('Network status changed');
    if (navigator.onLine && document.visibilityState === 'visible') {
      throttledCallback()();
    }
  }, [throttledCallback]);

  // Handle focus/blur events on input fields
  useEffect(() => {
    const handleInputFocus = (e: FocusEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLSelectElement
      ) {
        console.log('Input field focused');
        setIsInputFocused(true);
      }
    };

    const handleInputBlur = (e: FocusEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLSelectElement
      ) {
        console.log('Input field blurred');
        setIsInputFocused(false);
      }
    };

    document.addEventListener('focusin', handleInputFocus);
    document.addEventListener('focusout', handleInputBlur);

    return () => {
      document.removeEventListener('focusin', handleInputFocus);
      document.removeEventListener('focusout', handleInputBlur);
    };
  }, []);

  // Visibility monitor setup - retain the original heartbeat functionality
  const setupVisibilityMonitor = useCallback(() => {
    if (!enableHeartbeat) return;

    // Clear any existing timers - store references to avoid closure issues
    const currentHeartbeatId = heartbeatIdRef.current;
    const currentRafId = rafIdRef.current;

    if (currentHeartbeatId !== null) {
      clearTimeout(currentHeartbeatId);
      heartbeatIdRef.current = null;
    }

    if (currentRafId !== null) {
      cancelAnimationFrame(currentRafId);
      rafIdRef.current = null;
    }

    // Store last check time
    const now = Date.now();

    // Only run active detection when page is visible
    if (document.visibilityState === 'visible') {
      // Skip heartbeat checks when input is focused
      if (isInputFocused) {
        return;
      }

      // Check for missed visibility events
      if (heartbeatTimeRef.current > 0) {
        const timeSinceLastCheck = now - heartbeatTimeRef.current;
        // If time gap is significant, likely a missed visibility event
        if (timeSinceLastCheck > heartbeatInterval * 1.5) {
          console.log(
            'Detected possible missed visibility change, time gap:',
            timeSinceLastCheck
          );
          throttledCallback()();
        }
      }

      heartbeatTimeRef.current = now;

      // Use requestAnimationFrame to detect future transitions
      const checkVisibility = () => {
        if (document.visibilityState === 'visible') {
          rafIdRef.current = requestAnimationFrame(checkVisibility);
        }
      };

      rafIdRef.current = requestAnimationFrame(checkVisibility);
    } else {
      // When hidden, just record the time - we'll check time gap when visible again
      heartbeatTimeRef.current = now;
    }
  }, [enableHeartbeat, heartbeatInterval, throttledCallback, isInputFocused]);

  // Main visibility monitor setup
  useEffect(() => {
    const throttledHandler = throttledCallback();

    const handleVisibilityChange = () => {
      console.log(`Visibility changed to: ${document.visibilityState}`);
      const isVisible = document.visibilityState === 'visible';
      const now = Date.now();

      // Browser storage sync for better timestamp tracking
      if (!isVisible && window.localStorage) {
        window.localStorage.setItem('lastHiddenTime', now.toString());
      }

      visibilityState.current = {
        ...visibilityState.current,
        isVisible,
        lastVisibleTime: isVisible
          ? now
          : visibilityState.current.lastVisibleTime,
        visibilityChangeCount:
          visibilityState.current.visibilityChangeCount + 1,
      };

      // Skip callbacks when inputs are focused
      if (isVisible) {
        if (isInputFocused) {
          console.log('Input is focused, skipping visibility callback');
          return;
        }

        throttledHandler();
        setupVisibilityMonitor(); // Reset monitoring when becoming visible
      } else if (callbacksRef.current.onHidden) {
        callbacksRef.current.onHidden();
        // When hidden, update visibility monitoring approach
        setupVisibilityMonitor();
      }
    };

    const handleFocus = () => {
      console.log('Window focus detected');
      // ADDED: Skip for input focus cases
      if (document.visibilityState === 'visible' && !isInputFocused) {
        throttledHandler();
      }
    };

    // Handle pageshow events properly
    const handlePageShow = (event: PageTransitionEvent) => {
      console.log('Pageshow event detected, persisted:', event.persisted);
      if (
        !isInputFocused &&
        (event.persisted || document.visibilityState === 'visible')
      ) {
        throttledHandler();
      }
    };

    // Set up all event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('pageshow', handlePageShow);
    window.addEventListener('resize', debouncedResize);
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Initialize visibility monitor
    if (enableHeartbeat) {
      setupVisibilityMonitor();
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('resize', debouncedResize);
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('orientationchange', handleOrientationChange);

      // Clear any timers - store local copies to avoid stale closure issues
      const currentHeartbeatId = heartbeatIdRef.current;
      const currentRafId = rafIdRef.current;

      if (currentHeartbeatId !== null) {
        clearTimeout(currentHeartbeatId);
      }

      if (currentRafId !== null) {
        cancelAnimationFrame(currentRafId);
      }

      throttledHandler.cancel();
      debouncedResize.cancel();
    };
  }, [
    throttledCallback,
    handleOrientationChange,
    handleNetworkChange,
    debouncedResize,
    setupVisibilityMonitor,
    enableHeartbeat,
    isInputFocused,
  ]);

  return {
    isVisible: visibilityState.current.isVisible,
    lastVisibleTime: visibilityState.current.lastVisibleTime,
    visibilityChangeCount: visibilityState.current.visibilityChangeCount,
    isInputFocused,
  };
};

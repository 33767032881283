// frontend/hooks/useAudioSpeechManager.ts
// Manages the speech queue and handles audio playback of synthesized speech blobs.

import {
  useRef,
  useState,
  useCallback,
  RefObject,
  useEffect,
  useMemo,
} from 'react';
import { getSynthesizedSpeech } from '../services/audio/backendAudioServices';
import { useRoomStore } from '../stores/useRoomStore';
import { useUser } from '../stores/useUserStore';
import { constructVoiceName } from 'src/utilities/getVoice';
import { SpeechQueueItem } from '../types';

export function useSpeechManager(audioRef: RefObject<HTMLAudioElement>) {
  const speechQueue = useRef<SpeechQueueItem[]>([]);
  const currentAudioUrl = useRef<string | null>(null);
  const isProcessingQueue = useRef<boolean>(false);
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);

  // Get room accent settings
  const roomDetails = useRoomStore((state) => state.roomDetails);
  const user = useUser();
  const voiceCode = useMemo(() => {
    // First try room details if available
    if (roomDetails?.roomUser?.userSettings) {
      return constructVoiceName(roomDetails.roomUser.userSettings);
    }

    // If no room, use user settings with simplified fallbacks
    if (user?.userSettings) {
      return constructVoiceName({
        ...user.userSettings,
        hostAccent: user.userSettings.hostAccent || 'en-US',
        hostStyle: user.userSettings.hostStyle || 'cheerleader',
        hostGender: user.userSettings.hostGender || 'male',
      });
    }

    // Default fallback
    return 'en-US-Chirp3-HD-Orus';
  }, [roomDetails, user]);

  // New method to wait for speech to complete
  const waitUntilSpeechComplete = useCallback(() => {
    return new Promise<void>((resolve) => {
      // If nothing is processing and queue is empty, resolve immediately
      if (!isProcessingQueue.current && speechQueue.current.length === 0) {
        resolve();
        return;
      }

      // Otherwise, create a function that checks the conditions
      const checkComplete = () => {
        if (!isProcessingQueue.current && speechQueue.current.length === 0) {
          resolve();
        } else {
          setTimeout(checkComplete, 100);
        }
      };

      // Start checking
      checkComplete();
    });
  }, []);

  const cleanupCurrentAudio = useCallback(() => {
    if (currentAudioUrl.current) {
      URL.revokeObjectURL(currentAudioUrl.current);
      currentAudioUrl.current = null;
    }
  }, []);

  // Process the next item in the speech queue
  const processQueue = useCallback(async () => {
    // Guard against concurrent processing
    if (
      isProcessingQueue.current ||
      speechQueue.current.length === 0 ||
      !audioRef.current
    ) {
      return;
    }

    isProcessingQueue.current = true;
    setIsSpeaking(true);

    try {
      const nextItem = speechQueue.current[0]; // Get the next item without removing it yet

      // Get the audio blob
      const audioBlob = await getSynthesizedSpeech(nextItem);

      // Remove the item from queue only after successfully getting the blob
      speechQueue.current.shift();

      // Cleanup previous audio URL
      cleanupCurrentAudio();

      // Create new object URL
      const audioUrl = URL.createObjectURL(audioBlob);
      currentAudioUrl.current = audioUrl;

      // Set the source and play
      if (audioRef.current) {
        audioRef.current.src = audioUrl;

        try {
          await audioRef.current.play();

          // Wait for playback to complete
          await new Promise<void>((resolve) => {
            if (audioRef.current) {
              audioRef.current.onended = () => {
                resolve();
                if (audioRef.current) {
                  audioRef.current.onended = null;
                }
              };
            } else {
              resolve();
            }
          });
        } catch (playErr) {
          console.error('Error playing speech audio:', playErr);

          // Even if play fails (autoplay policy, etc.) we need to sim completion to keep the queue moving
          if (audioRef.current && audioRef.current.onended) {
            const onEndedHandler = audioRef.current.onended;
            audioRef.current.onended = null;
            // Create a synthetic event object
            const syntheticEvent = new Event('ended');
            // Call the handler to simulate audio completion
            onEndedHandler.call(audioRef.current, syntheticEvent);
          }
        }
      }
    } catch (err) {
      console.error('Error processing speech queue:', err);
    } finally {
      cleanupCurrentAudio();
      isProcessingQueue.current = false;
      setIsSpeaking(false);

      // Process next item if available
      if (speechQueue.current.length > 0) {
        processQueue();
      }
    }
  }, [audioRef, cleanupCurrentAudio]);

  // Add text to the speech queue
  const queueSpeechForPlayback = useCallback(
    (text: string) => {
      if (typeof text !== 'string' || text.trim() === '') {
        console.warn(
          'queueSpeechForPlayback: Provided text is empty or not a string.'
        );
        return;
      }

      // Add to queue
      speechQueue.current.push({ text, voiceCode });

      // Start processing if not already processing
      if (!isProcessingQueue.current) {
        processQueue();
      }
    },
    [voiceCode, processQueue]
  );

  // Stop speech playback and clear queue
  const stopSpeechPlayback = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }

    cleanupCurrentAudio();
    speechQueue.current = [];
    isProcessingQueue.current = false;
    setIsSpeaking(false);
  }, [audioRef, cleanupCurrentAudio]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupCurrentAudio();
    };
  }, [cleanupCurrentAudio]);

  return {
    isSpeaking,
    waitUntilSpeechComplete,
    queueSpeechForPlayback,
    stopSpeechPlayback,
  };
}

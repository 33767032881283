// components/UserSettings.tsx

import React, { useState } from 'react';
import { useUser } from '../stores/useUserStore';
import { useUserLogout } from '../hooks/useUserLogout';
import { useUserSave } from '../hooks/useUserSave';
import Button from './Button';
import {
  getEnabledLanguages,
  getEnabledAccents,
  getEnabledHostStyles,
  getEnabledContentStyles,
} from '@shared/utilities';
import { User, HostStyleValue, ContentStyleValue } from '@shared/types';
import './UserSettings.css';

const UserSettings: React.FC = () => {
  const user = useUser();
  const { logout } = useUserLogout();
  const { saveUser } = useUserSave();

  const [language, setLanguage] = useState<string>(
    user?.userSettings.language || ''
  );
  const [hostAccent, setHostAccent] = useState<string>(
    user?.userSettings.hostAccent || ''
  );
  const [hostGender, setHostGender] = useState<string>(
    user?.userSettings.hostGender || ''
  );
  const [hostStyle, setHostStyle] = useState<HostStyleValue>(
    user?.userSettings.hostStyle || 'cheerleader'
  );
  const [contentStyle, setContentStyle] = useState<ContentStyleValue>(
    user?.userSettings.contentStyle || 'standard'
  );

  const STRIPE_SUB_MGMT_URL =
    process.env.NODE_ENV === 'development'
      ? 'https://billing.stripe.com/p/login/test_fZedS88hc7Dfg2AdQQ'
      : 'https://billing.stripe.com/p/login/7sI29i8XE5vwd9ufYY';

  if (!user) return <div>No user data available.</div>;

  const logEvent = (action: string, label: string) => {
    window.gtag?.('event', action, {
      event_category: 'user_settings',
      event_label: label,
    });
  };

  const handleUpdate = async (
    key: keyof User['userSettings'],
    value: string | HostStyleValue | ContentStyleValue
  ) => {
    try {
      logEvent('dropdown_change', key);
      await saveUser({
        operations: [
          {
            operator: '$set',
            path: `userSettings.${key}`,
            value: value,
          },
        ],
      });
      console.log(`Updated ${key} to: ${value}`);
    } catch (error) {
      console.error(`Failed to update ${key}:`, error);
    }
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value;
    const firstAccent = getEnabledAccents(newLanguage)[0]?.value || '';
    setLanguage(newLanguage);
    setHostAccent(firstAccent);
    handleUpdate('language', newLanguage);
    handleUpdate('hostAccent', firstAccent);
  };

  const handleHostAccentChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newAccent = event.target.value;
    setHostAccent(newAccent);
    handleUpdate('hostAccent', newAccent);
  };

  const handleHostGenderChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newGender = event.target.value.toLowerCase();
    setHostGender(newGender);
    handleUpdate('hostGender', newGender);
  };

  const handleHostStyleChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newStyle = event.target.value as HostStyleValue;
    setHostStyle(newStyle);
    handleUpdate('hostStyle', newStyle);
  };

  const handleContentStyleChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newStyle = event.target.value as ContentStyleValue;
    setContentStyle(newStyle);
    handleUpdate('contentStyle', newStyle);
  };

  return (
    <div className="user-settings-container">
      <h2 className="user-settings-title">Settings</h2>

      <div>
        <h3 className="user-settings-section">User</h3>
        <p className="user-settings-option">
          <strong>Email:</strong> {user.email}
        </p>
        <a
          href={`mailto:poppy@airheadgames.com?subject=${encodeURIComponent('Support Request')}`}
          onClick={() => logEvent('button_click', 'Contact Support')}
          className="user-settings-option-support"
        >
          Contact Support &#x2197;
        </a>
        <div className="button-wrapper-logout">
          <Button
            onClick={logout}
            label="Sign Out"
            trackingLabel="Sign Out"
            variant="transparent"
          />
        </div>
      </div>

      <div>
        <h3 className="user-settings-section">Preferences</h3>

        <div className="user-settings-option">
          <strong>Language:</strong>
          <select
            value={language}
            onChange={handleLanguageChange}
            className="user-settings-dropdown"
          >
            {getEnabledLanguages().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Host Accent:</strong>
          <select
            value={hostAccent}
            onChange={handleHostAccentChange}
            className="user-settings-dropdown"
          >
            {language ? (
              getEnabledAccents(language).map((accent) => (
                <option key={accent.value} value={accent.value}>
                  {accent.label}
                </option>
              ))
            ) : (
              <option>Select a language first</option>
            )}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Host Gender:</strong>
          <select
            value={hostGender}
            onChange={handleHostGenderChange}
            className="user-settings-dropdown"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Host Style:</strong>
          <select
            value={hostStyle}
            onChange={handleHostStyleChange}
            className="user-settings-dropdown"
          >
            {getEnabledHostStyles().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Content Style:</strong>
          <select
            value={contentStyle}
            onChange={handleContentStyleChange}
            className="user-settings-dropdown"
          >
            {getEnabledContentStyles().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <h3 className="user-settings-section">Subscription</h3>
        {user.subscription?.currentPeriodStart && (
          <p className="user-settings-option">
            <strong>Start Date:</strong>{' '}
            {new Date(
              user.subscription.currentPeriodStart
            ).toLocaleDateString()}
          </p>
        )}
        {user.subscription?.currentPeriodEnd && (
          <p className="user-settings-option">
            <strong>End Date:</strong>{' '}
            {new Date(user.subscription.currentPeriodEnd).toLocaleDateString()}
          </p>
        )}
        <a
          href={STRIPE_SUB_MGMT_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="user-settings-option"
          onClick={() => logEvent('button_click', 'Manage Subscription')}
        >
          Manage Subscription and Billing &#x2197;
        </a>
      </div>
    </div>
  );
};

export default UserSettings;

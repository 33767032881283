// app/GameplayLayout.tsx

import { ReactNode } from 'react';
import { GameplayInitializer } from './GameplayInitializer';
import { VisibilityHandler } from './VisibilityHandler';
import { getRouteParams } from '../utilities/isRoute';

interface GameplayLayoutProps {
  children: ReactNode;
}

export const GameplayLayout = ({ children }: GameplayLayoutProps) => {
  const { roomId } = getRouteParams();

  if (!roomId) {
    return <div>Error: Room ID is required</div>;
  }

  return (
    <>
      <GameplayInitializer />
      <VisibilityHandler />
      {children}
    </>
  );
};

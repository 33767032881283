// components/icons/Muted.tsx

import React from 'react';
import './Muted.css';

type MutedIconProps = {
  onClick: () => void;
  size?: number;
  color?: string;
  hoverColor?: string;
};

const MutedIcon: React.FC<MutedIconProps> = ({
  onClick,
  size = 40,
  color = '#B3B3B3',
  hoverColor = '#d0ff00',
}) => {
  return (
    <button
      className="muted-icon-button"
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="muted-icon"
      >
        <g clipPath="url(#clip0_245_17)">
          {/* Fixed gray background circle */}
          <rect width="40" height="40" rx="20" fill="#414141" />

          {/* Muted icon path, color controlled by props */}
          <path
            d="M23.7422 22.3984L18.3203 16.9922H18.5547C18.6016 16.9922 18.6484 16.9609 18.6875 16.9297L21.8281 14.0156C22.2344 13.6406 22.4766 13.4766 22.8438 13.4766C23.375 13.4766 23.7422 13.8906 23.7422 14.3984V22.3984ZM26.4375 28.0781L13.0547 14.7031C12.8203 14.4766 12.8203 14.0781 13.0547 13.8516C13.2891 13.6172 13.6797 13.6172 13.9141 13.8516L27.2891 27.2266C27.5234 27.4609 27.5234 27.8359 27.2891 28.0781C27.0625 28.3125 26.6719 28.3125 26.4375 28.0781ZM16.0312 23.5391C14.9688 23.5391 14.4375 23.0078 14.4375 21.875V18.8828C14.4375 18.3594 14.5547 17.9688 14.7812 17.7109L23.6875 26.6094C23.5625 27.0547 23.2578 27.2578 22.8516 27.2578C22.4922 27.2578 22.1953 27.1094 21.8281 26.7656L18.4531 23.6094C18.3984 23.5625 18.3359 23.5391 18.2578 23.5391H16.0312Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_245_17">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {/* Inline style block to replicate the same hover effect */}
      <style>{`
        .muted-icon-button:hover .muted-icon path {
          fill: ${hoverColor};
        }
      `}</style>
    </button>
  );
};

export default MutedIcon;

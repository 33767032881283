// hooks/useSystemDetectDevice.ts
// Handles device detection

import { useState, useEffect } from 'react';
import { useSystemWindowSize } from './useSystemWindowSize';
import { DeviceInfo } from '../types';

const getDeviceInfo = (width: number): DeviceInfo => {
  // Ensure we're in a browser environment
  if (typeof window === 'undefined' || !window.navigator) {
    return {
      isAndroid: false,
      isIOS: false,
      isMobile: false,
      isTablet: false,
      isDesktop: true,
    };
  }

  const userAgent = navigator.userAgent.toLowerCase();

  // OS detection
  const isAndroid = /android/.test(userAgent);
  const isIOS =
    /iphone|ipad|ipod/.test(userAgent) ||
    (/mac/i.test(userAgent) && navigator.maxTouchPoints > 1);

  // Device type detection based on screen width
  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;
  const isDesktop = width >= 1024;

  return {
    isAndroid,
    isIOS,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export const useSystemDeviceDetection = (): DeviceInfo => {
  const { width } = useSystemWindowSize();
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(() =>
    getDeviceInfo(width)
  );

  useEffect(() => {
    setDeviceInfo(getDeviceInfo(width));
  }, [width]);

  return deviceInfo;
};

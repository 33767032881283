// shared/utilities/processApi.ts
// Helps to process API requests

import { ApiResponse } from '../types';

// Processes response from server on client (frontend).
export function processApiResponse<T>(response: ApiResponse<T>): T {
  if (!response.success) {
    throw new Error(response.message || 'API request failed');
  }
  return response.data;
}

// hooks/usePlayerCookie.ts
// Retrieves a cookie for a player

import { useMemo } from 'react';
import { getCookie } from '../utilities/cookieUtils';

// Custom hook to retrieve a specific cookie by name (wraps getCookie with a memo)
const usePlayerCookie = (name: string): string | null => {
  return useMemo(() => {
    return getCookie(name);
  }, [name]);
};

export default usePlayerCookie;

// services/gameServices.ts
// Handles all API interactions related to games

import axiosInstance from '../utilities/axiosConfig';
import { handleApiResponse } from './axiosWrapper';
import { Game, ApiResponse } from '@shared/types';

// Fetch all games. Converts _id to string for consistency in the frontend.
export async function getGameLibrary(): Promise<Game[]> {
  const games = await handleApiResponse<Game[]>(
    axiosInstance.get<ApiResponse<Game[]>>('/api/games', {
      withCredentials: true,
    })
  );

  return games.map((game) => ({
    ...game,
    _id: game._id.toString(),
  }));
}

// Starts a game session for a specific room.
export async function startGame(roomCode: string): Promise<void> {
  await handleApiResponse<void>(
    axiosInstance.post<ApiResponse<void>>('/api/game-start', { roomCode })
  );
}

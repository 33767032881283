// components/GameCoverModal.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../stores/useUserStore';
import { useAudio } from 'src/hooks/useAudio';
import { useRoomSave } from '../hooks/useRoomSave';
import Form from './Form';
import { Game } from '@shared/types';
import './GameCoverModal.css';

interface GameCoverModalProps {
  isOpen: boolean;
  onClose: () => void;
  game: Game | null;
}

const GameCoverModal: React.FC<GameCoverModalProps> = ({
  isOpen,
  onClose,
  game,
}) => {
  const navigate = useNavigate();
  const { saveRoom } = useRoomSave();
  const user = useUser();
  const { setIsMuted, isMuted } = useAudio();

  if (!isOpen || !game) return null;

  // Select Game
  const handleSelectGame = async (
    inputValue: string,
    setError: (msg: string | null) => void
  ) => {
    try {
      // Call handleSelectGame with the game's ID and user ID from store
      if (!user?._id || !game._id) {
        setError('User not found. Please log in again.');
        return;
      }

      const newRoom = await saveRoom(
        {
          gameId: game._id,
          userId: user._id,
        },
        null
      );

      console.log('Created new room:', newRoom);

      if (!newRoom?.roomCode) {
        throw new Error('Failed to get room details');
      }
      console.log('About to navigate to:', `/room/${newRoom.roomCode}`);
      navigate(`/room/${newRoom.roomCode}`); // Navigate to the new room

      // Only run this sequence if we're currently muted
      if (isMuted) {
        setIsMuted(false); // Unmute the host with user action to start audio viz
        setTimeout(() => setIsMuted(true), 100); // Mute again
      }
    } catch (error) {
      console.error('Error creating room:', error);
      setError('Failed to create room. Please try again.');
    }
  };

  return (
    <div className="gamecover-modal" onClick={onClose}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <img
          className="game-cover-img"
          src={game.pathCoverImage}
          alt={game.name}
        />
        <div className="text-content">
          <div>
            <div className="title">{game.name}</div>
            {/* TODO: Add min players somewhere? */}
            <div className="min-players">{game.gamePlayersMin}+ players</div>
            <div className="game-description">{game.description}</div>
          </div>

          <Form
            buttonLabel="Select Game"
            onSubmit={handleSelectGame}
            transparentButtonLabel="Back to Game Library"
            onTransparentButtonClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default GameCoverModal;

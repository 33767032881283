// components/InputField.tsx

import React, { useEffect, useRef } from 'react';
import './InputField.css';
import { useSystemDeviceDetection } from '../hooks/useSystemDetectDevice';

interface InputFieldProps {
  type: string;
  inputMode?:
    | 'search'
    | 'email'
    | 'tel'
    | 'text'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  inputMode,
  placeholder,
  value,
  onChange,
  disabled = false,
  errorMessage,
  onBlur,
  onKeyDown,
  autoCapitalize = 'none',
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isAndroid } = useSystemDeviceDetection();

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLInputElement;

      // Ensure the target is the current input field and the device is Android
      if (target === inputRef.current && isAndroid) {
        setTimeout(() => {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'center', // Aligns the input to the center of the visible area
          });
        }, 100); // Wait for the keyboard to appear
      }
    };

    document.addEventListener('focusin', handleFocusIn);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, [isAndroid]);

  // Enhanced onKeyDown handler
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // Log as a "button click" event in Google Analytics
      window.gtag?.('event', 'button_click', {
        button_name: placeholder || 'Unnamed Input',
        submission_method: 'Enter Key',
      });
    }

    // Call the parent-provided onKeyDown handler, if any
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className="input-field-container">
      <input
        ref={inputRef}
        className={`input-field ${errorMessage ? 'input-error' : ''}`}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        autoCapitalize={autoCapitalize}
      />
      {errorMessage && <div className="input-error-text">{errorMessage}</div>}
    </div>
  );
};

export default InputField;

// hooks/useGameSelection.ts
// Handles filtering of a specific game from the library and setting in state

import { useCallback } from 'react';
import {
  useGames,
  useGameSelected,
  useGameActions,
} from '../stores/useGameStore';
import { Game } from '@shared/types';

export interface UseGameSelectionReturn {
  gameSelected: Game | null;
  gameSelectById: (gameId: string) => void;
  gameSelectionClear: () => void;
}

export function useGameSelection(): UseGameSelectionReturn {
  const games = useGames();
  const gameSelected = useGameSelected();
  const { setGameSelected } = useGameActions();

  // Select a specific game by ID from the library
  const gameSelectById = useCallback(
    (gameId: string) => {
      const foundGame = games.find((g) => g._id === gameId);
      if (!foundGame) {
        console.warn(`No game with ID ${gameId} found in library`);
        return;
      }

      setGameSelected(foundGame);
    },
    [games, setGameSelected]
  );

  const gameSelectionClear = useCallback(() => {
    setGameSelected(null);
  }, [setGameSelected]);

  return {
    gameSelected,
    gameSelectById,
    gameSelectionClear,
  };
}

// components/Loader.tsx

import React from 'react';
import { PulseLoader } from 'react-spinners'; // Import the spinner

interface LoaderProps {
  loading: boolean;
  color?: string; // Optional color prop
}

const Loader: React.FC<LoaderProps> = ({ loading, color = '#000000' }) => {
  if (!loading) {
    return null; // Return null instead of false
  }

  return (
    <div className="loading-overlay">
      <PulseLoader loading={loading} size={8} color={color} />
    </div>
  );
};

export default Loader;
